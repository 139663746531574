import React, {FC} from 'react';

type UITextWrapperTypes = {
  heading?: string;
  title?: string;
  description?: string;
  textAlign?: string;
  padding?: string;
  headingStyles?: string;
  titleStyles?: string;
  descriptionStyles?: string;
}

const UITextWrapper: FC<UITextWrapperTypes> = ({
  heading,
  title,
  description,
  textAlign='text-left lg:text-center',
  padding='pt-32 pb-14 lg:pt-36',
  headingStyles,
  titleStyles,
  descriptionStyles,
}) => {
  return (
    <div className={`flex flex-col w-full text-black-body lg:items-center ${textAlign} ${padding}`}>
      {heading && (
        <h3 className={`${headingStyles && `${headingStyles}`}
         text-xs lg:text-base font-semibold uppercase mb-4 lg:mb-8`}>
          {heading}
        </h3>
      )}
      {title !== undefined && (
        <h2 className={`${titleStyles && `${titleStyles}`}
         text-25px lg:text-4xl font-semibold uppercase mb-3 lg:mb-6 w-full md:w-3/4 xl:w-1/2`}>
          {title}
        </h2>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{__html: description}}
          className={`${descriptionStyles && `${descriptionStyles}`}
           text-base lg:text-lg font-medium w-full md:w-3/4 xl:w-2/3`}
        />
      )}
    </div>
  );
};

export default UITextWrapper;
