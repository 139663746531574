import {useMutation} from 'react-query';
import actions from '../../api/events/eventsEnrollment';

const useMutateCancelEnrollment = () => {
  return useMutation(async (id: number) => {
    await actions.cancelEnrollment(id);
  }, {retry: false});
};

export default useMutateCancelEnrollment;
