import {FC} from 'react';

import useFetchPages from '../hooks/pages/useFetchPages';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import ArchiveSectionComponent from '../components/ArchivePage/ArchiveSectionComponent';


const ArchivePage: FC = () => {
  const {isLoading: isLoadingPage, isError: isErrorPage} = useFetchPages();

  if (isErrorPage) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };

  if (isLoadingPage) {
    return (
      <UILoadingIndicator/>
    );
  };
  return (
    <UIPageContainer>
      <UITextWrapper
        heading='arkiv'
        title='Historik och artiklar om Marknadsföreningen'
        description='Här hittar du arkivet med allt gällande Marknadsföreningen i Halland'
      />
      <ArchiveSectionComponent />
    </UIPageContainer>
  );
};

export default ArchivePage;
