import React, {FC} from 'react';

type StaffItemComponentTypes = {
  name: string;
  extraInfo: string;
  photoUrl?: string;
}

const StaffItemComponent:FC<StaffItemComponentTypes> = ({name, extraInfo, photoUrl}) => {
  return (
    <div className='flex flex-col'>
      <div className='relative h-44 lg:h-[346px]'>
        <div className={`flex flex-col justify-end w-full h-full sticky left-0 top-0
        z-10 rounded-15px text-white ${photoUrl == undefined ? 'bg-green-primary' : 'bg-radius-black'}`}>
          <div className='hidden lg:block mx-8 mb-4'>
            <h2 className='font-semibold text-25px'>
              {name}
            </h2>
            <span className='text-lg font-normal'>
              {extraInfo}
            </span>
          </div>
        </div>
        {photoUrl != null && (
          <img src={photoUrl} alt="image"
            className='h-full w-full object-fill rounded-15px absolute top-0 left-0 z-0'
          />
        )}
      </div>
      <div className='block lg:hidden text-black mt-1'>
        <h2 className='font-semibold text-base'>
          {name}
        </h2>
        <span className='text-sm font-normal'>
          {extraInfo}
        </span>
      </div>
    </div>
  );
};

export default StaffItemComponent;
