import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'testimonials/';

const fetchTestimonials = async () => {
  const response = await axios.get(URL);
  return response.data;
};

const actions = {
  fetchTestimonials,
};

export default actions;
