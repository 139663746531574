import {useMutation} from 'react-query';
import actions from '../../api/user/update';

const useMutateUpdateUser = () => {
  return useMutation(async (data: any) => {
    await actions.updateUser(data);
  }, {retry: false});
};

export default useMutateUpdateUser;
