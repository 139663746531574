import {FC} from 'react';


type UIUserAvatarProps = {
  amount: number,
  extra?: string,
};

const UIUserAvatarExtra: FC<UIUserAvatarProps> = ({
  amount,
  extra,
}) => {
  return (
    <div
      className={`border rounded-full w-[38px] h-[38px] md:w-[45px] md:h-[45px] ${extra} bg-green-primary
    flex justify-center items-center font-bold text-white overflow-hidden`}
    >
      <div
        className='font-bold text-md md:text-xl'
      >
        +
      </div>
      <div
        className='font-bold text-sm md:text-lg'
      >
        {amount}
      </div>
    </div>
  );
};

export default UIUserAvatarExtra;

