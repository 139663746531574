import {atom, selector} from 'recoil';
import {eventType} from '../../types/event';

export const eventsPaginated = atom<eventType[]>({
  key: 'eventsPaginated',
  default: undefined,
});

export const singleEvent = atom<eventType>({
  key: 'singleEvent',
  default: undefined,
});

export const eventPeriod = atom<string>({
  key: 'eventPeroid',
  default: 'future',
});

export const enrollableEvents = selector<eventType[]>({
  key: 'enrollableEvents',
  get: ({get}) => {
    const events = get(eventsPaginated);
    if (events != null) {
      return events.filter((el: eventType) => {
        const enrollmentDate = new Date(el.enrollment_date);
        const now = new Date();
        return enrollmentDate > now;
      });
    }
    return [];
  },
});

export const carouselEvents = atom<eventType[]>({
  key: 'carouselEvents',
  default: undefined,
});

export const otherEvents = selector<eventType[]>({
  key: 'otherEvents',
  get: ({get}) => {
    const allEvents = get(enrollableEvents);
    const nearestEventsList = get(carouselEvents);
    const today = new Date();

    if (nearestEventsList == null || allEvents == null) {
      return [];
    }

    const otherEventsList = allEvents.filter((event) => {
      const isNotInCarousel = !nearestEventsList.some((nearestEvent) => nearestEvent.id === event.id);
      const eventStartTime = new Date(event.start_time);
      const isFutureEvent = eventStartTime > today;
      return isNotInCarousel && isFutureEvent;
    });

    return otherEventsList;
  },
});

export const pastEvents = selector<eventType[]>({
  key: 'pastEvents',
  get: ({get}) => {
    const allEvents = get(eventsPaginated);
    const today = new Date();

    if (allEvents == null) {
      return [];
    }

    const pastEventsList = allEvents.filter((event) => {
      const eventStartTime = new Date(event.start_time);
      return eventStartTime < today;
    });

    const orderedEvents = [...pastEventsList].reverse();

    return orderedEvents;
  },
});

export const eventsArchiveTitle = selector<{title: string, description: string}>({
  key: 'eventsArchiveTitle',
  get: ({get}) => {
    const period = get(eventPeriod);

    if (period === 'future') {
      return {
        title: 'KOMMANDE EVENEMANG',
        description: `Här hittar du alla kommande event som vi på Marknadsföreningen ` +
        `i Halland arrangerar. Vi lägger in nya hela tiden, så håll utkik!`,
      };
    } else {
      return {
        title: 'TIDIGARE EVENEMANG',
        description: `Här hittar du alla tidigare event som vi på Marknadsföreningen i Halland arrangerat.`,
      };
    }
  },
});

export const periodedEvents = selector<eventType[]>({
  key: 'periodedEvents',
  get: ({get}) => {
    const events = get(eventsPaginated);
    const period = get(eventPeriod);
    const eventsArray = events || [];
    const currentDate = new Date();

    if (period === 'future') {
      const futureEvents = eventsArray.filter((event: eventType) => {
        const eventDate = new Date(event.start_time);
        return eventDate > currentDate;
      });

      const sortedFutureEvents = futureEvents.sort((a, b) => {
        const dateA = new Date(a.start_time);
        const dateB = new Date(b.start_time);
        return dateA.getTime() - dateB.getTime();
      });

      return sortedFutureEvents;
    } else {
      const pastEvents = eventsArray.filter((event: eventType) => {
        const eventDate = new Date(event.start_time);
        return eventDate < currentDate;
      });

      const sortedPastEvents = pastEvents.sort((a, b) => {
        const dateA = new Date(a.start_time);
        const dateB = new Date(b.start_time);
        return dateB.getTime() - dateA.getTime();
      });

      return sortedPastEvents;
    }
  },
});

export const currentYearEvents = selector<eventType[]>({
  key: 'currentYearEvents',
  get: ({get}) => {
    const events: eventType[] = get(eventsPaginated);
    const currentYear = new Date().getFullYear();

    if (events == undefined) {
      return [];
    }
    const filteredEvents = events.filter((event: eventType) => {
      const eventYear = new Date(event.start_time).getFullYear();
      return eventYear === currentYear && event.event_type === 'Frukost klubben';
    });
    return filteredEvents;
  },
});

export const futureYearEvents = selector<eventType[]>({
  key: 'futureYearEvents',
  get: ({get}) => {
    const events: eventType[] = get(eventsPaginated);
    const futureYear = new Date().getFullYear() + 1;

    if (events == undefined) {
      return [];
    }
    const filteredEvents = events.filter((event: eventType) => {
      const eventYear = new Date(event.start_time).getFullYear();
      return eventYear === futureYear && event.event_type === 'Frukost klubben';
    });
    return filteredEvents;
  },
});

