import React, {FC} from 'react';

type CurrentPlanInfoModalTypes = {
  state: boolean;
  position: string;
  subStartDate: string;
  currentSubPrice: string;
}

const CurrentPlanInfoModal: FC<CurrentPlanInfoModalTypes> = ({
  state,
  position,
  subStartDate,
  currentSubPrice,
}) => {
  return (
    <div
      className={`${state ? 'flex' : 'hidden'} ${position} 
      infoDropdown w-max absolute bg-green-primary rounded-10px text-white items-center px-4 py-3 space-x-3`}
    >
      <div className='flex flex-col'>
        <span className='text-sm font-medium'>
          Påbörjades
        </span>
        <span className='text-lg font-bold'>
          {subStartDate !== '' ? subStartDate : 'Ingen prenumeration'}
        </span>
      </div>
      {subStartDate && (
        <div className='flex flex-col'>
          <span className='text-sm font-medium'>
            Pris
          </span>
          <span className='text-lg font-bold'>
            {currentSubPrice}
          </span>
        </div>
      )}
    </div>
  );
};

export default CurrentPlanInfoModal;
