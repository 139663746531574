import axios from 'axios';
import {createFormData} from '../../utils/createFormData';
import client from '../clients/base';

const VERIFY_EMAIL_URL = process.env.REACT_APP_API_URI + 'events/verify-email/';
const ENROLL_GUEST_URL = process.env.REACT_APP_API_URI + 'events/enroll-guest/';
const ENROLL_PARTNER_URL = process.env.REACT_APP_API_URI + 'events/register-partner/';
const ENROLL_USER_URL = 'events/enroll/';
const ENROLL_STATUS_URL = 'events/enroll/status/';
const ENROLL_CANCEL_URL = 'events/enroll/cancel/';

const verifyEmail = async (data: any) => {
  const res = await axios.post(VERIFY_EMAIL_URL, data);
  return res.data;
};

const enrollUser = async (data: any) => {
  try {
    const res = await client.post(ENROLL_USER_URL, data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const enrollGuest = async (data: any) => {
  const res = await axios.post(ENROLL_GUEST_URL, createFormData(data));
  return res.data;
};

const enrollPartner = async (data: any) => {
  const res = await axios.post(ENROLL_PARTNER_URL, createFormData(data));
  return res.data;
};

const checkEnrollStatus = async (id: number) => {
  const res = await client.post(ENROLL_STATUS_URL, {event_id: id});
  return res.data;
};

const cancelEnrollment = async (id: number) => {
  const res = await client.post(ENROLL_CANCEL_URL, {event_id: id});
  return res.data;
};

const actions = {
  verifyEmail,
  enrollGuest,
  enrollPartner,
  enrollUser,
  checkEnrollStatus,
  cancelEnrollment,
};

export default actions;
