import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';

type AccountUpgradeModalTypes = {
  title: string;
  selectedPlan: string;
  planPrice: string;
  isOpen: boolean;
  setIsOpen: Function;
  upgradePlan: Function;
};

const AccountUpgradeModal: FC<AccountUpgradeModalTypes> = ({
  title,
  selectedPlan,
  planPrice,
  isOpen,
  setIsOpen,
  upgradePlan,
}) => {
  return (
    <div
      className={`${isOpen ? 'block' : 'hidden'}
     w-screen h-full bg-blue-dark/50 fixed left-0 top-0 z-50 flex justify-center items-center px-5`}
    >
      <UICardComponent
        width="w-full lg:w-[560px]"
        padding="px-5 lg:px-16 py-7"
        extraStyles="h-fit"
      >
        <div className="flex flex-col text-center lg:text-left">
          <h2 className="text-26px lg:text-32px text-blue-dark font-bold mb-3 lg:mb-6">
            {title}
          </h2>
          <div className="text-sm lg:text-xl text-blue-dark">
            En ny faktura skickas på ditt uppgraderade medlemskap. Medlemskapet
            börjar gälla direkt.
          </div>
          <div className="bg-green-light px-6 py-8 font-bold text-lg mt-8 rounded-15px text-left">
            <div className="flex flex-col">
              <h3 className="text-green-primary">Medlemskap</h3>
              <span className="text-blue-dark">{selectedPlan}</span>
            </div>
            <div className="flex flex-col">
              <h3 className="text-green-primary">Pris</h3>
              <span className="text-blue-dark">{planPrice}</span>
            </div>
          </div>
          <div className="flex items-center space-x-5 w-full justify-center mt-8">
            <UIButtonCommon
              buttonTitle="AVBRYT"
              onClick={() => setIsOpen(!isOpen)}
              variant="redOutlined"
            />
            <UIButtonCommon
              buttonTitle="UPPGRADERA"
              variant="greenOutlined"
              extraStyles="!px-6"
              onClick={() => upgradePlan()}
            />
          </div>
        </div>
      </UICardComponent>
    </div>
  );
};

export default AccountUpgradeModal;
