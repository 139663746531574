import React, {FC} from 'react';
import {MdClose} from 'react-icons/md';
import UICardComponent from '../UIWrappers/UICardComponent';

type UIModalWrapperProps = {
  width: string;
  wrapperPadding: string;
  children: JSX.Element | JSX.Element[];
  onClick: Function;
  wrapperExtraStyles?: string;
}

const UIModalWrapper: FC<UIModalWrapperProps> = ({
  width,
  wrapperPadding,
  children,
  onClick,
  wrapperExtraStyles,
}) => {
  return (
    <div className={`fixed w-screen h-screen flex items-center justify-center top-0 left-0 bg-black-normal/60 z-30
    ${wrapperExtraStyles && wrapperExtraStyles}`}
    >
      <UICardComponent width={width} padding={wrapperPadding} extraStyles='h-fit'>
        <div className='flex flex-col relative items-center justify-center'>
          <MdClose
            onClick={() => onClick()}
            className='text-red-primary text-xl absolute -top-5 -right-5 md:-top-8 md:-right-8 cursor-pointer'/>
          {children}
        </div>
      </UICardComponent>
    </div>
  );
};

export default UIModalWrapper;
