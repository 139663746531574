import React, {FC} from 'react';

import UITextContentWrapper from '../../../UIWrappers/UITextContentWrapper';
import ContactFormComponent from './ContactFromComponent';

type ContactFormSectionProps = {
  title: string;
  heading: string;
  description: string;
};


const ContactFormSection: FC<ContactFormSectionProps> = ({
  title,
  heading,
  description,
}) => {
  return (
    <UITextContentWrapper
      title={title}
      variant='allInclusive'
      containerOneWidth='w-full lg:w-1/5'
      containerTwoWidth='w-full lg:w-4/5'
    >
      <ContactFormComponent
        heading={heading}
        description={description}
      />
    </UITextContentWrapper>
  );
};

export default ContactFormSection;

