import React, {FC} from 'react';

import UIVideoComponent from './UIVideoComponent';

import imagePlaceholder from '../../assets/placeholder-image.png';

type UIHeroComponentProps = {
  height: string;
  isExtraImage: boolean;
  image?: string;
  mobileImage?: string;
  hBackgroundColor?: string;
  video?: string;
  text?: string;
  buttonTitle?: string;
  buttonUrl?: string;
  extraButtonTitle?: string;
  extraButtonUrl?: string;
  showOverlay?: boolean;
  isAllinclusive?: boolean;
  description?: string;
  buttonOnClick?: () => void;
  extraButtonOnClick?: () => void;
  extraImage?: string;
}

const UIHeroComponent: FC<UIHeroComponentProps> = ({
  height,
  isExtraImage = false,
  image,
  mobileImage,
  hBackgroundColor='#FFFFFF',
  video,
  text,
  buttonTitle,
  buttonUrl,
  extraButtonTitle,
  extraButtonUrl,
  showOverlay = true,
  isAllinclusive = false,
  description,
  buttonOnClick,
  extraButtonOnClick,
  extraImage,
}) => {
  return (
    <div style={{backgroundColor: `${hBackgroundColor}`, borderRadius: '12px'}}
      className={`relative w-full flex justify-center items-center ${height} mt-32`}>
      <div className='flex flex-col items-center sticky z-20 px-5 xl:px-0'>
        <div className='relative'>
          {isExtraImage && (
            <img
              src={extraImage} alt=""
              className = {`${isAllinclusive ? '!block lg:w-32 left-1/2 right-1/2 translate-y-[-50%] ' +
              'lg:transform-none translate-x-[-50%] -top-8' : ''} 
              hidden lg:block absolute lg:-left-24 lg:-top-12 w-20`}
            />
          )}
          {text && (
            <h1 className={`manrope text-center font-semibold text-31px lg:text-4xl text-white uppercase w-full
              lg:w-full`}>
              {text}
            </h1>
          )}
          {description && (
            <div className='font-bold text-18px sm:text-26px text-white pt-[4px] text-center'
              dangerouslySetInnerHTML={{__html: description}}
            />
          )}
        </div>
        {buttonTitle && (
          <div className={`flex flex-col sm:flex-row items-center inter text-basse text-white font-normal ` +
          `lg:font-medium mt-8 ${isAllinclusive ? 'flex !flex-row text-xs sm:text-base' : ''} ` +
            `${extraButtonTitle != null && 'sm:space-x-4'}`
          }>
            {extraButtonTitle != null && (
              <a
                onClick={extraButtonOnClick}
                href={extraButtonUrl}
                className={`border-white inline-block border rounded-full px-5 py-3 text-center w-full sm:w-auto ` +
                `hover:bg-white hover:text-green-primary transition-colors duration-150 cursor-pointer ` +
                `${isAllinclusive ? 'border-pink-primary bg-pink-primary text-pink-dark hover:bg-pink-dark ' +
                'hover:text-gray-light hover:border-pink-dark' : ''}`
                }
              >
                {extraButtonTitle}
              </a>
            )}
            <a
              onClick={buttonOnClick}
              href={buttonUrl}
              className={`bg-green-primary inline-block rounded-full px-16 lg:px-5 py-3 cursor-pointer text-center ` +
              `hover:bg-white hover:text-green-primary transition-colors duration-150 w-full sm:w-auto mt-4 sm:mt-0 ` +
              `${isAllinclusive ? 'border-white bg-gray-light text-pink-dark !px-3 mt-0 ml-3 ' +
              'hover:bg-pink-dark hover:text-gray-light' : '' }`
              }
            >
              {buttonTitle}
            </a>
          </div>
        )}
      </div>
      {showOverlay && (
        <div className={
          `h-full w-full ${isAllinclusive ? 'bg-gray-dark/50' : 'bg-gray-dark/75'}  absolute 
          top-0 left-0 z-10 rounded-15px`}> </div>
      )}
      {video == undefined ? (
        <>
          {mobileImage ? (
            <>
              {image && (
                <img
                  src={image} alt="image"
                  className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px hidden sm:block'
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = imagePlaceholder;
                  }}
                />
              )}
              <img
                src={mobileImage} alt="image"
                className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px block sm:hidden'
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = imagePlaceholder;
                }}
              />
            </>
          ) : (
            <>
              {image && (
                <img
                  src={image} alt="image"
                  className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px'
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = imagePlaceholder;
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <UIVideoComponent
          image={image}
          video={video}
        />
      )}
    </div>
  );
};

export default UIHeroComponent;
