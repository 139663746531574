import React, {FC, useState} from 'react';
import StatisticsCardComponent from './StatisticsCardComponent';

type statisticsArrayTypes = {
  title: string;
  value: string;
  descriptionTitle: string;
  description: string;
}

const statisticsArray = [
  {
    title: 'nya kontakter',
    value: '95%',
    descriptionTitle: 'Nya affärer via vårt nätverk',
    description: `95 % har fått nya kontakter via MiH och mer än varannan person som kommertill
    våra events har lyckas att genomföra en ny affär. Tänk vad lite nätverkande kan göra!`,
  },
  {
    title: 'Grundades',
    value: '1965',
    descriptionTitle: 'Året föreningen grundades',
    description: `Det är drygt 50 år sedan Marknadsföreningen i Halland grundades.
    Det har hänt mycket under denna tiden och vi är idag en väletablerad förening där entreprenörer
    från hela Halland samlas. Första mötet ägde rum på Jarlen i Halmstad 28 april 1965.`,
  },
  {
    title: 'Mässa & Partnerskap',
    value: '30+',
    descriptionTitle: 'Mässa & partnerskap',
    description: `
    Stort tack till alla våra partners som möjliggör en dynamisk mötesplats för näringslivet 
    med föreläsare i toppklass. Som partner har du även möjlighet att ställa ut på vår mässa 
    som arrangeras vid varje tillfälle i samband med Frukostklubben Before Work. Bli guld-. 
    silver- eller bronspartner.`,
  },
  {
    title: 'årets marknads-förening i sverige',
    value: '2012',
    descriptionTitle: 'Årets marknadsförening',
    description: `
    På Berns i Stockholm tog vi emot pris som Årets Marknadsförening i Sverige 
    2012. Marknadsföreningen i Halland bidrar till regional tillväxt, skapar goda affärer 
    och är den naturliga mötesplatsen för det halländska näringslivet. Vi bygger broar mellan 
    näringsliv, akademi och skola. Sturegymnasiet är värdar på Frukostklubben, vi stödjer 
    Ung Företagsamhet och delar ut 10 000 kr till Bästa UF-Marknadsförare.`,
  },
];

const StatisticsSectionComponent: FC = () => {
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full'>
      {statisticsArray && statisticsArray.map((el: statisticsArrayTypes, index: number) => (
        <StatisticsCardComponent
          key={index}
          index={index}
          title={el.title}
          value={el.value}
          descriptionTitle={el.descriptionTitle}
          description={el.description}
          openCardIndex={openCardIndex}
          setOpenCardIndex={setOpenCardIndex}
        />
      ))}
    </div>
  );
};

export default StatisticsSectionComponent;
