import React, {FC} from 'react';

type UITagProps = {
  name: string;
  extraStyles?: string;
}

const UITag:FC<UITagProps> = ({name, extraStyles=''}) => {
  return (
    <div className={`${extraStyles} rounded-full px-3 py-2 bg-pink-primary text-pink-dark text-sm
    font-medium`}
    >
      {name}
    </div>
  );
};

export default UITag;
