import React, {FC} from 'react';


type EventsAddToCalendarBtnProps = {
  buttonTitle: string;
  onClick: Function;
}

const EventsAddToCalendarBtn : FC<EventsAddToCalendarBtnProps> = (
    {
      buttonTitle,
      onClick,
    }) => {
  return (
    <div className={`w-fit m-0`}>
      <button
        type='button'
        onClick={() => onClick()}
        className={`border border-gray-black/60 text-gray-black/60 text-xs ` +
        `hover:text-white hover:border-green-primary px-4 py-2 hover:bg-green-primary ` +
        `transition-all ease-in-out duration-150 rounded-full text-center w-auto block`}
      >
        {buttonTitle}
      </button>
    </div>
  );
};

export default EventsAddToCalendarBtn;
