import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'partners/';

const fetchPartners = async () => {
  const response = await axios.get(URL);
  return response.data;
};

const actions = {
  fetchPartners,
};

export default actions;
