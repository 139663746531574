import React, {FC} from 'react';
import UIPageContainer from '../UIWrappers/UIPageContainer';

type UILoadingIndicatorProps = {
  height?: string;
}

const UILoadingIndicator: FC<UILoadingIndicatorProps> = ({height='h-screen'}) => {
  return (
    <UIPageContainer>
      <div className={`flex w-full justify-center items-center ${height}`}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </UIPageContainer>
  );
};

export default UILoadingIndicator;
