import {useMutation} from 'react-query';
import actions from '../../api/events/eventsEnrollment';

const useMutateEnrollToEvent = () => {
  return useMutation(async (data: any) => {
    await actions.enrollUser(data);
  }, {retry: false});
};

export default useMutateEnrollToEvent;
