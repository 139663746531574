import React, {FC, useRef, useState} from 'react';

import {formatVideoLink} from '../../utils/formatYoutubeLink';
import UIModalWrapper from '../UIModals/UIModalWrapper';
import playIcon from '../../assets/play_icon.svg';

type VideoLinkComponentTypes = {
  title: string;
  videoUrl: string;
}

const VideoLinkComponent:FC<VideoLinkComponentTypes> = ({title, videoUrl}) => {
  const [showModal, setShowModal] = useState(false);
  const link = formatVideoLink(videoUrl);
  const playerRef = useRef<HTMLIFrameElement>(null);

  const handleClose = () => {
    if (playerRef.current) {
      setShowModal(!showModal);
      playerRef.current.contentWindow?.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    }
  };

  return (
    <>
      <div className='w-full flex py-2 lg:py-5 justify-between text-gray-black border-b border-black-normal/30'>
        <h2 className='text-base lg:text-lg'>
          {title}
        </h2>
        <button onClick={() => setShowModal(!showModal)}>
          <img src={playIcon} alt="play_icon" />
        </button>
      </div>
      <UIModalWrapper
        width='w-fit'
        wrapperPadding='px-6 py-6 md:px-12 md:py-12'
        onClick={() => handleClose()}
        wrapperExtraStyles={`${showModal ? 'block' : 'hidden'}`}>
        <iframe
          className='w-auto h-auto md:w-[560px] md:h-[315px]'
          ref={playerRef}
          src={`https://www.youtube.com/embed/${link}?enablejsapi=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        >
        </iframe>
      </UIModalWrapper>
    </>
  );
};

export default VideoLinkComponent;
