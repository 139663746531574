import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {aboutPage} from '../../state/pages/pages';
import {teamType} from '../../types/pages';
import StaffItemComponent from './StaffItemComponent';


const StaffSectionComponent:FC = () => {
  const data = useRecoilValue(aboutPage);

  return (
    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-6 py-16'>
      {data && data.team.length > 0 && data.team.map((el: teamType, index: number) => (
        <StaffItemComponent
          key={index}
          name={el.full_name}
          extraInfo={el.position}
          photoUrl={el?.image}
        />
      ))}
    </div>
  );
};

export default StaffSectionComponent;
