import {useMutation} from 'react-query';
import actions from '../../api/newsletter/newsletter';

const useMutateNewsletterSubscribe = () => {
  return useMutation(async (email: string) => {
    await actions.subscribeNewsletter(email);
  }, {retry: false});
};

export default useMutateNewsletterSubscribe;
