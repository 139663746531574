import React, {FC} from 'react';
import {FiChevronDown} from 'react-icons/fi';
import UICardComponent from '../UIWrappers/UICardComponent';

type StatisticsCardComponentTypes = {
  title: string;
  value: string;
  descriptionTitle: string;
  description: string;
  index: number;
  openCardIndex: number | null;
  setOpenCardIndex: Function;
}

const StatisticsCardComponent: FC<StatisticsCardComponentTypes> = ({
  title,
  value,
  descriptionTitle,
  description,
  index,
  openCardIndex,
  setOpenCardIndex,
}) => {
  const isOpen = openCardIndex === index;

  const handleCardClick = () => {
    if (isOpen) {
      setOpenCardIndex(null); // Jeśli kliknięta karta jest już otwarta, zamknij ją
    } else {
      setOpenCardIndex(index); // W przeciwnym razie, otwórz klikniętą kartę
    }
  };

  return (
    <div className='relative'>
      <UICardComponent
        width='w-full'
        padding='pl-7 pr-5 py-12'
        extraStyles='justify-center'
      >
        <div className='flex items-center justify-between text-black-dark/80'>
          <h2 className='text-bases lg:text-lg font-medium uppercase'>
            {title}
          </h2>
          <div className='flex items-center'>
            <span className='text-3xl font-semibold mr-3'>
              {value}
            </span>
            <button type='button' onClick={() => handleCardClick()}>
              <FiChevronDown
                className={`text-2xl ${isOpen ? 'rotate-180' : ''} transition-all duration-150 ease-out`}
              />
            </button>
          </div>
        </div>
      </UICardComponent>
      <UICardComponent
        width='w-full lg:w-[205%]'
        padding='pl-7 pr-5 py-12'
        extraStyles={`
        ${index === 3 ? 'right-0' : 'left-0'}
        absolute z-20 shadow-sm h-auto mt-4 ${isOpen ? 'block' : 'hidden'}
        `}
      >
        <div className='flex flex-col text-black-dark/80'>
          <h2 className='font-semibold text-3xl mb-4'>
            {descriptionTitle}
          </h2>
          <p className='text-xl'>
            {description}
          </p>
        </div>
      </UICardComponent>
    </div>
  );
};

export default StatisticsCardComponent;
