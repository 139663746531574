import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';


type PlanSummaryComponentProps = {
  stepName: string;
  stepOrder: number;
  totalSteps: number;
  selectedPlan: string;
  selectedPlanPrice: number;
  onSubmit: Function;
  isDisabled: boolean;
  isInvited?: boolean;
}

const PlanSummaryComponent: FC<PlanSummaryComponentProps> = ({
  stepName,
  stepOrder,
  totalSteps,
  selectedPlan,
  selectedPlanPrice,
  onSubmit,
  isDisabled,
  isInvited = false,
}) => {
  return (
    <div className={`flex flex-col ${isDisabled == true ? 'opacity-30' : 'opacity-100'}`}>
      <div className={`flex items-center text-center px-8 py-2 mb-4
      bg-green-primary text-white text-lg font-semibold w-fit rounded-full`}>
        {stepName} {stepOrder}/{totalSteps}
      </div>
      <UICardComponent width='w-full' padding='sm:pl-9 sm:pr-12 lg:pr-24 pt-10 pb-9' extraStyles='!rounded-30px'>
        <div className='flex w-full items-center'>
          <div className={`flex flex-col md:flex-row space-y-6 md:space-y-0 
          md:space-x-8 lg:space-x-20 w-full md:w-2/3 lg:w-4/5`}>
            <div className='flex flex-col w-full md:w-1/2 space-y-4 text-black-dark'>
              <h3 className='text-base font-semibold'>
                Din beställning
              </h3>
              {!isInvited ? (
                <div
                  className='text-22px md:text-32px font-semibold'
                  dangerouslySetInnerHTML={{__html: '12 månader - ' + selectedPlan}}
                />
              ) : (
                <div
                  className='text-22px md:text-32px font-semibold'
                  dangerouslySetInnerHTML={{__html: selectedPlan}}
                />
              )}
            </div>
            <div className='flex flex-col w-full md:w-1/2 space-y-4 text-black-dark'>
              <h3 className='text-base font-semibold'>
                Pris
              </h3>
              <span className='text-22px md:text-32px font-semibold'>
                {selectedPlanPrice}kr ex. moms.
              </span>
            </div>
          </div>
          <div className='hidden md:flex justify-end md:w-1/3 lg:w-1/5'>
            <UIButtonCommon
              buttonTitle='Skapa & Bekräfta'
              buttonType='button'
              variant='greenFilled'
              onClick={onSubmit}
              extraStyles='hover:!bg-blue-dark hover:!text-white'
            />
          </div>
        </div>
      </UICardComponent>
      <div className='flex md:hidden w-full justify-center py-12'>
        <UIButtonCommon
          buttonTitle='Skapa & Bekräfta'
          buttonType='button'
          variant='greenFilled'
          width='w-full'
          onClick={onSubmit}
          extraStyles='hover:!bg-blue-dark hover:!text-white !w-full'
        />
      </div>
    </div>
  );
};

export default PlanSummaryComponent;
