import React, {FC, useEffect, useState} from 'react';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {CgClose} from 'react-icons/cg';
import useRefetchCurrentUser from '../../hooks/auth/useRefetchCurrentUser';
import useMutateOrganisationAdmin from '../../hooks/user/useMutateOrganisationAdmin';
import useMutateRemoveUserFromOrganisation from '../../hooks/user/useMutateRemoveUserFromOrganisation';

import UIAvatar from '../UICommon/UIAvatar';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import ButtonIconComponent from './ButtonIconComponent';

type MemberItemComponentTypes = {
  id: number;
  image: string;
  name: string;
  position: string;
  disabled: boolean;
}

const MemberItemComponent: FC<MemberItemComponentTypes> = ({
  id,
  image,
  name,
  position,
  disabled,
}) => {
  const {
    isLoading: isPassAdminLoading,
    isError: isPassAdminError,
    isSuccess: isPassAdminSuccess,
    mutate: mutatePassAdmin,
  } = useMutateOrganisationAdmin();
  const {
    isLoading: isRemoveLoading,
    isError: isRemoveError,
    isSuccess: isRemoveSuccess,
    mutate: mutateRemove,
  } = useMutateRemoveUserFromOrganisation();
  const {
    isLoading: isLoadingCurrentUser,
    refetch: refetchCurrentUser,
  } = useRefetchCurrentUser();
  const [isShowed, setIsShowed] = useState(false);

  const passOrganisationAdmin = () => {
    mutatePassAdmin(id);
  };

  const removeUser = () => {
    mutateRemove(id);
  };

  useEffect(() => {
    if (isRemoveError) {
      console.log(isRemoveError);
    }

    if (isRemoveSuccess) {
      refetchCurrentUser();
    }
  }, [isRemoveError, isRemoveSuccess]);

  useEffect(() => {
    if (isPassAdminError) {
      console.log(isPassAdminError);
    }

    if (isPassAdminSuccess) {
      window.location.reload();
    }
  }, [isPassAdminError, isPassAdminSuccess]);

  if (isPassAdminLoading || isRemoveLoading || isLoadingCurrentUser) {
    return (
      <UILoadingIndicator />
    );
  }

  return (
    <div className={`flex md:items-center w-full justify-between py-4 border-b 
    border-b-gray-semiLight last:!border-none`}>
      <div className='flex items-center w-1/2'>
        <UIAvatar
          image={image}
          size='h-12 w-12'
        />
        <div className='flex flex-col ml-12'>
          <h4 className='text-gray-common text-xs md:text-sm font-medium'>
            Namn
          </h4>
          <span className='text-base md:text-xl font-bold'>
            {name}
          </span>
        </div>
      </div>
      <div className='flex flex-col w-1/4'>
        <h4 className='text-gray-common text-sm font-medium'>
          Yrke
        </h4>
        <span className='text-base md:text-xl font-bold'>
          {position}
        </span>
      </div>
      {!disabled && (
        <>
          <div className='flex relative'>
            {!isShowed ? (
              <ButtonIconComponent
                onClick={() => setIsShowed(!isShowed)}
                variant='blank'
                icon={<BiDotsVerticalRounded/>}
                extraStyles='text-black-dark text-xl'
              />
            ) : (
              <ButtonIconComponent
                onClick={() => setIsShowed(!isShowed)}
                variant='blank'
                icon={<CgClose/>}
                extraStyles='text-black-dark text-xl'
              />
            )}
            <div
              className={`${!isShowed ? 'hidden' : 'block'} overflow-hidden
              rounded-10px border border-gray-dropdown absolute w-max bg-white z-10 -left-[600%] top-[105%]`}>
              <ButtonIconComponent
                onClick={() => passOrganisationAdmin()}
                variant='blank'
                text='Gör till admin'
                extraStyles='text-black-deep text-base font-semibold p-2 px-3 hover:bg-gray-semiLight'
              />
              <ButtonIconComponent
                onClick={() => removeUser()}
                variant='blank'
                text='Ta bort'
                extraStyles='text-red-500 text-base font-semibold py-2 px-3 hover:bg-gray-semiLight w-full'
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MemberItemComponent;
