import React, {FC} from 'react';
import {ButtonIconComponentVariant} from '../../variants/ButtonIconComponentVariant';

type ButtonIconComponentTypes = {
  variant: keyof typeof ButtonIconComponentVariant;
  icon?: JSX.Element;
  text?: string;
  onClick?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  extraStyles?: string;
}

const ButtonIconComponent: FC<ButtonIconComponentTypes> = ({
  icon,
  text,
  onMouseEnter,
  onMouseLeave,
  onClick,
  variant,
  extraStyles,
}) => {
  const variantClasses = {
    [ButtonIconComponentVariant.white]: `md:bg-white md:text-gray-common font-medium text-base md:rounded-15px 
    px-4 py-3 hover:text-green-primary hover:md:bg-green-light bg-blue-dark text-white text-center 
    w-full justify-center border-b-4 md:border-none rounded-none`,
    [ButtonIconComponentVariant.green]: `bg-green-light lg:bg-green-primary text-green-primary lg:text-white
    font-medium text-2xl rounded-lg px-4 py-3`,
    [ButtonIconComponentVariant.blank]: ``,
  };
  return (
    <button
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      type='button'
      onClick={() => onClick && onClick()}
      className={`${variantClasses[variant]} ${extraStyles && extraStyles} 
      flex items-center transition-colors duration-150 ease-in-out`}>
      {icon && (
        <div>
          {icon}
        </div>
      )}
      {text && (
        <div className='ml-2'>
          {text}
        </div>
      )}
    </button>
  );
};

export default ButtonIconComponent;
