import React, {FC} from 'react';

const UILoadingIndicatorFixed: FC = () => {
  return (
    <div className={`p-6 flex flex-col h-full w-full items-center justify-center text-navy-primary
     left-0 top-0 z-50 mx-auto text-center fixed bg-black-dark bg-opacity-40`}>
      <div className="loader"></div>
    </div>
  );
};

export default UILoadingIndicatorFixed;
