import React, {FC} from 'react';

type HeroComponentTypes = {
  title: string;
  image: string;
  heading: string;
  extraSpacing?: string;
  bgColor?: string;
}

const HeroComponent: FC<HeroComponentTypes> = ({
  title,
  image,
  heading,
  extraSpacing='pb-20',
  bgColor='#FFFFFF',
}) => {
  return (
    <div className={`flex flex-col w-full pt-32 ${extraSpacing}`}>
      <h2 className='text-25px lg:text-75px font-semibold mb-6 lg:mb-4 w-full uppercase'
        dangerouslySetInnerHTML={{__html: title}}
      />
      <div className='flex md:hidden text-black-dark/80 font-medium mb-8 text-sm'>
        OM MIH
      </div>
      <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 w-full'>
        <div style={{backgroundColor: `${bgColor}`, borderRadius: '12px'}}
          className={`w-full lg:w-2/3 h-auto lg:h-[556px]`}>
          {image && (
            <img className='h-full w-full object-cover rounded-15px' src={image} alt="image"/>
          )}
        </div>
        <div className='w-full lg:w-1/3 h-auto'>
          <div className={`w-full h-64 lg:h-full bg-green-primary rounded-15px text-white flex ` +
            `flex-col justify-between lg:justify-end pt-5 lg:pt-0 px-3 lg:px-5 pb-6 lg:pb-9 `
          }>
            <span className='flex lg:hidden text-28px font-bold'>
              mih.nu
            </span>
            <div>
              <span className='text-sm lg:text-lg font-medium'>
                NÄTVERK & KUNSKAP
              </span>
              <h3 className='text-31px lg:text-42px font-bold'>
                {heading}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
