import {testimonialsResponseType} from '../types/testimonials';

export default function getRandomItems(arr: testimonialsResponseType) {
  const indices: number[] = [];
  if (arr == null) {
    return arr;
  }
  for (let i = 0; i < arr.length; i++) {
    indices[i] = i;
  }

  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp: number = indices[i];
    indices[i] = indices[j];
    indices[j] = temp;
  }

  return [arr[indices[0]], arr[indices[1]]];
}
