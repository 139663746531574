export const parseHtml = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const walker = document.createTreeWalker(
      doc.body,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
      {
        acceptNode: (node) => {
          return NodeFilter.FILTER_ACCEPT;
        },
      },
  );

  const result: string[] = [];
  let currentNode;

  while ((currentNode = walker.nextNode())) {
    if (currentNode.nodeType === Node.ELEMENT_NODE) {
      const element = currentNode as Element;
      if (element.tagName === 'IMG' || element.tagName === 'A') {
        continue;
      }
    } else if (currentNode.nodeType === Node.TEXT_NODE) {
      result.push(currentNode.nodeValue as string);
    }
  }

  return result.join(' ');
};

