import React, {FC} from 'react';

import {eventType} from '../../types/event';

import UIEventCard from '../UICommon/UIEventCard';

type EventsArchiveSectionComponentProps = {
  data: eventType[]
}

const EventsArchiveSectionComponent: FC<EventsArchiveSectionComponentProps> = ({
  data,
}) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-10 inter mt-40'>
      {data && data.length > 0 && data.map((el: eventType) => (
        <UIEventCard
          key={el.id}
          isImage={true}
          event={el}
        />
      ))}
    </div>
  );
};

export default EventsArchiveSectionComponent;
