import React, {FC, useEffect, useState} from 'react';

import {isValidEmail} from '../../utils/isValid';
import {EventTargetType} from '../../types/eventTarget';
import useMutateNewsletterSubscribe from '../../hooks/newsletter/useMutateNewsletterSubscribe';

import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

type UIEnrollmentNewsletterFormProps = {
  buttonText: string;
  extraButtonWrapperStyles?: string;
}

const UIEnrollmentNewsletterForm: FC<UIEnrollmentNewsletterFormProps> = ({buttonText, extraButtonWrapperStyles}) => {
  const [email, setEmail] = useState<string>('');
  const [messageInfo, setMessageInfo] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutate,
  } = useMutateNewsletterSubscribe();

  const onChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {value} = event.target;
    setEmail(value);
  };

  const handleSubmit = () => {
    setMessageInfo('');
    setSuccess(false);
    if (email.length === 0) {
      setMessageInfo('Skriv in epostadress');
      return;
    }
    if (isValidEmail(email)) {
      mutate(email);
    } else {
      setMessageInfo('Vänligen ange giltig e-post.');
      return;
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccess(true);
    }

    if (isError) {
      const err: any = error;
      if (err.response.data.title === 'Member Exists') {
        setMessageInfo('Denna e-postadress finns redan registrerad.');
      } else {
        setMessageInfo('Ett fel uppstod när du prenumererade på nyhetsbrevet. Uppdatera sidan och försök igen.');
      }
    }
  }, [isSuccess, isError, error]);

  return (
    <div className={`flex flex-col`}>
      <div className='relative flex flex-col lg:flex-row items-center manrope'>
        <input
          type="text"
          name='email'
          placeholder='Fyll i din email'
          onChange={onChange}
          className={`bg-white lg:bg-gray-light border-gray-black/20 lg:border ` +
          `rounded-2xl w-full py-5 md:py-3 lg:py-5 pl-2 lg:pl-4 text-base lg:text-xl`}
        />
        <div className={`w-full lg:w-fit lg:absolute top-0 right-0 bottom-0 ${extraButtonWrapperStyles}`}>
          <button
            type='button'
            onClick={() => handleSubmit()}
            className={`text-sm md:text-base lg:absolute top-1 right-1 bottom-1 bg-green-primary text-white ` +
            `px-3 py-2 lg:px-6 lg:py-3 rounded-xl transition-all ease-in-out duration-150 ` +
            `text-center w-full lg:w-max block font-semibold h-12 lg:h-auto`}
          >
            {buttonText}
          </button>
        </div>
      </div>
      {success && (
        <small className="text-green-600 font-semibold text-xs mt-2 ml-3">
          Du har prenumererat på nyhetsbrevet, tack!
        </small>
      )}
      {messageInfo.length !== 0 && (
        <small className="text-red-600 font-semibold text-xs mt-2 ml-3">{messageInfo}</small>
      )}
      {isLoading && (
        <UILoadingIndicatorFixed />
      )}
    </div>
  );
};

export default UIEnrollmentNewsletterForm;
