import {atom} from 'recoil';
import {selectedPlanType, subscriptionsTypes} from '../../types/subscriptions';

export const subscriptionsArray = atom<subscriptionsTypes[]>({
  key: 'subscriptionsArray',
  default: undefined,
});

export const selectedPlan = atom<selectedPlanType>({
  key: 'selectedPlan',
  default: undefined,
});

