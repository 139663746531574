import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/pages/pages';
import {aboutPage} from '../../state/pages/pages';

const useFetchAboutPage = () => {
  const setAboutPage = useSetRecoilState(aboutPage);

  return useQuery('fetchAboutPage', async () => {
    const aboutPage = await actions.fetchAboutPage();
    setAboutPage(aboutPage);
    return aboutPage;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchAboutPage;
