import React, {FC} from 'react';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import RegisterPartnerForm from '../components/RegisterPartnerPage/RegisterPartnerForm';

const RegisterPartnerPage: FC = () => {
  return (
    <main className='bg-white lg:bg-gray-body'>
      <div className='absolute top-0 left-0 z-0 block h-[41vh] md:h-[45vh] lg:h-[80vh] bg-blue-dark w-full'/>
      <UIPageContainer>
        <UITextWrapper
          heading=''
          title='Här anmäler du dig som är partner & ifall du skall ställa ut på montern'
          description=''
          headingStyles='text-white mt-2 lg:mt-8'
          titleStyles='text-white'
          descriptionStyles='text-white font-normal'
        />
        <RegisterPartnerForm/>
      </UIPageContainer>
    </main>
  );
};

export default RegisterPartnerPage;
