import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/testimonials/testimonials';
import {testimonials} from '../../state/testimonials/testimonials';

const useFetchTestimonials = () => {
  const setTestimonials = useSetRecoilState(testimonials);

  return useQuery('useFetchTestimonials', async () => {
    const testimonials = await actions.fetchTestimonials();
    setTestimonials(testimonials);
    return testimonials;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchTestimonials;
