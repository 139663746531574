import React, {FC, useEffect, useState} from 'react';

import imgInputIcon from '../../assets/image_input_icon.svg';

type UIFileInputTypes = {
  id: string;
  label: string;
  name: string;
  setFile?: Function;
  currentAvatar?: string | undefined;
  isPortal?: boolean;
  formErrorMessage?: string;
  disabled?: boolean;
};

const UIFileInput: FC<UIFileInputTypes> = ({
  id,
  label,
  name,
  setFile,
  currentAvatar,
  isPortal = false,
  formErrorMessage,
  disabled,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (currentAvatar) {
      setPreviewUrl(currentAvatar);
    }
  }, [currentAvatar]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    if (setFile) {
      setFile(file);
    }
  };

  return (
    <div className='flex flex-col'>
      <div className={`flex items-center relative`}>
        <label
          className={`${!isPortal ? 'flex flex-col w-[120px]' : '!flex'} items-center cursor-pointer`}
          htmlFor={id}
        >
          {previewUrl ? (
            <img className={`${!isPortal ? 'mb-2 h-[120px] w-[120px]' : 'mr-3 h-[55px] w-[55px]'} 
            object-cover rounded-full cursor-pointer`}
            src={previewUrl} alt="Preview"
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = imgInputIcon;
            }}
            />
          ) : (
            <img className={`${!isPortal ? 'mb-2 h-[120px] w-[120px]' : 'mr-3 h-[55px] w-[55px]'} 
              object-cover rounded-full cursor-pointer`}
            src={imgInputIcon} alt="Placeholder"
            />
          )}
          {!disabled && (
            <span className={`${!isPortal ? 'text-green-primary text-sm text-center' : 'text-gray-common text-lg'} 
            underline`}>
              {label}
            </span>
          )}
        </label>
        <input
          id={id}
          name={name}
          disabled={disabled}
          className='inputFile w-[210px] -left-2 cursor-pointer'
          type="file" onChange={handleFileChange}
        />
      </div>
      <small className="text-red-600 font-semibold text-xs mt-1">
        {formErrorMessage}
      </small>
    </div>
  );
};

export default UIFileInput;
