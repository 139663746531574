import React, {FC, useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router';

import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';

import {isLoggedIn} from '../../utils/isLoggedIn';
import {isValidEmail} from '../../utils/isValid';
import {enrollEvent} from '../../state/events/enrollEvent';
import {EventTargetType} from '../../types/eventTarget';
import useMutateEnrollToEvent from '../../hooks/events/useMutateEnrollToEvent';
import useMutateVerifyEmail from '../../hooks/events/useMutateVerifyEmail';
import useMutateCancelEnrollment from '../../hooks/events/useMutateCancelEnrollment';
import useFetchEvents from '../../hooks/events/useFetchEvents';

import UIButtonCommon from '../UIButtons/UIButtonCommon';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';
import UIEnrollmentFormErrorMessage from './UIEnrollmentFormErrorMessage';

type UIEnrollmentFormComponentProps = {
  buttonVariant: keyof typeof UIButtonCommonVariants;
  buttonTitle: string;
  position: string;
  userEmail: string;
  isEnrolled?: boolean;
  setIsEnrolled?: Function;
  eventId?: number;
  buttonExtraStyles?: string;
}

const UIEnrollmentFormComponent: FC<UIEnrollmentFormComponentProps> = ({
  buttonTitle,
  buttonVariant,
  position,
  isEnrolled,
  setIsEnrolled,
  eventId,
  buttonExtraStyles,
  userEmail,
}) => {
  const [formValues, setFormValues] = useState({
    event_id: eventId,
    email: userEmail,
  });
  const [messageInfo, setMessageInfo] = useState('');
  const setEnrollmentData = useSetRecoilState(enrollEvent);
  const {
    isLoading: isEnrollLoading,
    isError: isEnrollError,
    error: enrollError,
    isSuccess: isEnrollSuccess,
    mutate: mutateEnroll,
  } = useMutateEnrollToEvent();
  const {
    isLoading: isVerifyLoading,
    isError: isVerifyError,
    error: verifyError,
    isSuccess: isVerifySuccess,
    mutate: mutateVerify,
  } = useMutateVerifyEmail();
  const {
    isLoading: isLoadingCancelEnrollment,
    isSuccess: isSuccessCancelEnrollment,
    isError: isErrorCancelEnrollment,
    error: cancelEnrollmentError,
    mutate: mutateCancelEnrollment,
  } = useMutateCancelEnrollment();
  const {isLoading: isLoadingEvents, refetch: refetchEvents} = useFetchEvents();
  const navigate = useNavigate();

  const cancelEnrollment = () => {
    if (eventId != null) {
      mutateCancelEnrollment(Number(eventId));
    }
  };

  const handleEventData = (id: number, email: string) => {
    setEnrollmentData({
      email: email,
      event_id: id,
    });
    navigate('/register-guest/');
  };

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormValues({
      event_id: eventId,
      email: userEmail,
    });
  }, [userEmail]);

  const handleSubmit = () => {
    setMessageInfo('');
    if (!isValidEmail(formValues.email)) {
      setMessageInfo('email');
      return;
    }
    if (isLoggedIn() && isValidEmail(formValues.email)) {
      mutateEnroll(formValues);
    } else {
      mutateVerify(formValues);
    }
  };

  useEffect(() => {
    if (isEnrollSuccess) {
      setMessageInfo('success');
      refetchEvents();
      setIsEnrolled && setIsEnrolled(true);
    }

    if (isEnrollError) {
      const err: any = enrollError;
      if (err.response.status == 404) {
        setMessageInfo('exists');
      } else {
        setMessageInfo(err.response.data);
      }
    }
  }, [isEnrollSuccess, isEnrollError, enrollError]);

  useEffect(() => {
    if (isVerifySuccess) {
      setMessageInfo('notFound');
      refetchEvents();
    }

    if (isVerifyError) {
      const err: any = verifyError;
      if (err.response.status == 404) {
        setMessageInfo('exists');
      } else {
        setMessageInfo(err.response.data);
      }
    }
  }, [isVerifySuccess, isVerifyError, verifyError]);

  useEffect(() => {
    if (isSuccessCancelEnrollment) {
      setMessageInfo('canceled');
      refetchEvents();
      setIsEnrolled && setIsEnrolled(false);
    }

    if (isErrorCancelEnrollment) {
      const err: any = cancelEnrollmentError;
      setMessageInfo(err.response.data);
    }
  }, [isSuccessCancelEnrollment, isErrorCancelEnrollment, cancelEnrollmentError]);

  if (isEnrollLoading || isVerifyLoading || isLoadingCancelEnrollment || isLoadingEvents) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  return (
    <div className='flex flex-col'>
      {isEnrolled ? (
        <div className="flex w-full">
          <button
            type='button'
            onClick={() => cancelEnrollment()}
            className={`rounded-full border border-red-500 text-red-500 px-6 py-2 ` +
            `bg-white font-medium text-base hover:bg-red-500 hover:text-white ` +
            `transition-all ease-in-out duration-150 rounded-full text-center w-60 block`}
          >
            Avbryt anmälan till event
          </button>
        </div>
      ) : (
        <>
          {isLoggedIn() ? (
            <div className="flex w-full">
              <button
                type='button'
                onClick={() => handleSubmit()}
                className={`rounded-full border border-green-primary text-green-primary px-6 py-2 ` +
              `bg-white font-medium text-base hover:bg-green-primary hover:text-white ` +
              `transition-all ease-in-out duration-150 rounded-full text-center w-60 block`}
              >
              Anmäl till event
              </button>
            </div>
          ) : (
            <div className='relative flex items-center manrope'>
              <input
                type="text"
                name='email'
                placeholder='Fyll i din email'
                onChange={onUserInputChange}
                className={`bg-white lg:bg-gray-light border-gray-black/20 lg:border ` +
                `rounded-full w-full eventSignUp py-5 pl-5 md:py-3 lg:py-5 md:pl-4 lg:pl-10 text-base lg:text-xl`}
              />
              <div className={`absolute ${position}`}>
                <UIButtonCommon
                  width='w-fit'
                  buttonTitle={buttonTitle}
                  buttonType='submit'
                  variant={buttonVariant}
                  onClick={handleSubmit}
                  extraStyles={buttonExtraStyles}
                />
              </div>
            </div>
          )}
        </>
      )}
      <UIEnrollmentFormErrorMessage
        messageInfo={messageInfo}
        onClick={() => handleEventData(formValues.event_id as number, formValues.email)}
      />
    </div>
  );
};

export default UIEnrollmentFormComponent;
