import React, {FC} from 'react';

type UIAvatarTypes = {
  image: string;
  size?: string;
}

const UIAvatar: FC<UIAvatarTypes> = ({image, size}) => {
  return (
    <img className={`${size} object-cover rounded-full`} src={image} alt='image' />
  );
};

export default UIAvatar;
