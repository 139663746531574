import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/pages/pages';
import {pagesArray} from '../../state/pages/pages';

const useFetchPages = () => {
  const setPages = useSetRecoilState(pagesArray);

  return useQuery('useFetchPages', async () => {
    const pages = await actions.fetchPages();
    setPages(pages);
    return pages;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchPages;
