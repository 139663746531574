import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/pages/home';
import {home} from '../../state/homePage/home';

const useFetchHomePage = () => {
  const setHomePage = useSetRecoilState(home);

  return useQuery('fetchHomePage', async () => {
    const homePage = await actions.fetchHomePage();
    setHomePage(homePage);
    return homePage;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchHomePage;
