import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'news/';

const fetchNews = async (page: number | string) => {
  try {
    if (page != 1) {
      const response = await axios.get(page as string);
      return response.data;
    } else {
      const response = await axios.get(URL + `?page=${page}`);
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching news:', error);
    throw error;
  }
};

const actions = {
  fetchNews,
};

export default actions;

