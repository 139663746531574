import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/pages/pages';
import {newsletterData} from '../../state/pages/pages';

const useFetchNewsletter = () => {
  const setNewsletter = useSetRecoilState(newsletterData);

  return useQuery('useFetchNewsletter', async () => {
    const data = await actions.fetchNewsletterData();
    setNewsletter(data);
    return data;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchNewsletter;
