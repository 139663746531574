import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/events/event';
import {eventsPaginated} from '../../state/events/eventsPaginated';

const useFetchEvents = () => {
  const setEvents = useSetRecoilState(eventsPaginated);

  return useQuery('useFetchEvents', async () => {
    const events = await actions.fetchEvents();
    setEvents(events);
    return events;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchEvents;
