import axios from 'axios';
import {BehaviorSubject} from 'rxjs';
import {createFormData} from '../utils/createFormData';

const accessToken = new BehaviorSubject('');
const refreshToken = new BehaviorSubject('');
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const REGISTER_URL = process.env.REACT_APP_API_URI + 'register/';
const LOGIN_URL = process.env.REACT_APP_API_URI + 'login/';
const REGISTER_COMPANY_USER_URL = process.env.REACT_APP_API_URI + `register-company-user/`;
const REFRESH_URL = process.env.REACT_APP_API_URI + 'token/refresh/';
const USER_EMAIL = 'userEmail';
const USER_PASS = 'userPassword';

const registerUser = async (data: any) => {
  const res = await axios.post(REGISTER_URL, createFormData(data));
  return res.data;
};

const registerCompanyUser = async (data: any, token: any) => {
  const res = await axios.post(REGISTER_COMPANY_USER_URL + token + '/', createFormData(data));
  return res.data;
};

const login = (email: string, password: string, rememberMe: boolean) => {
  return axios
      .post(LOGIN_URL, {
        'email': email,
        'password': password,
      })
      .then((res) => {
        accessToken.next(res.data.access);
        refreshToken.next(res.data.refresh);
        localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access);
        localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refresh);
        if (rememberMe) {
          localStorage.setItem(USER_EMAIL, email);
          localStorage.setItem(USER_PASS, password);
        }
      });
};

const logout = () => {
  accessToken.next('');
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_PASS);
};

const setRefreshTokenFromLocalStorage = () => {
  const tokenFromLocalStorage = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (tokenFromLocalStorage) {
    setRefreshToken(tokenFromLocalStorage);
  }
};

const setAccessTokenFromLocalStorage = () => {
  const tokenFromLocalStorage = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (tokenFromLocalStorage) {
    setAccessToken(tokenFromLocalStorage);
  }
};

const getUserEmailFromLocalStorage = () => {
  return localStorage.getItem(USER_EMAIL);
};

const getUserPasswordFromLocalStorage = () => {
  return localStorage.getItem(USER_PASS);
};

const getAccessTokenFromLocalStorage = () => {
  const tokenFromLocalStorage = localStorage.getItem(ACCESS_TOKEN_KEY);
  return tokenFromLocalStorage;
};

const setAccessToken = (token: any) => {
  accessToken.next(token);
};

const setRefreshToken = (token: any) => {
  refreshToken.next(token);
};

const refreshAccessToken = () => {
  const data = {refresh: refreshToken.value};
  return axios
      .post(REFRESH_URL, data)
      .then((res) => {
        setAccessToken(res.data.access);
        setRefreshToken(res.data.refresh);
        localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access);
        localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refresh);
      })
      .catch((res) => {
        if (res.response.status === 401) {
          setAccessToken('');
          setRefreshToken('');
        }
      });
};

export const authService = {
  registerUser,
  registerCompanyUser,
  login,
  logout,
  refreshAccessToken,
  setAccessTokenFromLocalStorage,
  setRefreshTokenFromLocalStorage,
  getAccessTokenFromLocalStorage,
  getUserEmailFromLocalStorage,
  getUserPasswordFromLocalStorage,
  currentToken: accessToken.asObservable(),
  get tokenValue() {
    return getAccessTokenFromLocalStorage();
  },
};
