import React, {FC} from 'react';
import {MdArrowBack, MdArrowForward} from 'react-icons/md';

type UIPaginationButtonTypes = {
  value: string;
  onClick: Function;
  variant: 'next' | 'previous'
}

const UIPaginationButton: FC<UIPaginationButtonTypes> = ({value, onClick, variant}) => {
  return (
    <>
      {variant == 'previous' ? (
      <button
        onClick={() => onClick && onClick()}
        className='flex items-center text-sm lg:text-lg w-full lg:w-fit justify-between'>
        <div className='group'>
          <div className={`flex items-center justify-center px-5 py-2 bg-white lg:bg-gray-body rounded-full mr-3
          group-hover:bg-green-primary transition-colors duration-150 ease-in-out`}>
            <MdArrowBack className='text-xl group-hover:text-white'/>
          </div>
        </div>
        <span>
          <strong>Tidigare:</strong>&nbsp;
          {value}
        </span>
      </button>
    ) : (
      <button
        onClick={() => onClick && onClick()}
        className='flex items-center text-sm lg:text-lg w-full lg:w-fit justify-between'>
        <span>
          <strong>Nästa:</strong>&nbsp;
          {value}
        </span>
        <div className='group'>
          <div className={`flex items-center justify-center px-5 py-2 bg-white lg:bg-gray-body rounded-full ml-3
          group-hover:bg-green-primary transition-colors duration-150 ease-in-out`}>
            <MdArrowForward className='text-xl group-hover:text-white'/>
          </div>
        </div>
      </button>
    )}
    </>
  );
};

export default UIPaginationButton;
