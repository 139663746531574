import React, {FC} from 'react';
import UITestimonialComponent from '../UICommon/UITestimonialComponent';
import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';

type CtaSectionComponentProps = {
  buttonCompanyTitle: string;
  buttonNameTitle: string;
  description: string;
  ctaTitle: string;
  ctaDescription: string;
  ctaButtonTitle: string;
  ctaButtonUrl: string;
}

const CtaSectionComponent: FC<CtaSectionComponentProps> = ({
  buttonCompanyTitle,
  buttonNameTitle,
  description,
  ctaTitle,
  ctaDescription,
  ctaButtonTitle,
  ctaButtonUrl,
}) => {
  return (
    <div className='flex flex-col-reverse lg:flex-row w-full lg:space-x-8 py-16'>
      {description && (
        <div className='w-full lg:w-2/3'>
          <UITestimonialComponent
            companyTitle={buttonCompanyTitle}
            nameTitle={buttonNameTitle}
            description={description}
          />
        </div>
      )}
      <div className='w-full lg:w-1/3 mb-6 lg:mb-0'>
        <UICardComponent
          width='w-full'
          padding='px-8 py-10 lg:px-10 lg:pt-12 lg:pb-8 xl:px-16 xl:pt-20 xl:pb-12'
          backgroundColor='bg-green-primary'>
          <div className='flex flex-col text-white text-center items-center justify-between h-full'>
            <div className='mb-6 lg:mb-0'>
              <h2 className='text-33px font-bold mb-6'>
                {ctaTitle}
              </h2>
              <div className='text-lg' dangerouslySetInnerHTML={{__html: ctaDescription}}>
              </div>
            </div>
            <UIButtonCommon
              width='w-fit lg:w-full'
              buttonTitle={ctaButtonTitle}
              buttonUrl={ctaButtonUrl}
              variant='whiteOutlined'
              extraStyles='mt-4'
            />
          </div>
        </UICardComponent>
      </div>
    </div>
  );
};

export default CtaSectionComponent;
