import React, {FC} from 'react';
import {UITextContentWrapperVariants} from '../../variants/UITextContentWrapperVariants';

type UITextContentWrapperTypes = {
  title: string;
  variant: keyof typeof UITextContentWrapperVariants;
  isDescription?: boolean
  children?: JSX.Element|JSX.Element[];
  heading?: string;
  description?: string;
  containerOneWidth?: string;
  containerTwoWidth?: string;
  isBorder?: boolean;
  isButton?: boolean;
  buttonTitle?: string;
}

const UITextContentWrapper: FC<UITextContentWrapperTypes> = ({
  title,
  variant,
  isDescription,
  children,
  heading,
  description,
  containerOneWidth='w-full lg:w-1/3',
  containerTwoWidth='w-full lg:w-2/3',
  isBorder = false,
  isButton = false,
  buttonTitle = '',
}) => {
  const wrapperClasses = {
    [UITextContentWrapperVariants.big]: `flex-col lg:flex-row`,
    [UITextContentWrapperVariants.small]: `flex-row`,
    [UITextContentWrapperVariants.event]: `flex-col gap-y-3 lg:gap-y-0` +
    ` text-left lg:flex-row justify-between items-start lg:items-center`,
    [UITextContentWrapperVariants.allInclusive]: `flex flex-col lg:flex-row`,
  };
  const headingClasses = {
    [UITextContentWrapperVariants.big]: `text-27px lg:text-43px font-semibold text-black-normal mb-8`,
    [UITextContentWrapperVariants.small]: `text-22px lg:text-25px font-medium text-black-deep mb-2`,
    [UITextContentWrapperVariants.event]: ``,
    [UITextContentWrapperVariants.allInclusive]: `text-27px pt-4 lg:text-32px font-semibold`,
  };
  const titleClasses = {
    [UITextContentWrapperVariants.big]: `text-black-deep font-semibold uppercase xl:text-lg mb-8 lg:mb-0`,
    [UITextContentWrapperVariants.small]: `text-black-deep font-semibold uppercase xl:text-lg mb-8 lg:mb-0`,
    [UITextContentWrapperVariants.event]: `text-xl lg:text-5xl xl:text-75px font-semibold xl:leading-[5rem]`,
    [UITextContentWrapperVariants.allInclusive]: `text-sm uppercase sm:normal-case sm:text-42px sm:pb-4 font-semibold` +
    ` tracking-wider sm:tracking-normal lg:pt-16`,
  };
  const descriptionClasses = {
    [UITextContentWrapperVariants.big]: `text-base lg:text-25px text-black-deep`,
    [UITextContentWrapperVariants.small]: `text-base lg:text-xl text-black-normal/50`,
    [UITextContentWrapperVariants.event]: `text-left lg:text-right text-black-deep text-lg`,
    [UITextContentWrapperVariants.allInclusive]: `lg:text-22px pt-4 lg:pt-10`,
  };
  const secondContainerStyle = variant === UITextContentWrapperVariants.allInclusive ?
    'sm:bg-white sm:rounded-[15px] sm:p-10 lg:pl-24 lg:pr-22 lg:py-20 lg:pr-20' : '';

  return (
    <div className={`w-full flex ${wrapperClasses[variant]} py-6 lg:py-16 ${isBorder && 'border-b border-black/30'}`}>
      <div className={`h-full ${containerOneWidth}`}>
        <h2 className={`${titleClasses[variant]}`}>
          {title}
        </h2>
      </div>
      <div className={`h-full ${containerTwoWidth} ${secondContainerStyle}`}>
        {isDescription && (
          <div>
            {heading && (
              <h2 className={`${headingClasses[variant]}`}>
                {heading}
              </h2>
            )}
            {description && (
              <div className={`${descriptionClasses[variant]} leading-snug content`}
                dangerouslySetInnerHTML={{__html: description}}
              >
              </div>
            )}
          </div>
        )}
        {children && (
          <div className='mt-6'>
            {children}
          </div>
        )
        }
        {isButton && buttonTitle && (
          <a href={`mailto:${buttonTitle}`}
            className={`bg-pink-primary inline-block rounded-full px-16 lg:px-5 py-3 cursor-pointer ` +
            `text-center text-pink-dark mt-4`}>
            {buttonTitle}
          </a>
        )}
      </div>
    </div>
  );
};

export default UITextContentWrapper;
