import React, {FC} from 'react';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import RegisterInvitedUserForm from '../components/RegisterInvitedPage/RegisterInvitedUserForm';

const RegisterInvitedPage: FC = () => {
  return (
    <main className='bg-white lg:bg-gray-body'>
      <div className='absolute top-0 left-0 z-0 block h-[41vh] md:h-[45vh] lg:h-[80vh] bg-blue-dark w-full'/>
      <UIPageContainer>
        <UITextWrapper
          heading='MEDLEMSKAP'
          title='medlemskap som passar just er'
          description='Snart klart! Nu behöver vi bara ett par uppgifter av dig för att färdigställa ditt konto.'
          headingStyles='text-white mt-2 lg:mt-8'
          titleStyles='text-white'
          descriptionStyles='text-white font-normal'
        />
        <RegisterInvitedUserForm/>
      </UIPageContainer>
    </main>
  );
};

export default RegisterInvitedPage;

