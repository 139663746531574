import React, {FC} from 'react';

type ProfileFormInputComponentTypes = {
  id: string;
  name: string;
  type: string;
  onChange: Function;
  value?: string;
  errorMessages?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

const ProfileFormInputComponent: FC<ProfileFormInputComponentTypes> = ({
  id,
  name,
  type,
  onChange,
  value,
  errorMessages,
  placeholder,
  label,
  disabled,
}) => {
  return (
    <div className='flex flex-col pr-4'>
      <div className='flex items-center space-x-2 mb-2'>
        <label
          htmlFor={name}
          className='text-gray-common text-base'
        >
          {label}
        </label>
      </div>
      <input
        id={id}
        onChange={(event) => onChange(event)}
        autoComplete='off'
        className={`font-bold profileInputForm text-22px`}
        value={value}
        placeholder={placeholder && placeholder} name={name} type={type}
        disabled={disabled}
      />
      {errorMessages && (
        <small className="text-red-600 font-semibold text-xs mt-1">
          {errorMessages}
        </small>
      )}
    </div>
  );
};

export default ProfileFormInputComponent;
