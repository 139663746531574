import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {home} from '../state/homePage/home';
import {testimonials} from '../state/testimonials/testimonials';
import {carouselEvents, pastEvents} from '../state/events/eventsPaginated';

import homeTypes from '../types/home';
import {testimonialsResponseType} from '../types/testimonials';
import {eventType} from '../types/event';

import useFetchEvents from '../hooks/events/useFetchEvents';
import useFetchHomePage from '../hooks/pages/useFetchHomePage';
import useFetchTestimonials from '../hooks/testimonials/useFetchTestimonials';
import useFetchCarouselEvents from '../hooks/events/useFetchCarouselEvents';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UIHeroComponent from '../components/UICommon/UIHeroComponent';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import EventsCarouselComponent from '../components/HomePage/EventsCarouselSection/EventsCarouselComponent';
import ArchiveEventsSection from '../components/HomePage/ArchiveEventsSection';
import UICtaComponent from '../components/UICommon/UICtaComponent';
import TestimonialsCarouselSection from '../components/HomePage/TestimonialsCarouselSection';
import UINewsletterModal from '../components/UIModals/UINewsletterModal';


const LandingPage: FC = () => {
  const {isLoading: isLoadingHomePage, isError: isErrorHomePage} = useFetchHomePage();
  const {isLoading: isLoadingEvents, isError: isErrorEvents} = useFetchEvents();
  const {isLoading: isLoadingCarouselEvents, isError: isErrorCarouselEvents} = useFetchCarouselEvents();
  const {isLoading: isLoadingTestimonials, isError: isErrorTestimonials} = useFetchTestimonials();
  const homePageData : homeTypes = useRecoilValue(home);
  const pastEventsData: eventType[] = useRecoilValue(pastEvents);
  const carouselEventsData: eventType[] = useRecoilValue(carouselEvents);
  const testimonialsData: testimonialsResponseType = useRecoilValue(testimonials);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const lastClosed: any = localStorage.getItem('lastClosed');
    const currentDate = new Date().getTime();
    if (!lastClosed || currentDate - lastClosed > 3 * 24 * 60 * 60 * 1000) {
      setTimeout(() => {
        setShowModal(true);
      }, 3000);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('lastClosed', new Date().getTime() as any);
  };

  if (isErrorHomePage || isErrorEvents || isErrorTestimonials || isErrorCarouselEvents) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };

  if (isLoadingHomePage || isLoadingEvents || isLoadingTestimonials || isLoadingCarouselEvents||
    pastEventsData == null || homePageData == null) {
    return (
      <UILoadingIndicator/>
    );
  };

  return (
    <>
      <UIPageContainer>
        <UIHeroComponent
          image={homePageData.h_image}
          mobileImage={homePageData.h_image}
          hBackgroundColor={homePageData.h_background_color}
          video={homePageData.h_video}
          text={homePageData.h_title}
          buttonTitle='BLI MEDLEM'
          buttonUrl='/become-member/'
          extraButtonUrl={homePageData.h_cta_url}
          extraButtonTitle={homePageData.h_cta_button}
          height='h-[85vh] lg:h-738px'
          isExtraImage = {true}
          extraImage={homePageData.h_logo_image}
        />
        <EventsCarouselComponent
          events={carouselEventsData}
        />
        <TestimonialsCarouselSection
          data={testimonialsData}
        />
        <ArchiveEventsSection
          filter='past'
          title='Tidigare events'
          isImage={false}
          events={pastEventsData}
          padding='pt-16'
        />
        <UICtaComponent
          title={homePageData.cta_title}
          description={homePageData.cta_description}
          buttonTitle={homePageData.cta_button}
          buttonUrl={homePageData.cta_url}
        />
        {showModal ? <UINewsletterModal closeModal={closeModal} /> : <></>}
      </UIPageContainer>
    </>
  );
};

export default LandingPage;
