/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React, {FC, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from 'swiper';
import {BiArrowBack} from 'react-icons/bi';
import {eventType} from '../../../types/event';

import EventsCarouselSlide from './EventsCarouselSlide';

import 'swiper/css';
import 'swiper/css/pagination';

type eventsCarouselComponentTypes = {
	events: eventType[]
}

const EventsCarouselComponent: FC<eventsCarouselComponentTypes> = ({events}) => {
  const dates = events.map((el) => {
    if ('start_time' in el && el.start_time) {
      const date = new Date(el.start_time);
      const day = date.getDate();
      const month = date.toLocaleDateString('sv-SV', {month: 'long'}).substring(0, 3);
      return `${day} ${month}`;
    }
  });

  function formatTime(dateString: string) {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toLocaleString('sv-SV').padStart(2, '0');
    const minutes = date.getUTCMinutes().toLocaleString('sv-SV').padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const pagination = {
    clickable: true,
    renderBullet: function(index: number, className: string) {
      return `<span class="paginationItem capitalize ${className}">${dates[index]}</span>`;
    },
  };

  const [init, setInit] = useState(true);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Swiper
      pagination={pagination}
      modules={[Pagination, Navigation]}
      className="mySwiper"
      spaceBetween={40}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      onInit={() => setInit(!init)}>
      <button ref={prevRef} className="customPrev left-[7%] bg-white px-5 py-2 rounded-full z-20">
        <BiArrowBack className="text-xl" />
      </button>
      <button ref={nextRef} className="customPrev right-[7%] rotate-180 bg-white px-5 py-2 rounded-full z-20">
        <BiArrowBack className="text-xl" />
      </button>
      {events &&
				events.map((el: eventType) => {
				  const startTime = el.start_time ? formatTime(el.start_time) : '';
				  const stopTime = el.stop_time ? formatTime(el.stop_time) : '';
				  return (
				    <SwiperSlide key={`event-slide-${el.id}`}>
				      <EventsCarouselSlide
				        title={el.title}
				        description={el.description}
				        speakers={el.enrolled_list}
				        eventId={el.id}
				        enrollmentType={el.enrollment_type}
				        mapLink={el.map_link}
				        enrollmentDate={el.enrollment_date}
				        enrollmentUrl={el.external_url}
				        thumbnailImageUrl={el.image}
				        localization={el.address}
				        timeStart={startTime}
				        timeEnd={stopTime}
				        dateStart={el.start_time}
				        dateEnd={el.start_time}
				      />
				    </SwiperSlide>
				  );
				})}
    </Swiper>
  );
};

export default EventsCarouselComponent;
