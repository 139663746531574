import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'events/';
const URL_CAROUSEL = process.env.REACT_APP_API_URI + 'events/carousel/';

const fetchEvents = async () => {
  const response = await axios.get(URL);
  return response.data;
};

const fetchCarouselEvents = async () => {
  const response = await axios.get(URL_CAROUSEL);
  return response.data;
};

const fetchSingleEvent = async (id:number) => {
  const response = await axios.get(URL + id + '/');
  return response.data;
};

const actions = {
  fetchEvents,
  fetchCarouselEvents,
  fetchSingleEvent,
};

export default actions;
