import React, {FC, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';

import {selectedPlan} from '../../state/subscriptions/subscriptions';

import RegisterCompanyForm from './RegisterCompanyForm';
import RegisterUserForm from './RegisterUserForm';
import RegisterPublicForm from './RegisterPublicForm';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';

const RegisterSection: FC = () => {
  const navigate = useNavigate();
  const selectedUserPlan = useRecoilValue(selectedPlan);

  useEffect(() => {
    if (selectedUserPlan === undefined) {
      navigate('/become-member/');
    }
  }, [selectedUserPlan]);

  if (selectedUserPlan === undefined) {
    return <UILoadingIndicator/>;
  }

  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      {selectedUserPlan.type === 'company' ? (
        <RegisterCompanyForm/>
      ) : selectedUserPlan.type === 'personal' ? (
        <RegisterUserForm/>
      ) : (
        <RegisterPublicForm/>
      )}
    </div>
  );
};
export default RegisterSection;
