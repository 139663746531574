import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {MdClose} from 'react-icons/md';

import UIInput from '../UICommon/UIInput';
import UICheckbox from '../UICommon/UICheckbox';
import UISubmit from '../UIButtons/UISubmit';
import UIPasswordInput from '../UICommon/UIPasswordInput';

import logoImg from '../../assets/mih-logo-big.png';

type LoginBoxComponentTypes = {
  onChange: Function;
  onKeyDown: Function;
  formErrorMessages: {
    email: string;
    password: string;
  };
  submit: Function;
}

const LoginBoxComponent: FC<LoginBoxComponentTypes> = ({
  onChange,
  onKeyDown,
  formErrorMessages,
  submit,
}) => {
  return (
    <div className='flex flex-col items-center pt-12 lg:pt-0'>
      <div className='flex justify-between lg:justify-center w-full items-center mb-16 lg:mb-0 px-6 lg:px-0'>
        <Link to="/">
          <img className='lg:mb-6 h-10 lg:h-[72px]' src={logoImg} alt="" />
        </Link>
        <Link to="/" className='lg:hidden'>
          <MdClose className='text-2xl'/>
        </Link>
      </div>
      <form
        onKeyDown={(e) => onKeyDown(e)}
        className='lg:bg-white lg:shadow-sm flex flex-col px-6 py-9 space-y-4 rounded-[5px]'>
        <div className='flex flex-col lg:hidden w-full'>
          <h2 className='text-23px text-black-body'>
            Välkommen till Marknadsföreningen i Halland
          </h2>
          <div className='flex items-baseline'>
            <span className='text-black-body/70'>
            Har du inget konto?
            </span>
            <Link className='text-green-primary font-medium ml-1' to="/become-member/">
              Bli medlem här
            </Link>
          </div>
        </div>
        <UIInput
          id='email_address'
          type='text'
          label='E-postadress'
          name='email'
          onChange={onChange}
          formErrorMessage={formErrorMessages.email}
        />
        <UIPasswordInput
          id='password'
          label='Lösenord'
          name='password'
          onChange={onChange}
          formErrorMessage={formErrorMessages.password}
        />
        <div className='w-full flex justify-between'>
          <UICheckbox
            label='Kom ihåg mig'
            name='rememberMe'
            onChange={() => onChange}
          />
          <a className='text-green-primary font-semibold text-sm hover:underline' href="/forgot-password/">
              Glömt lösenord
          </a>
        </div>
        <div className='w-full !mt-7'>
          <UISubmit
            label='Logga in'
            onClick={submit}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginBoxComponent;
