import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {MdClose} from 'react-icons/md';

import {isValidEmail} from '../utils/isValid';
import actions from '../api/resetPassword';

import UIInput from '../components/UICommon/UIInput';
import UISubmit from '../components/UIButtons/UISubmit';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';

import loginImage from '../assets/thumbnail.jpg';
import logoImg from '../assets/mih-logo.png';


const ForgotPasswordPage: FC = () => {
  const initialValues = {success: false, error: false, errorMessage: ''};
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(initialValues);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setEmail(value);
  };

  const submit = () => {
    const state = {...initialValues};
    if (!email) {
      state.errorMessage = 'Ange din e-postadress';
    } else if (!isValidEmail(email)) {
      state.errorMessage = 'E-postadressen är ogiltig';
    }
    if (!state.errorMessage) {
      setLoading(true);
      actions
          .requestPasswordReset(email)
          .then(() => {
            state.success = true;
          })
          .catch(() => {
            state.error = true;
          })
          .finally(() => {
            setLoading(false);
            setStatus(state);
          });
    } else {
      setStatus(state);
    }
  };

  return (
    <div className='h-screen w-full flex'>
      <div className='hidden lg:block w-1/2 relative'>
        <Link to='/' className={`bg-white/70 text-black-deep uppercase inter text-xs
         font-medium rounded-full px-4 py-3 sticky z-10 w-fit top-12 left-12`}>
          TILLBAKA TILL MIH.NU
        </Link>
        <img className='w-full h-full absolute top-0 left-0 object-cover' src={loginImage} alt="iamge" />
      </div>
      <div className='w-full lg:w-1/2 h-full flex flex-col lg:justify-center lg:items-center relative'>
        {loading &&
          <div className='absolute h-full w-full z-10 bg-gray-body'>
            <UILoadingIndicator/>
          </div>
        }
        {status.error &&
          <div className='absolute h-full w-full z-10 bg-gray-body'>
            <UIErrorIndicator message='Användare med den e-postadressen finns inte.'/>
          </div>
        }
        {status.success ? (
          <div className='text-green-primary text-center font-semibold flex h-full items-center justify-center'>
            <span>
             Kolla din e-post och klicka på aktiveringslänken för att ändra ditt lösenord.
            </span>
          </div>
        ) : (
          <div className='flex flex-col items-center pt-12 lg:pt-0'>
            <div className={`flex lg:hidden justify-between lg:justify-center w-full items-center
             mb-16 lg:mb-0 px-6 lg:px-0`}>
              <img className='lg:mb-6 h-10 lg:h-[72px]' src={logoImg} alt="" />
              <Link to="/" className='lg:hidden'>
                <MdClose className='text-2xl'/>
              </Link>
            </div>
            <form
              onSubmit={() => submit()}
              className={`lg:bg-white lg:shadow-sm flex flex-col px-6 py-9 space-y-4 rounded-[5px]
               w-full md:w-80 lg:w-fit`}>
              <h2 className='text-black-dark font-semibold text-base'>
                Återställ ditt lösenord.
              </h2>
              <UIInput
                id='email_address'
                type='text'
                label='E-postadress'
                name='email'
                onChange={onChange}
                formErrorMessage={status.errorMessage}
              />
              <UISubmit
                label="Skicka länk"
                onClick={submit}
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
