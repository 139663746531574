import React, {FC, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {MdClose} from 'react-icons/md';

import actions from '../api/resetPassword';
import {isValidPassword} from '../utils/isValid';

import UISubmit from '../components/UIButtons/UISubmit';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UIPasswordInput from '../components/UICommon/UIPasswordInput';

import loginImage from '../assets/thumbnail.jpg';
import logoImg from '../assets/mih-logo.png';

const SetNewPasswordPage: FC = () => {
  const initialValues = {success: false, error: false, errorMessage: '', confirmed: false};
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({password: '', confirmPassword: ''});
  const [status, setStatus] = useState(initialValues);

  const {token} = useParams<string>();

  useEffect(() => {
    if (token != null) {
      const state = {...status};
      actions.confirmPasswordReset(token).then((res: any) => {
        state.confirmed = true;
        setStatus(state);
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }, [token]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const state = {...status};
    state.errorMessage = ('');
    setStatus(state);
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: value});
  };

  const submit = () => {
    const state = {...status};

    if (!formValues.password) {
      state.errorMessage = ('Ange ditt lösenord');
    } else if (!formValues.confirmPassword) {
      state.errorMessage = ('Bekräfta ditt lösenord');
    } else if (formValues.password !== formValues.confirmPassword) {
      state.errorMessage = ('Lösenord matchar inte');
    } else if (!isValidPassword(formValues.password)) {
      state.errorMessage = ('Lösenordet ska innehålla minst 6 tecken, 1 siffra och 1 specialtecken');
    }

    if (!state.errorMessage && token != null) {
      setLoading(true);
      actions.setNewPassword(token, formValues.password!, formValues.confirmPassword!).then(() => {
        state.success = true;
      }).catch((error) => {
        state.error = true;
      }).finally(() => {
        setLoading(false);
        setStatus(state);
      });
    } else {
      setStatus(state);
    }
  };

  return (
    <div className='h-screen w-full flex'>
      <div className='hidden lg:block w-1/2 relative'>
        <Link to='/' className={`bg-white/70 text-black-deep uppercase inter text-xs
         font-medium rounded-full px-4 py-3 sticky z-10 w-fit top-12 left-12`}>
          TILLBAKA TILL MIH.NU
        </Link>
        <img className='w-full h-full absolute top-0 left-0 object-cover' src={loginImage} alt="iamge" />
      </div>
      <div className='w-full lg:w-1/2 h-full flex flex-col justify-start lg:justify-center lg:items-center relative'>
        {loading &&
          <div className='absolute h-full w-full z-10 bg-gray-body'>
            <UILoadingIndicator/>
          </div>
        }
        {status.error &&
          <div className='absolute h-full w-full z-10 bg-gray-body'>
            <UIErrorIndicator
              message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'
            />
          </div>
        }
        {status.confirmed && !status.success ? (
          <div className='flex flex-col items-center pt-12 lg:pt-0'>
            <div className={`flex lg:hidden justify-between lg:justify-center w-full items-center
             mb-16 lg:mb-0 px-6 lg:px-0`}>
              <img className='lg:mb-6 h-10 lg:h-[72px]' src={logoImg} alt="" />
              <Link to='/' className='lg:hidden'>
                <MdClose className='text-2xl'/>
              </Link>
            </div>
            <form
              onSubmit={() => submit()}
              className={`lg:bg-white lg:shadow-sm flex flex-col px-6 py-9 space-y-4
              rounded-[5px] w-full md:w-80 lg:w-fit`} >
              <UIPasswordInput
                id='password'
                label='Lösenord'
                name='password'
                onChange={onChange}
                placeholder='Lösenord'
                formErrorMessage={status.errorMessage}/>
              <UIPasswordInput
                id='confirm_password'
                label="Bekräfta lösenord"
                name='confirmPassword'
                onChange={onChange}
                placeholder='Bekräfta lösenord'
                formErrorMessage={status.errorMessage}
              />
              <UISubmit
                label="Spara nytt lösenord"
                onClick={submit}
              />
            </form>
          </div>
        ) : status.success ? (
          <>
            <div className='text-green-primary text-center font-semibold mb-4'>
              Tack! Vi har nu återställt ditt lösenord.</div>
            <Link
              to="/login/"
              className={`rounded-full border border-green-primary text-green-primary px-6 py-2 ` +
                  `bg-white font-medium text-base hover:bg-green-primary hover:text-white ` +
                  `transition-all ease-in-out duration-150 rounded-full text-center w-auto block`}>
                Logga in
            </Link>
          </>
        ) : (
          <div className='text-red-700 text-center font-semibold'>
              Länken har löpt ut eller så är den fel.</div>
        )}
      </div>
    </div>
  );
};

export default SetNewPasswordPage;

