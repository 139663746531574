import React, {FC, useEffect, useState} from 'react';
import {FiMapPin, FiClock} from 'react-icons/fi';

import {enrolledListType} from '../../../types/event';
import {isLoggedIn} from '../../../utils/isLoggedIn';
import {parseHtml} from '../../../utils/parseHtml';
import {useRecoilValue} from 'recoil';
import {currentUser} from '../../../state/user/currentUser';
// eslint-disable-next-line camelcase
import {atcb_action} from 'add-to-calendar-button';
import useRefetchCurrentUser from '../../../hooks/auth/useRefetchCurrentUser';

import UIEnrollmentFormMobileComponent from '../../UIForms/UIEnrollmentFormMobileComponent';
import UICardComponent from '../../UIWrappers/UICardComponent';
import UIButtonCommon from '../../UIButtons/UIButtonCommon';
import EventsAddToCalendarBtn from './EventsAddToCalendarBtn';
import UIEnrollmentFormComponent from '../../UIForms/UIEnrollmentFormComponent';
import UIUserAvatar from '../../UICommon/UIUserAvatar';
import UIUserAvatarExtra from '../../UICommon/UIUserAvatarExtra';


type EventsCarouselSlideMobile = {
  title: string;
  description: string;
  eventId: number;
  enrollmentType: string;
  speakers: enrolledListType[];
  enrollmentDate: string;
  enrollmentUrl?: string;
  thumbnailImageUrl?: string;
  localization?: string;
  timeStart?: string;
  timeEnd?: string;
  dateStart?: string;
  dateEnd?: string;
}

const EventsCarouselSlideMobile: FC<EventsCarouselSlideMobile> = ({
  title,
  description,
  eventId,
  enrollmentType,
  speakers,
  enrollmentDate,
  enrollmentUrl,
  localization,
  timeStart,
  timeEnd,
  dateStart,
  dateEnd,
}) => {
  const [isEnrolled, setIsEnrolled] = useState(false);
  const userData = useRecoilValue(currentUser);
  const {refetch} = useRefetchCurrentUser();

  const enrolledUsersAmount = speakers?.length;
  const availableSpaces = 16;
  const leftOverUsers = Math.max(enrolledUsersAmount - availableSpaces + 1, 0);
  const displayAvatars = leftOverUsers > 0 ? availableSpaces - 1 : enrolledUsersAmount;

  useEffect(() => {
    if (userData != null && speakers?.filter((el: any) => el.id === userData.id).length > 0) {
      setIsEnrolled(true);
    }
  }, [userData, speakers]);

  const config: any = {
    name: title,
    hideIconButton: true,
    hideCheckmark: true,
    language: 'sv',
    size: '2',
    label: 'LÄGG TILL I KALENDER',
    location: localization,
    startDate: dateStart,
    endDate: dateEnd,
    options: ['Apple', 'Google', 'Yahoo', 'iCal'],
  };

  useEffect(() => {
    if (isLoggedIn()) {
      refetch();
    }
  }, []);

  const handleAddToCalendar = () => {
    atcb_action(config);
  };

  const txt = parseHtml(description);
  const enrollDate = new Date(enrollmentDate);

  return (
    <div className='flex flex-col w-full h-full mb-8'>
      <div className='relative w-full mt-4 h-[100px] flex items-center justify-center'>
        {displayAvatars == 0 && enrollmentType === 'Anmälan på webbplats' ?
        <div
          className='flex justify-center items-center'
        >
          <div
            className='bg-green-primary rounded-full text-2xl text-white py-2 px-6'
          >
          Inga anmälda.
          </div>
        </div> :
        <div className='grid grid-cols-8 gap-2 pb-4 w-full'>
          {speakers?.slice(0, displayAvatars).map((el: enrolledListType, idx: number) => (
            <UIUserAvatar
              key={idx}
              image={el.avatar}
            />
          ))}
          {leftOverUsers > 0 &&
            <UIUserAvatarExtra
              amount={leftOverUsers}
            />
          }
        </div>
        }
      </div>
      <div>
        {enrollmentType === 'Anmälan på webbplats' ? (
          <>
            {!isLoggedIn() && enrollDate > new Date() ? (
              <UIEnrollmentFormMobileComponent
                eventId={eventId}
                buttonVariant='greenEvent'
                formExtraStyles='mb-3'
                buttonTitle='ANMÄL'
              />
            ) : !isLoggedIn() ? (
              <div className='text-sm text-red-500 w-full h-[60px] text-center'>
                DET GÅR INTE LÄNGRE ATT ANMÄLA SIG TILL DETTA EVENEMANG
              </div>
            ) : (<></>)}
          </>
        ) : (
          <UIButtonCommon
            buttonUrl={enrollmentUrl}
            variant='greenEvent'
            buttonTitle='ANMÄL'
            extraStyles='mb-3 !w-full'
            width='w-full'
          />
        )}
      </div>
      <UICardComponent
        width='w-full'
        padding='px-5 py-4'
      >
        <div className='flex flex-col manrope relative h-[480px]'>
          {isLoggedIn() && enrollDate < new Date() && (
            <div className='text-sm text-red-500 w-full h-[60px] text-center'>
              DET GÅR INTE LÄNGRE ATT ANMÄLA SIG TILL DETTA EVENEMANG
            </div>
          )}
          <div className="flex flex-col-reverse items-start justify-between">
            <h2 className='text-black-body text-2xl mt-3'>
              {title}
            </h2>
            {isLoggedIn() && enrollmentType === 'Anmälan på webbplats' && enrollDate > new Date() && (
              <UIEnrollmentFormComponent
                eventId={eventId}
                buttonVariant='greenEvent'
                buttonTitle='ANMÄL'
                buttonExtraStyles='!w-full'
                position='top-1 xl:top-2 right-2'
                isEnrolled={isEnrolled}
                setIsEnrolled={setIsEnrolled}
                userEmail={userData ? userData.email : ''}
              />
            )}
          </div>
          <h3 className='text-black/40 text-xl mt-6 opacity-40'>
            Om eventet
          </h3>
          <div
            className='mt-3 text-base text-black-normal/70 h-[150px] carousel-content-wrapper'
          >
            <p>
              {txt}
            </p>
          </div>
          <div className='mt-6 flex flex-col space-y-4'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <FiMapPin
                  className='w-4 h-4 md:w-6 md:h-6 text-black-normal/60 mr-2'
                />
                <span className='text-gray-black/60 text-base'>
                  {localization}
                </span>
              </div>
              <UIButtonCommon
                buttonUrl=''
                buttonTitle='HITTA HIT'
                variant='blackOutlinedTransparent'
                extraStyles='whitespace-nowrap'
              />
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <FiClock
                  className='w-4 h-4 md:w-6 md:h-6 text-black-normal/60 mr-2'
                />
                <span className='text-gray-black/60 text-base'>
                  {timeStart} - {timeEnd}
                </span>
              </div>
              <EventsAddToCalendarBtn
                buttonTitle='LÄGG TILL I KALENDER'
                onClick={handleAddToCalendar}
              />
            </div>
          </div>
          <UIButtonCommon
            margin='mt-8'
            buttonUrl={`events/${eventId}`}
            buttonTitle='LÄS MER'
            variant='greenFilled'
            width='w-full'
          />
        </div>
      </UICardComponent>
    </div>
  );
};

export default EventsCarouselSlideMobile;
