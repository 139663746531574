import React, {FC} from 'react';

import {enrolledListType} from '../../types/event';
import UICardComponent from '../UIWrappers/UICardComponent';
import imgPlacholder from '../../assets/user_icon.svg';

type SpeakersListComponentTypes = {
  speakers: enrolledListType[],
  isDisabled: boolean;
}

const SpeakersListComponent: FC<SpeakersListComponentTypes> = ({speakers, isDisabled}) => {
  return (
    <UICardComponent width='w-full' padding='pt-5 pl-7 pr-3 pt-6' extraStyles='max-lg:!h-auto relative'>
      <div className='flex flex-col h-full'>
        <h3 className='text-black-dark/40 text-22px pb-6'>
          Anmälda
        </h3>
        <ul className='w-full overflow-y-scroll max-h-96 lg:max-h-[65%] lg:h-auto speakerListWrapper mb-6 space-y-3'>
          {speakers?.map((el: enrolledListType, index: number)=> (
            <li key={index} className='flex'>
              {el.avatar ? (
                <img
                  src={el.avatar}
                  className='h-14 w-14 object-cover rounded-full'
                  alt="photo"
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = imgPlacholder;
                  }}
                />
              ) : (
                <img src={imgPlacholder} className='h-14 w-14 object-cover' alt="photo" />
              )}
              <div className='flex flex-col text-black-dark/70 ml-4'>
                <div className="flex items-center">
                  <h2 className='font-semibold text-xl'>
                    {el.full_name}
                  </h2>
                  {el.is_guest && (
                    <h2 className='ml-2'>(Gäst)</h2>
                  )}
                </div>
                <span className='text-lg'>
                  {el.work_place}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </UICardComponent>
  );
};

export default SpeakersListComponent;
