import {useMutation} from 'react-query';
import actions from '../../api/user/organisation';

const useMutateOrganisationAdmin = () => {
  return useMutation(async (user: number) => {
    await actions.passOrganisationAdmin(user);
  }, {retry: false});
};

export default useMutateOrganisationAdmin;
