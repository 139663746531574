import {FC} from 'react';
import {FaBars} from 'react-icons/fa';

type UINavTogglerProps = {
  onClick: Function;
  location: boolean;
};

const UINavToggler: FC<UINavTogglerProps> = ({
  onClick,
  location,
}) => {
  return (
    <button
      className={`cursor-pointer lg:hidden px-3 py-1 text-xl leading-none` +
      ` ${location == true ? 'text-white opacity-100' : 'text-black-normal opacity-50'}`}
      type="button"
      onClick={() => onClick('bg-white m-2 py-3 px-6')}
    >
      <FaBars className="text-xl" />
    </button>
  );
};

export default UINavToggler;
