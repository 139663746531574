import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router';
import {useRecoilValue} from 'recoil';

import actions from '../../api/events/eventsEnrollment';
import {enrollEvent} from '../../state/events/enrollEvent';
import {guestFormFields} from '../../utils/registerPageForms';
import {EventTargetType} from '../../types/eventTarget';

import RegisterPageFormComponent from '../RegisterPage/RegisterPageFormComponent';
import {isValidEmail, isValidFullName, isValidPassword} from '../../utils/isValid';


const RegisterGuestForm: FC = () => {
  const event = useRecoilValue(enrollEvent);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    full_name: '',
    work_place: '',
    event_id: event.event_id,
  });
  const [formErrorMessages, setFormErrorMessages] = useState({
    email: '',
    password: '',
    full_name: '',
    work_place: '',
  });

  const onInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let hasErrors = false;

    for (const field in formValues) {
      if (!formValues[field as keyof typeof formValues]) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Fältet är obligatoriskt.',
        }));
        hasErrors = true;
      } else if (field === 'email' && !isValidEmail(formValues[field])) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Ogiltig e-postadress.',
        }));
        hasErrors = true;
      } else if (field === 'password' && !isValidPassword(formValues[field])) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Lösenordet ska innehålla minst 6 tecken, 1 siffra och 1 specialtecken',
        }));
        hasErrors = true;
      } else if (field === 'full_name' && !isValidFullName(formValues[field])) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Fältet ska innehålla namn och efternamn',
        }));
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }

    actions
        .enrollGuest(formValues)
        .then(() => {
          navigate('/login/');
        })
        .catch((error) => {
          console.log(error);
        });
  };
  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      <RegisterPageFormComponent
        stepName='Företagsuppgifter'
        stepOrder={1}
        totalSteps={1}
        onChange={onInputChange}
        onClick={handleSubmit}
        formErrorMessages={formErrorMessages}
        formInputsArray={guestFormFields}
        keyPrefix='guest-form-'
        isGuest={true}
        isDisabled={false}
      />
    </div>
  );
};

export default RegisterGuestForm;
