import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/events/event';
import {carouselEvents} from '../../state/events/eventsPaginated';

const useFetchCarouselEvents = () => {
  const setEvents = useSetRecoilState(carouselEvents);

  return useQuery('useFetchCarouselEvents', async () => {
    const events = await actions.fetchCarouselEvents();
    setEvents(events);
    return events;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchCarouselEvents;
