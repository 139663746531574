/* eslint-disable no-unused-vars */
export enum UIButtonCommonVariants {
  greenOutlined = 'greenOutlined',
  greenFilled = 'greenFilled',
  redOutlined = 'redOutlined',
  greenSquared = 'greenSquared',
  blackOutlined = 'blackOutlined',
  blackOutlinedTransparent = 'blackOutlinedTransparent',
  blackFilled = 'blackFilled',
  blackOutlinedShadowGreen = 'blackOutlinedShadowGreen',
  greenEvent = 'greenEvent',
  greenNewsletter = 'greenNewsletter',
  whiteFilled = 'whiteFilled',
  whiteOutlined = 'whiteOutlined',
  whitePink = 'whitePink',
  pink = 'pink',
  blank = 'blank',
}
