import React, {FC} from 'react';
import {parseHtml} from '../../../utils/parseHtml';
import UITag from '../../UICommon/UITag';
import expandedMark from '../../../assets/expanded_mark_desk.svg';

type NewsItemProps = {
  id: number;
  image: string;
  title: string;
  byline: string;
  tag: string;
  buttonFunction: () => void;
  expandedItemId: number | null;
}

const NewsItem: FC<NewsItemProps> = ({
  id,
  image,
  title,
  byline,
  tag,
  buttonFunction,
  expandedItemId,
}) => {
  const parsedByline = parseHtml(byline);
  return (
    <div onClick={() => {
      buttonFunction();
    }} id={`news-${id}`} className='w-full h-auto flex flex-col box'>
      <div className='h-[240px] relative'>
        <img className='h-full w-full object-cover rounded-t-15px' src={image}/>
        <UITag name={tag} extraStyles='absolute -bottom-4 left-5'/>
        {expandedItemId == id && (
          <div className='flex items-center gap-x-2 absolute bottom-2 right-5'>
            <p className='uppercase text-white font-medium text-sm'>LÄS MER</p>
            <img src={expandedMark} alt='image'/>
          </div>
        )}
      </div>
      <div className='h-auto'>
        <div className='bg-white px-5 pt-8 h-full flex flex-col pb-8 rounded-b-15px'>
          <h3 className='font-medium text-lg text-black-dark'>{title}</h3>
          <div className='text-black-dark/50 text-base news-content-wrapper'>
            <p>{parsedByline}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
