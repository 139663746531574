import {isValidEmail, isValidPassword} from './isValid';

export const validateForm = (formValues: any, isUserForm: boolean) => {
  const updatedFormValues: any = {};
  for (const key in formValues) {
    if (formValues[key]) {
      updatedFormValues[key] = formValues[key];
    }
  }

  const invoiceMail = formValues.invoice_email;
  const isMailValid = isValidEmail(invoiceMail);

  let isFormValid = isMailValid;
  const formErrorMessages = {
    invoice_email: isMailValid ? '' : 'Vänligen ange en giltig e-postadress',
    password: true ? '' : 'Lösenordet ska innehålla 6 tecken, 1 siffra och 1 specialtecken',
  };

  if (isUserForm && formValues.password) {
    const password = formValues.password;
    const isPasswordValid = isValidPassword(password);
    isFormValid = isFormValid && isPasswordValid;
    formErrorMessages.password = isPasswordValid ? '' :
    'Lösenordet ska innehålla minst 6 tecken, 1 siffra och 1 specialtecken';
  }

  return {isFormValid, formErrorMessages, updatedFormValues};
};
