import React, {FC} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {pagesType} from '../../types/pages';
import {archiveCurrentPageIndex, archivePages, archiveIsGdpr} from '../../state/pages/pages';

import UIPaginatedTextWrapper from '../UIWrappers/UIPaginatedTextWrapper';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';


const ArchiveSectionComponent: FC = () => {
  const archivePageData : pagesType[] = useRecoilValue(archivePages);
  const [currentIndex, setCurrentIndex] = useRecoilState(archiveCurrentPageIndex);
  const [gdprIndex] = useRecoilState(archiveIsGdpr);

  if (!archivePageData || archivePageData.length === 0) {
    return <UILoadingIndicator height='h-fit'/>;
  }

  return (
    <div className='w-full'>
      <UIPaginatedTextWrapper
        data={archivePageData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        archiveIsGdpr={gdprIndex}
      />
    </div>
  );
};

export default ArchiveSectionComponent;

