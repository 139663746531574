import React, {FC, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {MdClose} from 'react-icons/md';

import {isLoggedIn} from '../../utils/isLoggedIn';
import {authService} from '../../api/authService';

import UINavToggler from '../UICommon/UINavToggler';

import logoImg from '../../assets/mih-logo.png';
import logoWhiteImg from '../../assets/mih_logo_white.png';


type AppLayoutMenuPage = {
  url: string;
  title: string;
}

const appPages: AppLayoutMenuPage[] = [
  {
    url: '/all-inclusive/',
    title: 'ALL INCLUSIVE',
  },
  {
    url: '/partners/',
    title: 'BLI PARTNER',
  },
  {
    url: '/about/',
    title: 'OM MIH',
  },
  {
    url: '/archive/',
    title: 'ARKIV',
  },
  {
    url: '/faq/',
    title: 'FAQ',
  },
  {
    url: '/login/',
    title: 'LOGGA IN',
  },
  {
    url: '/logout/',
    title: 'LOGGA UT',
  },
];

const isLoggedInHiddenLinks= [
  {
    url: '/login/',
  },
  {
    url: '/forgot-password/',
  },
];

const AppLayoutMenu: FC = () => {
  const location = useLocation();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const menuIsHidden = isLoggedInHiddenLinks.some((link) => {
    return location.pathname.startsWith(link.url) || location.pathname.startsWith('/reset-password/');
  });
  const darkMenu = location.pathname === '/member-portal/' || location.pathname === '/register/';
  const navigate = useNavigate();

  const handleLogout = async () => {
    authService.logout();
    navigate('/login/');
  };

  return (
    <nav className={`flex w-full list-none justify-between manrope py-7 text-gray-semi items-center ` +
    `bg-gray-body fixed left-0 top-0 z-50 ${menuIsHidden && 'hidden'} ` +
    `${darkMenu && 'bg-blue-dark !text-white'}`
    }>
      <div className='flex items-center justify-between max-w-container m-auto w-full px-5 lg:px-4 2xl:px-0'>
        <Link to='/'>
          <img className='h-auto w-20' src={logoImg}/>
        </Link>
        <div className={`nav space-x-14 hidden lg:flex items-center text-base font-medium`}>
          {appPages.map((appPage: AppLayoutMenuPage, index: number) => {
            if (appPage.url === '/login/' && isLoggedIn()) {
              return null;
            } else if (appPage.url === '/logout/' && !isLoggedIn()) {
              return null;
            } else if (appPage.url === '/logout/' && isLoggedIn()) {
              return (
                <li key={index}>
                  <button onClick={handleLogout}>
                    <span>{appPage.title}</span>
                  </button>
                </li>
              );
            }
            return (
              <li key={index}>
                <Link to={appPage.url}>
                  <span>{appPage.title}</span>
                </Link>
              </li>
            );
          })}
          <div className='hidden lg:block'>
            {isLoggedIn() ? (
              <Link to='/member-portal/'>
                <div className='bg-green-primary px-5 py-3 text-white rounded-md shadow-button !ml-16'>
                  MEDLEMSPORTAL
                </div>
              </Link>
            ) : (
              <Link to='/become-member/'>
                <div className='bg-green-primary px-5 py-3 text-white rounded-md shadow-button !ml-16'>
                  BLI MEDLEM
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
      {/* MOBILE MENU */}
      <div className={`${toggleDropdown ? 'left-0' : '-left-[100%] h-screen'}
        bg-blue-dark w-full h-screen absolute z-40 text-white transition-all duration-300 top-0 px-4 pt-8 menu ` +
        `flex flex-col`
      }>
        <div className='flex w-full justify-between'>
          <img className='w-20 h-auto' src={logoWhiteImg} alt='image'/>
          <MdClose className='text-4xl' onClick={() => setToggleDropdown(!toggleDropdown)}/>
        </div>
        <div className='flex flex-col relative pt-20'>
          {appPages.map((appPage: AppLayoutMenuPage, index: number) => {
            if (appPage.url === '/login/' && isLoggedIn()) {
              return null;
            } else if (appPage.url === '/logout/' && !isLoggedIn()) {
              return null;
            } else if (appPage.url === '/logout/' && isLoggedIn()) {
              return (
                <li key={index}>
                  <button className={`${toggleDropdown ? 'left-0' : '-left-[100%]'} position-inherit transition-all ` +
                  `text-35px manrope font-medium duration-300`
                  }
                  style={{transitionDelay: `${index * 100}ms`}}
                  onClick={handleLogout}>
                    <span>{appPage.title}</span>
                  </button>
                </li>
              );
            }
            return (
              <li
                key={index}
                className={`${toggleDropdown ? 'left-0' : '-left-[100%]'} position-inherit transition-all ` +
                `text-35px manrope font-medium duration-300`
                }
                style={{transitionDelay: `${index * 100}ms`}}
              >
                <Link
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                  to={appPage.url}
                >
                  <span>{appPage.title}</span>
                </Link>
              </li>
            );
          })}
          <div className='flex flex-col mt-12 space-y-4 inter font-medium uppercase'>
            {isLoggedIn() ? (
              <Link
                onClick={() => setToggleDropdown(!toggleDropdown)}
                className='border border-white px-6 py-2 text-white rounded-full uppercase w-fit shadow-button-green'
                to='/member-portal/'
              >
                MEDLEMSPORTAL
              </Link>
            ) : (
              <Link
                onClick={() => setToggleDropdown(!toggleDropdown)}
                className='border border-white px-6 py-2 text-white rounded-full uppercase w-fit shadow-button-green'
                to='/become-member/'
              >
                BLI MEDLEM
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className='flex lg:hidden items-center space-x-4'>
        {!isLoggedIn() && (
          <Link
            className='bg-green-primary px-3 py-2 text-white rounded-full min-w-[94px] uppercase'
            to='/login/'
          >
            LOGGA IN
          </Link>
        )}
        <div className={`menu-icon-toggle`}>
          <UINavToggler
            onClick={() => setToggleDropdown(!toggleDropdown)}
            location={darkMenu}
          />
        </div>
      </div>
      {/* MOBILE MENU END */}
    </nav>
  );
};

export default AppLayoutMenu;
