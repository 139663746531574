import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';

type UITestimonialComponentTypes = {
  nameTitle: string;
  companyTitle: string;
  description: string;
}

const UITestimonialComponent: FC<UITestimonialComponentTypes> = ({
  nameTitle,
  companyTitle,
  description,
}) => {
  return (
    <UICardComponent width='w-full' padding='px-5 md:px-6 pb-10'>
      <div className='flex flex-col w-full manrope text-gray-semidark h-full justify-between'>
        <div>
          <span className='text-[202px] leading-none h-20 w-fit -ml-3 block'>
            &ldquo;
          </span>
          <div className='text-25px md:text-38px font-semibold pt-4 pb-16'
            dangerouslySetInnerHTML={{__html: description}}
          >
          </div>
        </div>
        <div className='flex items-center'>
          {nameTitle && (
            <div
              className='bg-white text-gray-semiblack text-sm lg:text-base rounded-full'
            >
              {nameTitle} -
            </div>
          )}
          {companyTitle && (
            <div
              className='bg-white text-gray-semiblack text-sm lg:text-base rounded-full'
            >
              &nbsp;{companyTitle}
            </div>
          )}
        </div>
      </div>
    </UICardComponent>
  );
};

export default UITestimonialComponent;
