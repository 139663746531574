import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/pages/pages';
import {all} from '../../state/allInclusive/allinclusive';

const useFetchAllInclusive = () => {
  const setAllInclusive = useSetRecoilState(all);

  return useQuery(
      'allinclusive',
      async () => {
        const allInclusive = await actions.fetchAllInclusive();
        setAllInclusive(allInclusive);
        return allInclusive;
      },
      {
        refetchOnWindowFocus: false,
      },
  );
};

export default useFetchAllInclusive;
