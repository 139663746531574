import React, {FC, useState} from 'react';
import {BiShow} from 'react-icons/bi';
import {UIInputVariants} from '../../variants/UIInputVariants';

type UIPasswordInputProps = {
  id: string;
  label: string;
  name: string;
  disabled?: boolean;
  value?: any;
  formErrorMessage: string;
  onChange?: Function;
  variant?: keyof typeof UIInputVariants;
  placeholder?: string;
};

const UIPasswordInput: FC<UIPasswordInputProps> = ({
  id,
  label,
  name,
  disabled = false,
  value,
  formErrorMessage,
  onChange,
  variant = 'common',
  placeholder = '',
}) => {
  const variantClasses = {
    [UIInputVariants.common]: `w-full lg:w-80 border border-gray-deepLight rounded-[5px] py-2 px-3 
    text-black-body font-semibold`,
    [UIInputVariants.green]: `w-full lg:w-80 bg-green-light rounded-[5px] py-2 px-3 
    text-black-body font-medium`,
    [UIInputVariants.pink]: ``,
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="flex flex-col w-full relative">
        <label className="text-sm font-semibold mb-2" htmlFor={id}>
          {label}
        </label>
        <input
          id={id}
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange && onChange(event)}
          className={`${variantClasses[variant]}`}
          disabled={disabled}
        />
        <BiShow className='absolute right-2 bottom-3 z-10' onClick={() => setShowPassword(!showPassword)}></BiShow>
        {formErrorMessage && (
          <small className="text-red-600 font-semibold text-xs mt-1">
            {formErrorMessage}
          </small>
        )}
      </div>
    </>
  );
};

export default UIPasswordInput;
