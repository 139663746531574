import React, {FC} from 'react';

import UICardComponent from '../UIWrappers/UICardComponent';
import ProfileFormInputComponent from './ProfileFormInputComponent';
import MembersListComponent from './MembersListComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';
import InviteUserComponent from '../UIForms/InviteUserComponent';
import InviteUserMobileComponent from '../UIForms/InviteUserMobileComponent';


type CompanyDetailsFormTypes = {
  companyEmail: string;
  companyCity: string;
  companyPostalCode: string;
  companyReference: string;
  onChange: Function;
  onClick: Function;
  errorMessages?: any;
  disabled: boolean;
}

const CompanyDetailsForm: FC<CompanyDetailsFormTypes> = ({
  companyEmail,
  companyCity,
  companyPostalCode,
  companyReference,
  onChange,
  onClick,
  errorMessages,
  disabled,
}) => {
  const companyFormInputs = [
    {
      id: 'companyEmail',
      name: 'invoice_email',
      type: 'text',
      label: 'Faktureringsaddress',
      placeholder: companyEmail,
      value: companyEmail,
    },
    {
      id: 'companyReference',
      name: 'reference',
      type: 'text',
      label: 'Referens',
      placeholder: companyReference,
      value: companyReference,
    },
    {
      id: 'companyCity',
      name: 'city',
      type: 'text',
      label: 'Stad',
      placeholder: companyCity,
      value: companyCity,
    },
    {
      id: 'companyPostalCode',
      name: 'postal_code',
      type: 'text',
      label: 'Postnummer',
      placeholder: companyPostalCode,
      value: companyPostalCode,
    },
  ];
  return (
    <UICardComponent
      width='w-full'
      backgroundColor='bg-transparent md:bg-white'
      padding='md:pl-12 md:pr-6 md:pt-4 md:pb-6 lg:pl-[72px] lg:pr-12 lg:pt-10 lg:pb-8'
      extraStyles='!rounded-b-lg md:!rounded-t-none'
    >
      <div className='flex space-y-6 md:space-y-0 flex-col md:flex-row'>
        <div className={`flex flex-col space-y-4 w-full md:w-1/3 xl:w-1/2 bg-white 
        md:bg-transparent py-6 px-4 md:p-0 rounded-15px`}>
          {companyFormInputs && companyFormInputs.map((input, index: number) => {
            const name = input.name as keyof typeof errorMessages;
            return (
              <ProfileFormInputComponent
                key={index}
                id={input.id}
                name={input.name}
                type={input.type}
                onChange={onChange}
                errorMessages={errorMessages[name] && errorMessages[name]}
                label={input.label}
                placeholder={`${input.type === 'password' ? 'password' : (input.placeholder || input.label)}`}
                value={input.value ? input.value : ''}
                disabled={disabled}
              />
            );
          })}
          {!disabled && (
            <UIButtonCommon
              extraStyles='mt-6 hover:!bg-blue-dark hover:!text-white'
              buttonTitle='Spara profil'
              buttonType='button'
              onClick={onClick}
              variant='greenFilled'
            />
          )}
        </div>
        <div className='w-full md:w-2/3 xl:w-1/2 flex flex-col'>
          {!disabled && (
            <div className={`w-full flex flex-col md:flex-row items-center 
            bg-green-light px-4 py-6 md:pl-6 md:pr-2 md:py-4 md:rounded-15px`}>
              <div className='w-full md:w-1/3'>
                <h3 className='text-green-primary font-bold text-lg mb-2 md:mb-0'>
                  Lägg till medlem
                </h3>
              </div>
              <div className='w-full md:w-2/3'>
                <div className='hidden md:block'>
                  <InviteUserComponent
                    buttonVariant='blank'
                    buttonTitle='Skicka'
                    position='absolute right-1 top-1'
                    buttonExtraStyles='text-white bg-green-primary px-4 py-2 text-base'
                  />
                </div>
                <div className='block md:hidden'>
                  <InviteUserMobileComponent
                    buttonVariant='blank'
                    buttonTitle='Skicka'
                    buttonExtraStyles='text-white bg-green-primary px-4 py-2 text-base'
                  />
                </div>
              </div>
            </div>
          )}
          <MembersListComponent disabled={disabled} />
        </div>
      </div>
    </UICardComponent>
  );
};

export default CompanyDetailsForm;
