import React, {FC} from 'react';
import {useNavigate} from 'react-router';

import {BiCheckCircle, BiXCircle} from 'react-icons/bi';
import {useSetRecoilState} from 'recoil';

import {perksTypes, itemsTypes} from '../../types/subscriptions';
import {selectedPlan} from '../../state/subscriptions/subscriptions';

import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';

type PlanDetailsBoxComponentTypes = {
  planTitle: string;
  planPrice: string;
  planDescription: string;
  planPerks: perksTypes[];
  planItems: itemsTypes[];
  planType: string;
  isUpgrading?: boolean;
}

const PlanDetailsBoxComponent: FC<PlanDetailsBoxComponentTypes> = ({
  planTitle,
  planPrice,
  planDescription,
  planPerks,
  planItems,
  planType,
  isUpgrading,
}) => {
  const navigate = useNavigate();
  const setSelectedUserPlan = useSetRecoilState(selectedPlan);

  const handleSelectPlan = (id: number, title: string, price: number, qty: number, type: string) => {
    setSelectedUserPlan({
      selectedId: id,
      title: title,
      qty: qty,
      price: price,
      type: type,
    });
    if (isUpgrading) {
      navigate('/register-organisation/upgrade/');
      return;
    }
    navigate('/register/');
  };

  return (
    <div className='flex flex-col space-y-4'>
      <UICardComponent
        width='w-full'
        padding='pt-7 px-6'
        extraStyles='relative'
      >
        <div className='flex flex-col justify-between w-full text-left h-full pb-8'>
          <div>
            <div className='text-black-normal'>
              <h3 className='text-lg font-bold mb-2'>
                {planTitle}
              </h3>
              <span className='text-32px font-bold'>
                {planPrice}
              </span>
            </div>
            <div
              dangerouslySetInnerHTML={{__html: planDescription}}
              className='text-sm font-medium text-black-normal/60'
            />
            <ul className='mt-5 text-black-normal/60 space-y-2 pb-3'>
              {planPerks && planPerks.map((el: perksTypes, index: number) => (
                <div key={index}>
                  {el.enabled == true ? (
                    <li className='flex items-baseline text-base font-medium w-full'>
                      <div className='w-1/12'>
                        <BiCheckCircle
                          className='mr-1 text-green-primary'
                        />
                      </div>
                      <div className='w-11/12'>
                        {el.description}
                      </div>
                    </li>
                  ) : (
                    <li className='flex items-center text-base font-medium w-full'>
                      <div className='w-1/12'>
                        <BiXCircle
                          className='mr-1 text-red-primary'
                        />
                      </div>
                      <div className='w-11/12'>
                        {el.description}
                      </div>
                    </li>
                  )}
                </div>
              ))}
            </ul>
          </div>
          <div className='flex flex-col text-xs pt-3 border-t border-t-black-normal/5'>
            <span className='text-black-normal/60'>
              Övrigt
            </span>
            <p className='text-black-normal font-medium'>
              OBS! Priserna är exklusive moms och våra fakturor skickas med epost. Önskas e-fakturering kontakta &nbsp;
              <a className='underline' href="mailto: ekonomi@mih.nu">
                ekonomi@mih.nu
              </a>
            </p>
          </div>
        </div>
      </UICardComponent>
      <UICardComponent
        width='w-full'
        padding='pt-7 pb-16 px-5 lg:px-9'
        extraStyles='space-y-4'
      >
        {planItems && planItems.map((el: itemsTypes, index: number) => (
          <div key={index} className='flex items-center justify-between'>
            <div className='flex flex-col'>
              <div className='text-black-normal/60' dangerouslySetInnerHTML={{__html: el.description}}/>
              <span className='text-lg font-medium text-black-normal'>
                {el.price} kr/12 månader
              </span>
            </div>
            <UIButtonCommon
              buttonTitle='Välj'
              variant='greenSquared'
              onClick={() => handleSelectPlan(el.id, planTitle, el.price, el.user_qty, planType)}
            />
          </div>
        ))}

      </UICardComponent>
    </div>
  );
};

export default PlanDetailsBoxComponent;
