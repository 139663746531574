import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import UIInput from '../UICommon/UIInput';
import UIFileInput from '../UICommon/UIFileInput';
import UIButtonCommon from '../UIButtons/UIButtonCommon';

type FormInputsArrayType = {
  id: string;
  type: string;
  label: string;
  name: string;
  value?: any;
};

type RegisterPageFormComponentProps = {
  stepName: string;
  stepOrder: number;
  totalSteps: number;
  formInputsArray: FormInputsArrayType[];
  isDisabled: boolean;
  keyPrefix: string;
  formErrorMessages: any;
  onClick?: Function;
  isGuest?: boolean;
  setFile?: Function;
  onChange?: Function;
};

const RegisterUpgradePageFormComponent: FC<RegisterPageFormComponentProps> = ({
  stepName,
  stepOrder,
  totalSteps,
  formInputsArray,
  isDisabled,
  keyPrefix,
  formErrorMessages,
  onClick,
  isGuest = false,
  setFile,
  onChange,
}) => {
  return (
    <div className={`flex flex-col ${isDisabled == true ? 'opacity-30' : 'opacity-100'}`}>
      <div
        className={`flex items-center text-center px-8 py-2 mb-4 bg-green-primary 
        text-white text-lg font-semibold w-fit rounded-full`}
      >
        {stepName} {stepOrder}/{totalSteps}
      </div>
      <UICardComponent
        width='w-full'
        padding='sm:pl-9 sm:pr-12 lg:pr-24 pt-10 pb-9'
        extraStyles='!rounded-30px'
        backgroundColor='bg-transparent sm:bg-white'
      >
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-6'>
          {formInputsArray.map((el: FormInputsArrayType, index: number) => {
            const name = el.name as keyof typeof formErrorMessages;
            return (
              <UIInput
                id={`${el.id}`}
                onChange={onChange}
                key={`${keyPrefix}-${index}`}
                type={el.type}
                label={el.label}
                name={el.name}
                value={el.value}
                formErrorMessage={formErrorMessages[name] as string}
                variant='green'
                placeholder={`Fyll i ${el.label}`}
                disabled={isDisabled}
              />
            );
          })}
        </div>
        <>
          {!isGuest ? (
          <div className='flex flex-col mt-7'>
            <h3 className='text-black-dark font-semibold text-lg mb-3'>Företagsprofil</h3>
            <UIFileInput
              id={`${keyPrefix}avatar`}
              label='Ladda upp bild'
              name={`${keyPrefix}avatar`}
              setFile={setFile && setFile}
              formErrorMessage={formErrorMessages.avatar}
            />
          </div>
          ) : (
            <div className='w-full flex justify-end'>
              <UIButtonCommon
                buttonTitle='Skicka in'
                buttonType='button'
                variant='greenFilled'
                width='w-fit'
                extraStyles='mt-8 hover:!bg-blue-dark hover:!text-white'
                onClick={onClick && onClick}
              />
            </div>
          )}
        </>
      </UICardComponent>
    </div>
  );
};

export default RegisterUpgradePageFormComponent;
