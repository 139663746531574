import React, {FC, useState} from 'react';
import {parseHtml} from '../../../utils/parseHtml';
import UITag from '../../UICommon/UITag';
import expandedMark from '../../../assets/expanded_mark.svg';

type NewsItemMobileProps = {
  id: number;
  image: string;
  title: string;
  byline: string;
  tag: string;
  description: string;
}

const NewsItemMobile: FC<NewsItemMobileProps> = ({
  id,
  image,
  title,
  byline,
  tag,
  description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const parsedByline = parseHtml(byline);
  return (
    <>
      <div onClick={() => setIsExpanded(!isExpanded)} id={`news-${id}`} className='w-full h-auto flex flex-col box'>
        <div className='h-[240px] relative'>
          <img className='h-full w-full object-cover rounded-t-15px' src={image}/>
          <UITag name={tag} extraStyles='absolute -bottom-4 left-5'/>
          {isExpanded && (
            <img className='absolute right-5 bottom-4' src={expandedMark} alt="" />
          )}
        </div>
        <div className='h-auto'>
          <div className='bg-white px-5 pt-8 h-full flex flex-col pb-8 rounded-b-15px'>
            <h3 className='font-medium text-lg text-black-dark'>{title}</h3>
            <div className={`text-black-dark/50 text-base ${isExpanded ? '' : 'news-content-wrapper'}`}>
              <p>{parsedByline}</p>
            </div>
            {isExpanded && (
              <div className='text-black-dark/50 text-base mt-6 content'>
                <p dangerouslySetInnerHTML={{__html: description}}></p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsItemMobile;
