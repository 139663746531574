import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import {authService} from '../../api/authService';
import {invitedUserFormFields} from '../../utils/registerPageForms';
import {isValidEmail, isValidFullName, isValidPassword} from '../../utils/isValid';
import {RegisterUserFormErrorMessagesType} from '../../types/register';
import {EventTargetType} from '../../types/eventTarget';

import RegisterPageFormComponent from '../RegisterPage/RegisterPageFormComponent';
import PlanSummaryComponent from '../RegisterPage/PlanSummaryComponent';

const RegisterInvitedUserForm: FC = () => {
  const [isUserValid, setIsUserValid] = useState(false);
  const [formValues, setformValues] = useState({
    email: '',
    password: '',
    avatar: null,
    full_name: '',
    work_place: '',
    reference: '',
    invoice_email: '',
    street: '',
    city: '',
    postal_code: '',
  });
  const [userFormErrorMessages, setUserFormErrorMessages] = useState<RegisterUserFormErrorMessagesType>({
    avatar: '',
    email: '',
    password: '',
    full_name: '',
    work_place: '',
    reference: '',
    invoice_email: '',
    street: '',
    city: '',
    postal_code: '',
  });
  const summaryRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [triggerScroll, setTriggereScroll] = useState(false);

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setformValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: '',
    }));
  };

  const setUserAvatar = (file: any) => {
    setformValues((prevValues) => ({
      ...prevValues,
      avatar: file,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      avatar: '',
    }));
  };

  const validateFields = (
      obj: any,
      setFormErrorMessages: Function,
      defaultErrorMessages: any,
      setIsValid: Function,
  ) => {
    let isValid = true;
    const tmpErrorMessages: any = {...defaultErrorMessages};
    let isPasswordValid: boolean = true;
    let isEmailValid: boolean = true;
    let isFullNameValid: boolean = true;
    let isInvoiceEmailValid: boolean= true;
    if (obj.password) {
      isPasswordValid = isValidPassword(obj.password);
    }
    if (obj.email) {
      isEmailValid = isValidEmail(obj.email);
    }
    if (obj.invoice_email) {
      isInvoiceEmailValid = isValidEmail(obj.invoice_email);
    }
    if (obj.full_name) {
      isFullNameValid = isValidFullName(obj.full_name);
    }

    for (const key in obj) {
      if ((obj[key] === null || obj[key] === undefined || obj[key] === '') && key !== 'reference') {
        tmpErrorMessages[key] = `Det här fältet får inte vara tomt.`;
        isValid = false;
      }
    }

    if (!isPasswordValid) {
      tmpErrorMessages.password = `Lösenordet måste innehålla minst 6 tecken, 1 specialtecken och 1 siffra.`;
      isValid = false;
    }

    if (!isFullNameValid) {
      tmpErrorMessages.full_name = `Fältet ska innehålla namn och efternamn.`;
      isValid = false;
    }

    if (!isEmailValid) {
      tmpErrorMessages.email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }

    if (!isInvoiceEmailValid) {
      tmpErrorMessages.invoice_email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }
    setTriggereScroll(!triggerScroll);
    setFormErrorMessages(tmpErrorMessages);
    setIsValid(isValid);
  };

  useEffect(() => {
    if (summaryRef.current && isUserValid) {
      window.scrollTo({
        top: summaryRef.current.offsetTop - 148,
        behavior: 'smooth',
      });
    }
  }, [isUserValid, triggerScroll]);


  const handleSubmit = () => {
    const token = window.location.pathname.split('/').pop();
    authService.registerCompanyUser(formValues, token)
        .then(() => {
          navigate('/login/');
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      <RegisterPageFormComponent
        stepName='Företagsuppgifter'
        stepOrder={1}
        totalSteps={2}
        formInputsArray={invitedUserFormFields}
        onChange={onUserInputChange}
        formErrorMessages={userFormErrorMessages}
        keyPrefix='user-form-'
        setFile={setUserAvatar}
        isDisabled={false}
      />
      <div
        className={`flex items-center text-center px-8 py-2 my-4 bg-green-primary 
        text-white text-lg font-semibold w-fit rounded-full cursor-pointer`}
        onClick={() => {
          validateFields(formValues, setUserFormErrorMessages, userFormErrorMessages, setIsUserValid);
        }}
      >
        Nästa
      </div>
      <div ref={summaryRef}>
        <PlanSummaryComponent
          stepName='Slutför köp'
          stepOrder={2}
          totalSteps={2}
          selectedPlan='Gå som partner'
          selectedPlanPrice={0}
          onSubmit={handleSubmit}
          isDisabled={!isUserValid}
          isInvited={true}
        />
      </div>
    </div>
  );
};

export default RegisterInvitedUserForm;
