import axios from 'axios';
import {createFormData} from '../utils/createFormData';

const CONTACT_URL = process.env.REACT_APP_API_URI + 'all-inclusive-contact/';

const postContactForm = async (data: any) => {
  const res = await axios.post(CONTACT_URL, createFormData(data));
  return res.data;
};

const contact = {
  postContactForm,
};

export default contact;
