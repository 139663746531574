import axios from 'axios';
import client from '../clients/base';

const URL = process.env.REACT_APP_API_URI + 'subscription-types/';
const CREATE_URL = process.env.REACT_APP_API_URI + 'subscription-enrollment/create/';
const UPGRADE_URL = 'subscription-enrollment/update/';

const fetchSubscriptions = async () => {
  const response = await axios.get(URL);
  return response.data;
};

const createSubscription = async (data: any) => {
  const response = await axios.post(CREATE_URL, data);
  return response.data;
};

const upgradeSubscription = async (id: number, subscription: number) => {
  const response = await client.put(UPGRADE_URL + id + '/', {subscription: subscription});
  return response.data;
};

const actions = {
  fetchSubscriptions,
  createSubscription,
  upgradeSubscription,
};

export default actions;
