import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserType} from '../../types/currentUser';

import MemberItemComponent from './MemberItemComponent';
import {currentUser} from '../../state/user/currentUser';

type MembersListComponentType = {
  disabled: boolean;
}

const MembersListComponent: FC<MembersListComponentType> = ({disabled = false}) => {
  const memberListData: currentUserType = useRecoilValue(currentUser);

  return (
    <div className='flex flex-col px-4 md:px-0 bg-white md:bg-transparent rounded-b-15px md:rounded-b-0'>
      {memberListData && memberListData.organisation.company_users.map((el, index) => (
        <MemberItemComponent
          key={index}
          id={el.id}
          image={el.avatar}
          name={el.full_name}
          position={el.work_place}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default MembersListComponent;
