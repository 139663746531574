import React, {FC} from 'react';

import {partnerType} from '../../types/partners';

type partnersComponentTypes = {
  index: number;
  title: string;
  perks: string;
  partners: partnerType[];
  partnerType: string;
}


const PartnersComponent: FC<partnersComponentTypes> = ({title, perks, partners, partnerType}) => {
  const filteredPartners = partners.find((partner) => partner.title === partnerType);
  const partnerList = filteredPartners ? filteredPartners.partners_list : null;
  return (
    <div className='flex flex-col lg:flex-row w-full'>
      <div className='flex flex-col w-full lg:w-1/3'>
        <h2 className='text-27px lg:text-33px font-semibold pb-3 border-b-2 border-gray-semiLight w-full'>
          {title}
        </h2>
        <div className='py-7 text-black-dark text-base lg:text-lg partners-content-wrapper'
          dangerouslySetInnerHTML={{__html: perks}}
        >
        </div>
      </div>
      <div
        className={`w-full lg:w-2/3 h-fit grid grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-4 lg:gap-y-0 lg:gap-x-16
        lg:px-20 items-center`}>
        {partnerList && partnerList.map((partner, index) => (
          <div key={index} className='h-28 lg:h-[262px] flex items-center justify-center'>
            <a href={partner.url} target='blank' className='flex justify-center items-center'>
              <img
                className='mix-blend-luminosity opacity-20 hover:mix-blend-normal hover:opacity-100
                transition-all duration-150 ease-in-out max-h-28 max-w-[170px] w-3/4 md:w-full'
                src={`${partner.logo}`} alt=""
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersComponent;
