import {useMutation} from 'react-query';
import actions from '../../api/events/eventsEnrollment';

const useMutateVerifyEmail = () => {
  return useMutation(async (data: any) => {
    await actions.verifyEmail(data);
  }, {retry: false});
};

export default useMutateVerifyEmail;
