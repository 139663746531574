import React, {FC, useEffect, useState} from 'react';

import {isValidEmail} from '../../utils/isValid';
import {EventTargetType} from '../../types/eventTarget';
import useRefetchCurrentUser from '../../hooks/auth/useRefetchCurrentUser';
import useMutateInviteUserToOrganisation from '../../hooks/user/useMutateInviteUserToOrganisation';

import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';

import UIButtonCommon from '../UIButtons/UIButtonCommon';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

type InviteUserComponentProps = {
  buttonVariant: keyof typeof UIButtonCommonVariants;
  buttonTitle: string;
  position: string;
  buttonExtraStyles?: string;
}

const InviteUserComponent: FC<InviteUserComponentProps> = ({
  buttonTitle,
  buttonVariant,
  position,
  buttonExtraStyles,
}) => {
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [messageInfo, setMessageInfo] = useState('');
  const {
    isLoading: isAddLoading,
    isError: isAddError,
    error: addError,
    isSuccess: isAddSuccess,
    mutate: mutateAdd,
  } = useMutateInviteUserToOrganisation();
  const {
    isLoading: isLoadingCurrentUser,
    refetch: refetchCurrentUser,
  } = useRefetchCurrentUser();

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setMessageInfo('');
    if (!isValidEmail(formValues.email)) {
      setMessageInfo('email');
      return;
    }
    if (isValidEmail(formValues.email)) {
      mutateAdd(formValues.email);
    }
  };

  useEffect(() => {
    if (isAddSuccess) {
      setMessageInfo('success');
      refetchCurrentUser();
    }

    if (isAddError && addError) {
      const error: any = addError;
      if (error.response.status === 404) {
        setMessageInfo('exists');
      } else {
        setMessageInfo(error.response.data.message);
      }
    }
  }, [isAddSuccess, isAddError, refetchCurrentUser]);

  if (isAddLoading || isLoadingCurrentUser) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  return (
    <div className='flex flex-col'>
      <div className='relative flex items-center manrope'>
        <input
          type="text"
          name='email'
          placeholder='Fyll i din email'
          onChange={onUserInputChange}
          className={`bg-white text-base text-gray-deepLight w-full py-3 rounded-full px-3`}
        />
        <div className={`absolute ${position}`}>
          <UIButtonCommon
            width='w-fit'
            buttonTitle={buttonTitle}
            buttonType='button'
            variant={buttonVariant}
            onClick={handleSubmit}
            extraStyles={buttonExtraStyles}
          />
        </div>
      </div>
      <>
        {messageInfo === 'email' ? (
          <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
            Vänligen ange giltig e-post.
          </small>
        ) : messageInfo === 'exists' ? (
          <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
            Användaren hittades inte.
          </small>
        ) : messageInfo === 'success' ? (
          <small className="text-green-primary font-semibold text-xs mt-2 ml-3">
            E-post skickades
          </small>
        ) : (
          <>
            {messageInfo && (
              <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
                {messageInfo}
              </small>
            )}
          </>
        )
        }
      </>
    </div>
  );
};

export default InviteUserComponent;
