import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'pages/home/';

const fetchHomePage = async () => {
  const response = await axios.get(URL);
  return response.data;
};

const actions = {
  fetchHomePage,
};

export default actions;
