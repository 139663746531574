import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {partnersPaginated} from '../state/partners/partnersPaginated';
import {randomTestimonials} from '../state/testimonials/testimonials';

import {partnerPaginatedType} from '../types/partners';
import {testimonialsResponseType} from '../types/testimonials';

import useFetchPartners from '../hooks/partners/useFetchPartners';
import useFetchTestimonials from '../hooks/testimonials/useFetchTestimonials';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UIHeroComponent from '../components/UICommon/UIHeroComponent';
import PartnersSection from '../components/PartnersPage/PartnersSection';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import CtaSectionComponent from '../components/PartnersPage/CtaSectionComponent';

import heroImg from '../assets/hero_image.png';


const PartnersPage: FC = () => {
  const {isLoading: isLoadingPartners, isError: isErrorPartners} = useFetchPartners();
  const {isLoading: isLoadingTestimonials, isError: isErrorTestimonials} = useFetchTestimonials();
  const partnersData: partnerPaginatedType = useRecoilValue(partnersPaginated);
  const testimonialsData: testimonialsResponseType = useRecoilValue(randomTestimonials);

  if (isErrorPartners || isErrorTestimonials) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };
  if (isLoadingPartners || isLoadingTestimonials || partnersData == null) {
    return (
      <UILoadingIndicator/>
    );
  };

  return (
    <UIPageContainer>
      <UIHeroComponent
        image={heroImg}
        text='Tillsammans skapar vi en dynamisk mötesplats för näringslivet.'
        buttonTitle='BLI PARTNER'
        buttonUrl='mailto:canita@mih.nu?subject=Förfrågan bli partner MiH'
        height='h-426px'
        isExtraImage={false}
      />
      <PartnersSection
        partnersType={partnersData.results}
        partners={partnersData.results}
      />
      <CtaSectionComponent
        buttonCompanyTitle={testimonialsData[0]?.organisation}
        buttonNameTitle={testimonialsData[0]?.author}
        description={testimonialsData[0]?.quote}
        ctaTitle='Vill du bli partner?'
        ctaDescription='Hitta vilket medlemskap som
        passar dig eller din organisation!'
        ctaButtonTitle='Bli partner'
        ctaButtonUrl='mailto:canita@mih.nu?subject=Förfrågan bli partner MiH'
      />
    </UIPageContainer>
  );
};

export default PartnersPage;
