export function createFormData(formValues: any) {
  const formData = new FormData();

  function appendFormData(data: any, formData: FormData, parentKey?: string) {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        if (data[key] !== null) {
          formData.append(currentKey, data[key]);
          appendFormData(data[key], formData, currentKey);
        }
      });
    } else if (data !== null && parentKey !== undefined) {
      formData.append(parentKey, data);
    }
  }

  appendFormData(formValues, formData);
  return formData;
}
