import React, {FC} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {pagesType} from '../../types/pages';
import {faqCurrentPageIndex, faqPages} from '../../state/pages/pages';

import UIPaginatedTextWrapper from '../UIWrappers/UIPaginatedTextWrapper';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';


const FaqSectionComponent: FC = () => {
  const faqPageData : pagesType[] = useRecoilValue(faqPages);
  const [currentIndex, setCurrentIndex] = useRecoilState(faqCurrentPageIndex);

  if (!faqPageData || faqPageData.length === 0) {
    return <UILoadingIndicator height='h-auto'/>;
  }

  return (
    <div className='w-full'>
      <UIPaginatedTextWrapper
        data={faqPageData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
};

export default FaqSectionComponent;

