import {useInfiniteQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';

import actions from '../../api/news/news';
import {newsPaginated} from '../../state/news/newsPaginated';

const useFetchNews = () => {
  const setNews = useSetRecoilState(newsPaginated);

  return useInfiniteQuery('useFetchNews', async ({pageParam = 1}) => {
    const news = await actions.fetchNews(pageParam);
    if (!news.next) {
      return news;
    }
    setNews((prev) => ({
      count: news.count,
      next: news.next,
      previous: news.previous,
      results: [...prev.results, ...news.results],
    }));
    return news;
  }, {
    getNextPageParam: (lastPage) => lastPage.next || undefined,
    refetchOnWindowFocus: false,
  });
};

export default useFetchNews;
