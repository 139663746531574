import {FC} from 'react';

type UISubmitProps = {
  label: string;
  onClick: Function;
};

const UISubmit: FC<UISubmitProps> = ({
  label,
  onClick,
}) => {
  return (
    <div className='text-center'>
      <button
        className={
          `bg-black-normal text-white font-semibold w-full rounded-[5px] py-2 hover:bg-green-primary` +
        ` transition-colors duration-150 ease-in-out`}
        type='button'
        onClick={(e) => onClick(e)}
      >
        {label}
      </button>
    </div>
  );
};

export default UISubmit;
