import React, {FC, useState} from 'react';
import {FiChevronDown} from 'react-icons/fi';

type ProfileUpgradeDropdownProps = {
  currentUserQty: number;
  avaliableUpgrades: any;
  subscriptionEnrollment: any;
  selectPlan: Function;
};

const ProfileUpgradeDropdown: FC<ProfileUpgradeDropdownProps> = ({
  currentUserQty,
  avaliableUpgrades,
  subscriptionEnrollment,
  selectPlan,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='relative'>
      <button
        type='button'
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-green-primary text-white relative flex font-bold w-full ` +
        `items-center py-3 px-4 pr-8 shadow-sm text-base rounded-md`}
      >
        {subscriptionEnrollment == null && 'Ingen prenumeration'}
        {avaliableUpgrades != null && subscriptionEnrollment != null && (currentUserQty + ' personer')}
        <FiChevronDown
          className={`${isOpen && 'rotate-180'} absolute right-3 transition-transform duration-150 ease-out`}
        />
      </button>
      <div
        className={`${!isOpen ? 'hidden' : 'block'} ` +
          `w-60 absolute flex flex-col text-left bg-white rounded-15px shadow-sm top-[120%] ` +
          `items-center shadow-sm text-base overflow-hidden`}
      >
        {avaliableUpgrades && avaliableUpgrades.map((el: any, index: number) => (
          <div
            key={`upgrade-${index}`}
            className={`hover:bg-gray-semiLight cursor-pointer px-4 py-3 text-lg ` +
            `text-left font-semibold text-black-dark/60 w-full ` +
            `${index !== avaliableUpgrades.length - 1 && 'border-b border-[#CCCCCC]'}`}
            onClick={() => selectPlan(el)}
          >
            {el.description}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileUpgradeDropdown;
