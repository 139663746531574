import client from '../clients/base';

const CHANGE_ADMIN_URL = 'change-organisation-admin/';
const REMOVE_USER_URL = 'remove-organisation-user/';
const INVITE_USER_URL = process.env.REACT_APP_API_URI + 'invite-user/';

const passOrganisationAdmin = async (user: number) => {
  try {
    const res = await client.post(CHANGE_ADMIN_URL, {user: user});
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const removeUserFromOrganisation = async (user: number) => {
  try {
    const res = await client.post(REMOVE_USER_URL, {user: user});
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const inviteUser = async (email: string) => {
  try {
    const res = await client.post(INVITE_USER_URL, {email: email});
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const actions = {
  passOrganisationAdmin,
  removeUserFromOrganisation,
  inviteUser,
};

export default actions;
