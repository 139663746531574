import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'reset-password/';
const CONFIRM_URL = process.env.REACT_APP_API_URI + 'reset-password/complete/';

const requestPasswordReset = async (email: string) => {
  const res = await axios.post(URL, {
    'email': email,
  });
  return res.data;
};

const confirmPasswordReset = async (token: string) => {
  const res = await axios.get(CONFIRM_URL + token + '/');
  return res.data;
};

const setNewPassword = async (token: string, password: string, confirmPassword: string) => {
  const res = await axios.post(CONFIRM_URL + token + '/', {
    'password': password,
    'confirm_password': confirmPassword,
  });
  return res.data;
};

const actions = {
  requestPasswordReset,
  confirmPasswordReset,
  setNewPassword,
};

export default actions;
