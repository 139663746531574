import {useMutation} from 'react-query';
import actions from '../../api/subscriptions/subscriptions';

const useMutateUpgradeSubscription = () => {
  return useMutation(async (data: any) => {
    await actions.upgradeSubscription(data.id, data.subscription);
  }, {retry: false});
};

export default useMutateUpgradeSubscription;
