import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useRecoilValue} from 'recoil';

import {currentUser} from '../state/user/currentUser';
import {isLoggedIn} from '../utils/isLoggedIn';
import useRefetchCurrentUser from '../hooks/auth/useRefetchCurrentUser';
import {currentUserType} from '../types/currentUser';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import OrganisationUserBoxFormComponent from '../components/MemberPage/OrganisationUserBoxFormComponent';
import RegularUserBoxFormComponent from '../components/MemberPage/RegularUserBoxFormComponent';

const MemberPage: FC = () => {
  const {
    isLoading: isLoadingMemberPage,
    isError: isErrorMemberPage,
    refetch: refetchCurrentUser,
  } = useRefetchCurrentUser();

  const currentUserData: currentUserType = useRecoilValue(currentUser);
  const [isRegularUser, setIsRegularUser] = useState<boolean | null>(true);
  const isLoggedInUser: boolean = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    refetchCurrentUser();
  }, []);

  useEffect(() => {
    if (!currentUserData || currentUserData.organisation == undefined) {
      setIsRegularUser(true);
    } else {
      setIsRegularUser(false);
    }
  }, [currentUserData]);

  useEffect(() => {
    if (!isLoggedInUser) {
      navigate('/login/');
    }
  }, [isLoggedInUser]);

  if (isErrorMemberPage) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  }

  if (isLoadingMemberPage || currentUserData == null) {
    return (
      <UILoadingIndicator/>
    );
  }

  return (
    <>
      <div className='absolute top-0 left-0 z-0 hidden md:block md:h-[25vh] bg-blue-dark w-full'/>
      <UIPageContainer isPadding={false}>
        <>
          {isRegularUser ? (
            <RegularUserBoxFormComponent
              refetchCurrentUser={refetchCurrentUser}
            />
          ) : (
            <OrganisationUserBoxFormComponent
              refetchCurrentUser={refetchCurrentUser}
            />
          )}
        </>
      </UIPageContainer>
    </>
  );
};

export default MemberPage;
