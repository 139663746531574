import {useMutation} from 'react-query';
import actions from '../../api/user/organisation';

const useMutateRemoveUserFromOrganisation = () => {
  return useMutation(async (user: number) => {
    await actions.removeUserFromOrganisation(user);
  }, {retry: false});
};

export default useMutateRemoveUserFromOrganisation;
