import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Helmet} from 'react-helmet';
import {RecoilRoot} from 'recoil';

import LandingPage from './pages/LandingPage';
import PartnersPage from './pages/PartnersPage';
import ArchivePage from './pages/ArchivePage';
import FAQPage from './pages/FAQPage';
import LoginPage from './pages/LoginPage';
import BecomeMemberPage from './pages/BecomeMemberPage';
import AboutPage from './pages/AboutPage';
import EventsSinglePage from './pages/EventsSinglePage';
import EventsArchivePage from './pages/EventsArchivePage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import MemberPage from './pages/MemberPage';
import RegisterPage from './pages/RegisterPage';
import SetNewPasswordPage from './pages/SetNewPasswordPage';
import RegisterGuestPage from './pages/RegisterGuestPage';
import RegisterInvitedPage from './pages/RegisterInvitedPage';
import RegisterPartnerPage from './pages/RegisterPartnerPage';
import UpgradeSelectPlanPage from './pages/UpgradeSelectPlanPage';
import UpgradePlanRegisterPage from './pages/UpgradePlanRegisterPage';
import AllInclusivePage from './pages/AllInclusivePage';

import ScrollToTop from './components/UICommon/ScrollToTop';
import AppLayoutMenu from './components/AppLayout/AppLayoutMenu';
import AppLayoutFooter from './components/AppLayout/AppLayoutFooter';
import UIPageContainer from './components/UIWrappers/UIPageContainer';

import './theme/index.css';
import Page404 from './pages/404';

import logo from './assets/mih-logo.png';

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <RecoilRoot>
      <Helmet>
        <title>Marknadsföreningen i Halland</title>
        <meta
          name="description"
          content={
            `Ideell förening. Mötesplatsen för Näringslivet med föreläsare i toppklass. För dig ` +
            `som vill införskaffa kunskap, nätverka och få en skön start på morgonen med ` +
            `inspirerande/intressanta föreläsningar om entreprenörskap, ledarskap, marknadsföring, kommunikation m m.`}
        />
        <meta property="og:image" content={logo} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppLayoutMenu/>
          <ScrollToTop/>
          <Routes>
            <Route
              path="/all-inclusive/"
              element={<AllInclusivePage/>}
            />
            <Route
              path="/partners/"
              element={<PartnersPage/>}
            />
            <Route
              path="/"
              element={<LandingPage/>}
            />
            <Route
              path="/about/"
              element={<AboutPage/>}
            />
            <Route
              path="/archive/"
              element={<ArchivePage/>}
            />
            <Route
              path="/faq/"
              element={<FAQPage/>}
            />
            <Route
              path="/login/"
              element={<LoginPage/>}
            />
            <Route
              path="/register/"
              element={<RegisterPage/>}
            />
            <Route
              path="/register-organisation/upgrade/"
              element={<UpgradePlanRegisterPage/>}
            />
            <Route
              path="/register/:token/"
              element={<RegisterInvitedPage/>}
            />
            <Route
              path="/register-guest/"
              element={<RegisterGuestPage/>}
            />
            <Route
              path="/register-partner/:id/"
              element={<RegisterPartnerPage/>}
            />
            <Route
              path="/become-member/"
              element={<BecomeMemberPage/>}
            />
            <Route
              path="/register-organisation/plans"
              element={<UpgradeSelectPlanPage/>}
            />
            <Route
              path="/events/"
              element={<EventsArchivePage/>}
            />
            <Route
              path="/events/:id"
              element={<EventsSinglePage/>}
            />
            <Route
              path="/member-portal/"
              element={<MemberPage/>}
            />
            <Route
              path="/forgot-password/"
              element={<ForgotPasswordPage/>}
            />
            <Route path='/reset-password/complete/:token' element={<SetNewPasswordPage/>} />
            <Route path="*" element={<Page404/>}
            />
          </Routes>
          <UIPageContainer>
            <AppLayoutFooter/>
          </UIPageContainer>
        </Router>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
