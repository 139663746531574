export const formatVideoLink = (url: string) => {
  try {
    const regExp = /watch\?v=([\w-]+)/;
    const link = regExp.exec(url);

    if (link && link.length > 1) {
      return link[1];
    } else {
      return null;
    }
  } catch (error) {
    console.error('Ett fel inträffade:', error);
    return null;
  }
};
