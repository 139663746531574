import React, {FC, useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {useNavigate} from 'react-router';

import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';

import {isLoggedIn} from '../../utils/isLoggedIn';
import {isValidEmail} from '../../utils/isValid';
import {enrollEvent} from '../../state/events/enrollEvent';
import {EventTargetType} from '../../types/eventTarget';
import useMutateEnrollToEvent from '../../hooks/events/useMutateEnrollToEvent';
import useMutateVerifyEmail from '../../hooks/events/useMutateVerifyEmail';

import UIButtonCommon from '../UIButtons/UIButtonCommon';
import UIEnrollmentFormErrorMessage from './UIEnrollmentFormErrorMessage';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

type UIEnrollmentFormMobileComponentProps = {
  buttonVariant: keyof typeof UIButtonCommonVariants;
  buttonTitle: string;
  eventId?: number;
  formExtraStyles?: string;
  inputBg?: string;
}

const UIEnrollmentFormMobileComponent: FC<UIEnrollmentFormMobileComponentProps> = ({
  buttonTitle,
  buttonVariant,
  eventId,
  formExtraStyles,
  inputBg = 'bg-white',
}) => {
  const [formValues, setFormValues] = useState({
    event_id: eventId,
    email: '',
  });
  const [messageInfo, setMessageInfo] = useState('');
  const setEnrollmentData = useSetRecoilState(enrollEvent);
  const {
    isLoading: isEnrollLoading,
    isError: isEnrollError,
    error: enrollError,
    isSuccess: isEnrollSuccess,
    mutate: mutateEnroll,
  } = useMutateEnrollToEvent();
  const {
    isLoading: isVerifyLoading,
    isError: isVerifyError,
    error: verifyError,
    isSuccess: isVerifySuccess,
    mutate: mutateVerify,
  } = useMutateVerifyEmail();
  const navigate = useNavigate();

  const handleEventData = (id: number, email: string) => {
    setEnrollmentData({
      email: email,
      event_id: id,
    });
    navigate('register-guest/');
  };

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setMessageInfo('');
    if (!isValidEmail(formValues.email)) {
      setMessageInfo('email');
      return;
    }
    if (isLoggedIn() && isValidEmail(formValues.email)) {
      mutateEnroll(formValues);
    } else {
      mutateVerify(formValues);
    }
  };

  useEffect(() => {
    if (isEnrollSuccess) {
      setMessageInfo('success');
    }

    if (isEnrollError) {
      const err: any = enrollError;
      if (err.response.status == 404) {
        setMessageInfo('exists');
      } else {
        setMessageInfo(err.response.data);
      }
    }
  }, [isEnrollSuccess, isEnrollError, enrollError]);

  useEffect(() => {
    if (isVerifySuccess) {
      setMessageInfo('notFound');
    }

    if (isVerifyError) {
      const err: any = verifyError;
      if (err.response.status == 404) {
        setMessageInfo('exists');
      } else {
        setMessageInfo(err.response.data);
      }
    }
  }, [isVerifySuccess, isVerifyError, verifyError]);

  if (isEnrollLoading || isVerifyLoading) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  return (
    <div className='flex flex-col'>
      <div className='relative flex flex-col manrope'>
        <input
          type="text"
          name='email'
          placeholder='Fyll i din email'
          onChange={onUserInputChange}
          className={`${inputBg} rounded-full ` +
          `w-full eventSignUp py-3 pl-5 text-base ${formExtraStyles ? formExtraStyles : ''}`}
        />
        <div className={location.pathname === '/' ? 'absolute right-[2px] top-[2px]' : ''}>
          <UIButtonCommon
            width='w-full'
            buttonTitle={buttonTitle}
            buttonType='submit'
            variant={buttonVariant}
            onClick={handleSubmit}
            extraStyles='!py-[10px]'
          />
        </div>
      </div>
      <UIEnrollmentFormErrorMessage
        messageInfo={messageInfo}
        onClick={() => handleEventData(formValues.event_id as number, formValues.email)}
      />
    </div>
  );
};

export default UIEnrollmentFormMobileComponent;
