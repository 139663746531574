import React, {FC, useEffect, useRef, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';

import {authService} from '../../api/authService';
import {selectedPlan} from '../../state/subscriptions/subscriptions';
import {userFormFields} from '../../utils/registerPageForms';
import {isValidEmail, isValidFullName, isValidPassword} from '../../utils/isValid';
import {RegisterUserFormErrorMessagesType} from '../../types/register';
import {EventTargetType} from '../../types/eventTarget';
import useMutateCreateSubscription from '../../hooks/subscriptions/useMutateCreateSubscription';
import useMutateNewsletterSubscribe from '../../hooks/newsletter/useMutateNewsletterSubscribe';

import RegisterPageFormComponent from './RegisterPageFormComponent';
import PlanSummaryComponent from './PlanSummaryComponent';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

const RegisterUserForm: FC = () => {
  const navigate = useNavigate();
  const plan = useRecoilValue(selectedPlan);
  const [isUserValid, setIsUserValid] = useState(false);
  const [triggerScroll, setTriggereScroll] = useState(false);
  const [formValues, setformValues] = useState({
    avatar: '',
    email: '',
    password: '',
    full_name: '',
    work_place: '',
    reference: '',
    invoice_email: '',
    street: '',
    city: '',
    postal_code: '',
  });
  const [userFormErrorMessages, setUserFormErrorMessages] = useState<Partial<RegisterUserFormErrorMessagesType>>({
    avatar: '',
    email: '',
    password: '',
    full_name: '',
    work_place: '',
    invoice_email: '',
    street: '',
    city: '',
    postal_code: '',
  });
  const summaryRef = useRef<HTMLDivElement>(null);
  const userInfoRef = useRef<HTMLDivElement>(null);
  const {
    isLoading: isCreateSubscriptionLoading,
    isError: isCreateSubscriptionError,
    error: createSubscriptionError,
    isSuccess: isCreateSubscriptionSuccess,
    mutate: mutateCreateSubscription,
  } = useMutateCreateSubscription();
  const {
    isLoading: isNewsletterSubscribeLoading,
    isError: isNewsletterSubscribeError,
    error: newsletterSubscribeError,
    mutate: mutateSubscribeNewsletter,
  } = useMutateNewsletterSubscribe();

  const onUserInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setformValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: '',
    }));
  };

  const setUserAvatar = (file: any) => {
    setformValues((prevValues) => ({
      ...prevValues,
      avatar: file,
    }));
    setUserFormErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      avatar: '',
    }));
  };

  const validateFields = (
      obj: any,
      setFormErrorMessages: Function,
      defaultErrorMessages: any,
      setIsValid: Function,
  ) => {
    let isValid = true;
    const tmpErrorMessages: any = {...defaultErrorMessages};
    let isPasswordValid: boolean = true;
    let isEmailValid: boolean = true;
    let isFullNameValid: boolean = true;
    let isInvoiceEmailValid: boolean = true;
    let isNumber: boolean = true;
    if (obj.password) {
      isPasswordValid = isValidPassword(obj.password);
    }
    if (obj.email) {
      isEmailValid = isValidEmail(obj.email);
    }
    if (obj.full_name) {
      isFullNameValid = isValidFullName(obj.full_name);
    }
    if (obj.invoice_email) {
      isInvoiceEmailValid = isValidEmail(obj.invoice_email);
    }
    if (obj.org_no) {
      isNumber = isNaN(obj.org_no) ? false : true;
    }

    for (const key in obj) {
      if (key !== 'reference' && (obj[key] === null || obj[key] === undefined || obj[key] === '')) {
        tmpErrorMessages[key] = `Det här fältet får inte vara tomt.`;
        isValid = false;
      }
    }

    if (!isPasswordValid) {
      tmpErrorMessages.password = `Lösenordet måste innehålla minst 6 tecken, 1 specialtecken och 1 siffra.`;
      isValid = false;
    }

    if (!isEmailValid) {
      tmpErrorMessages.email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }

    if (!isFullNameValid) {
      tmpErrorMessages.full_name = `Fältet ska innehålla namn och efternamn.`;
      isValid = false;
    }

    if (!isInvoiceEmailValid) {
      tmpErrorMessages.invoice_email = `Det angivna värdet är inte en giltig e-postadress.`;
      isValid = false;
    }

    if (!isNumber) {
      tmpErrorMessages.org_no = `Organisationsnummer måste vara ett nummer.`;
      isValid = false;
    }

    setTriggereScroll(!triggerScroll);
    setFormErrorMessages(tmpErrorMessages);
    setIsValid(isValid);
  };

  useEffect(() => {
    if (summaryRef.current && isUserValid) {
      window.scrollTo({
        top: summaryRef.current.offsetTop - 148,
        behavior: 'smooth',
      });
    }
  }, [isUserValid, triggerScroll]);

  const handleSubmit = () => {
    authService.registerUser(formValues)
        .then((res) => {
          mutateCreateSubscription({
            type: 'user',
            object_id: res.id,
            subscription: plan.selectedId,
          });
          mutateSubscribeNewsletter(formValues.email);
        })
        .catch((error) => {
          const errors: any = error.response.data;
          if (errors.email) {
            setUserFormErrorMessages((prevErrorMessages) => ({
              ...prevErrorMessages,
              email: 'Användare med denna e-postadress finns redan.',
            }));
          }
          if (userInfoRef.current) {
            window.scrollTo({
              top: userInfoRef.current.offsetTop - 148,
              behavior: 'smooth',
            });
          }
        });
  };

  useEffect(() => {
    if (isCreateSubscriptionSuccess) {
      navigate('/login/');
    }

    if (isCreateSubscriptionError) {
      console.log(createSubscriptionError);
    }
  }, [isCreateSubscriptionSuccess, isCreateSubscriptionError, createSubscriptionError]);

  useEffect(() => {
    if (isNewsletterSubscribeError) {
      console.log(newsletterSubscribeError);
    }
  }, [isNewsletterSubscribeLoading, isNewsletterSubscribeError, newsletterSubscribeError]);

  if (isCreateSubscriptionLoading || isNewsletterSubscribeLoading) {
    return <UILoadingIndicatorFixed />;
  }

  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      <div ref={userInfoRef}>
        <RegisterPageFormComponent
          stepName='Personliga uppgifter'
          stepOrder={1}
          totalSteps={2}
          formInputsArray={userFormFields}
          onChange={onUserInputChange}
          formErrorMessages={userFormErrorMessages}
          keyPrefix='user-form-'
          setFile={setUserAvatar}
          isDisabled={false}
        />
      </div>
      <div
        className={`flex items-center text-center px-8 py-2 my-4 bg-green-primary 
        text-white text-lg font-semibold w-fit rounded-full cursor-pointer`}
        onClick={() => {
          validateFields(
              formValues,
              setUserFormErrorMessages,
              userFormErrorMessages,
              setIsUserValid,
          );
        }}
      >
        Nästa
      </div>
      <div ref={summaryRef}>
        <PlanSummaryComponent
          stepName='Slutför köp'
          stepOrder={2}
          totalSteps={2}
          selectedPlan={plan.title}
          selectedPlanPrice={plan.price}
          onSubmit={handleSubmit}
          isDisabled={!isUserValid}
        />
      </div>
    </div>
  );
};

export default RegisterUserForm;
