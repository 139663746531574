import {FC} from 'react';

import userImagePlaceholder from '../../assets/user_icon.svg';

type UIUserAvatarProps = {
  image: string,
  extra?: string,
};

const UIUserAvatar: FC<UIUserAvatarProps> = ({
  image,
  extra,
}) => {
  return (
    <div
      className={`border rounded-full h-[38px] w-[38px] md:h-[45px] md:w-[45px] ${extra} bg-white overflow-hidden`}
    >
      <img
        className='w-[38px] md:w-[45px]'
        src={image || userImagePlaceholder}
        onError={(e) => {
          e.currentTarget.onerror = null;
          e.currentTarget.src = userImagePlaceholder;
        }}
      />
    </div>
  );
};

export default UIUserAvatar;

