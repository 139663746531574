import React, {FC} from 'react';
import {Link} from 'react-router-dom';

type UIEnrollmentFormErrorMessageProps = {
  messageInfo: string;
  onClick: Function;
}

const UIEnrollmentFormErrorMessage: FC<UIEnrollmentFormErrorMessageProps> = ({
  messageInfo,
  onClick,
}) => {
  return (
    <>
      {messageInfo === 'email' ? (
        <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
          Vänligen ange giltig e-post.
        </small>
      ) : messageInfo === 'notFound' ? (
        <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
          Du eller någon annan från ditt företag har redan gått som gäst, vänligen
          <Link className='underline' to="/become-member/">skapa ett medlemskap</Link> för att fortsätta.
        </small>
      ) : messageInfo === 'exists' ? (
        <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
          Användaren hittades inte.
          <button
            onClick={() => onClick()}
            className='underline'>
            Skapa konto som gäst.
          </button>
        </small>
      ) : messageInfo === 'success' ? (
        <small className="text-green-primary font-semibold text-xs mt-2 ml-3">
          Registrerade dig till evenemanget
        </small>
      ) : messageInfo === 'canceled' ? (
        <small className="text-green-primary font-semibold text-xs mt-2 ml-3">
          Anmälan till evenemanget har avbrutits.
        </small>
      ) : messageInfo !== '' && (
        <small className="text-red-600 font-semibold text-xs mt-2 ml-3">
          {messageInfo}
        </small>
      )}
    </>
  );
};

export default UIEnrollmentFormErrorMessage;
