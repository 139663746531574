import React, {FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue, useResetRecoilState} from 'recoil';

import {singleEvent} from '../state/events/eventsPaginated';
import {home} from '../state/homePage/home';
import {eventType} from '../types/event';
import homeTypes from '../types/home';

import useFetchSingleEvent from '../hooks/events/useFetchSingleEvent';
import useFetchHomePage from '../hooks/pages/useFetchHomePage';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UIHeroComponent from '../components/UICommon/UIHeroComponent';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import EventDetailsSectionComponent from '../components/Events/EventDetailsSectionComponent';
import EventDetailsSectionMobileComponent from '../components/Events/EventDetailsSectionMobileComponent';
import UICtaComponent from '../components/UICommon/UICtaComponent';

import heroImg from '../assets/single_event_hero.jpg';
import heroImgMobile from '../assets/single_event_hero_mobile.png';

const EventsSinglePage: FC = () => {
  const {id} = useParams();
  const resetEvent = useResetRecoilState(singleEvent);
  const {
    isLoading: isLoadingEvents,
    isError: isErrorEvents,
    refetch: refetchSingleEvent,
  } = useFetchSingleEvent(Number(id));
  const {isLoading: isLoadingHomePage, isError: isErrorHomePage} = useFetchHomePage();
  const homePageData : homeTypes = useRecoilValue(home);
  const eventData: eventType = useRecoilValue(singleEvent);

  useEffect(() => {
    resetEvent();
    if (id != undefined) {
      refetchSingleEvent();
    }
  }, [id]);

  if (isLoadingEvents || isLoadingHomePage || eventData == null) {
    return (
      <UILoadingIndicator/>
    );
  };

  if (isErrorEvents || isErrorHomePage) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };

  return (
    <UIPageContainer>
      <UIHeroComponent
        height='h-426px'
        image={heroImg}
        mobileImage={heroImgMobile}
        text={eventData.title}
        isExtraImage={false}
      />
      <EventDetailsSectionComponent
        id={eventData.id}
        title={eventData.title}
        description={eventData.description}
        information={eventData.information}
        speakers={eventData.enrolled_list}
        date={eventData.start_time}
        enrollmentType={eventData.enrollment_type}
        enrollmentDate={eventData.enrollment_date}
        enrollmentUrl={eventData.external_url}
      />
      <EventDetailsSectionMobileComponent
        id={eventData.id}
        title={eventData.title}
        description={eventData.description}
        information={eventData.information}
        speakers={eventData.enrolled_list}
        date={eventData.start_time}
        enrollmentType={eventData.enrollment_type}
        enrollmentDate={eventData.enrollment_date}
        enrollmentUrl={eventData.external_url}
      />
      <UICtaComponent
        title={homePageData.cta_title}
        description={homePageData.cta_description}
        buttonTitle={homePageData.cta_button}
        buttonUrl={homePageData.cta_url}
      />
    </UIPageContainer>
  );
};

export default EventsSinglePage;
