import React, {FC} from 'react';
import {useNavigate} from 'react-router';
import {useSetRecoilState} from 'recoil';

import {eventPeriod} from '../../state/events/eventsPaginated';
import {eventType} from '../../types/event';

import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';
import UIHeading from '../UICommon/UIHeading';
import UIEventCard from '../UICommon/UIEventCard';


type ArchiveEventsSectionTypes = {
  title: string;
  isImage: boolean;
  events: eventType[];
  padding?: string;
  filter?: 'past' | 'upcoming';
};

const ArchiveEventsSection: FC<ArchiveEventsSectionTypes> = ({
  title,
  isImage,
  events,
  padding = 'py-8',
  filter = 'upcoming',
}) => {
  const setEventPeriod = useSetRecoilState(eventPeriod);
  const navigate = useNavigate();

  if (events.length === 0) {
    return null;
  }

  const handleClick = () => {
    if (filter === 'upcoming') {
      setEventPeriod('future');
    } else {
      setEventPeriod('past');
    }
    navigate('/events/');
  };

  return (
    <div className={`${padding}`}>
      <UIHeading
        text={title}
        extraStyles='justify-between items-center'
        isButton={true}
        buttonTitle='VISA ALLA'
        buttonOnClick={() => handleClick()}
        buttonVariant={UIButtonCommonVariants.blackOutlined}
      />
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-6 mt-10'>

        {events.slice(0, 8).map((el) => (
          <UIEventCard
            key={el.id}
            isImage={isImage}
            event={el}
          />
        ))}
      </div>
    </div>
  );
};

export default ArchiveEventsSection;
