import React, {FC, useState} from 'react';
import CurrentPlanInfoModal from '../UIModals/CurrentPlanInfoModal';
import ButtonIconComponent from './ButtonIconComponent';
import {BsEye} from 'react-icons/bs';

type InfoWidgetComponentTypes = {
  subStartDate: string;
  currentSubPrice: string;
}

const InfoWidgetComponent: FC<InfoWidgetComponentTypes> = ({subStartDate, currentSubPrice}) => {
  const [isShowed, setIsShowed] = useState(false);
  return (
    <div className='flex flex-col space-y-3 w-fit relative'>
      <span className='text-gray-semi font-medium text-base hidden lg:block'>
        Info
      </span>
      <div className='w-fit'>
        <ButtonIconComponent
          onMouseEnter={() => setIsShowed(true)}
          onMouseLeave={() => setIsShowed(false)}
          variant='green'
          icon={<BsEye/>}
        />
      </div>
      <CurrentPlanInfoModal
        state={isShowed}
        position='-bottom-[50%] lg:-bottom-[100%] right-0'
        subStartDate={subStartDate}
        currentSubPrice={currentSubPrice}
      />
    </div>
  );
};

export default InfoWidgetComponent;
