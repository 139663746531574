import React, {FC} from 'react';
import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';

type UIButtonCommonTypes = {
  buttonTitle: string;
  variant: keyof typeof UIButtonCommonVariants;
  target?: string;
  buttonUrl?: string;
  onSubmit?: Function;
  onClick?: Function;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  width?: string;
  margin?: string;
  extraStyles?: string;
}

const UIButtonCommon : FC<UIButtonCommonTypes> = (
    {
      buttonTitle,
      variant,
      target,
      buttonUrl,
      onSubmit,
      onClick,
      buttonType='button',
      width='w-fit',
      margin='m-0',
      extraStyles,
    }) => {
  const variantClasses = {
    [UIButtonCommonVariants.greenOutlined]: `border border-green-primary text-green-primary hover:bg-green-primary ` +
    `hover:text-white px-16 py-2 shadow-button-green w-full lg:w-fit`,
    [UIButtonCommonVariants.redOutlined]: `rounded-full border border-red-500 text-red-500 px-6 py-2 
    bg-white font-medium text-base hover:bg-red-500 hover:text-white`,
    [UIButtonCommonVariants.greenFilled]: `bg-green-primary text-white hover:bg-white hover:text-green-primary ` +
    `px-8 py-2 w-full lg:w-fit`,
    [UIButtonCommonVariants.greenSquared]: `bg-green-primary text-white px-8 py-3 w-full lg:w-fit` +
    `shadow-sm rounded-[10px] hover:scale-105`,
    [UIButtonCommonVariants.blackOutlined]: `border border-gray-black text-gray-black hover:bg-green-primary ` +
    `hover:border-green-primary px-4 py-2 lg:px-6 lg:py-3 hover:text-white text-sm lg:text-base`,
    [UIButtonCommonVariants.blackOutlinedTransparent]: `border border-gray-black/60 text-gray-black/60 text-xs ` +
    `hover:text-white hover:border-green-primary px-4 py-2 hover:bg-green-primary`,
    [UIButtonCommonVariants.blackFilled]: `bg-gray-black text-white hover:bg-green-primary px-4 py-2 lg:px-6 lg:py-3 ` +
    `text-sm lg:text-base `,
    [UIButtonCommonVariants.blackOutlinedShadowGreen]: `bg-white text-gray-semiblack ` +
    `px-3 md:px-4 py-2 text-sm lg:text-base shadow-button-green border-gray-semiblack border `,
    [UIButtonCommonVariants.greenEvent]: `!w-full bg-green-primary text-white px-6 py-3 md:px-3 ` +
    `lg:px-6 lg:py-4 rounded-full md:py-2 top-[6px] right-[5px] `,
    [UIButtonCommonVariants.greenNewsletter]: `absolute top-1 right-1 bg-green-primary text-white px-6 py-2 ` +
    `md:px-3 md:py-2 lg:px-6 lg:py-3 rounded-xl `,
    [UIButtonCommonVariants.whiteFilled]: `border border-white bg-white text-green-primary px-4 py-2 lg:px-6 lg:py-3 ` +
    `hover:bg-green-primary hover:text-white hover:border-white hover:border manrope text-base `,
    [UIButtonCommonVariants.whiteOutlined]: `border bg-white lg:bg-transparent border-white text-green-primary ` +
    `lg:text-white px-12 py-2 lg:px-6 lg:py-3 !w-full hover:bg-white hover:text-green-primary ` +
    `hover:border-white hover:border manrope text-base`,
    [UIButtonCommonVariants.whitePink]: `bg-white text-pink-dark manrope text-base font-medium ` +
    `px-3 py-2 w-fit hover:bg-pink-primary `,
    [UIButtonCommonVariants.pink]: `bg-pink-primary text-pink-dark manrope text-base font-medium ` +
    `px-12 py-3 !w-full lg:!w-fit hover:bg-pink-dark hover:text-pink-primary inter`,
    [UIButtonCommonVariants.blank]: ``,
  };
  return (
    <div className={`${margin} ${width}`}>
      {buttonUrl ? (
      <a
        href={buttonUrl}
        target={target}
        className={`${variantClasses[variant]} ${extraStyles && `${extraStyles}`} 
        transition-all ease-in-out duration-150 rounded-full text-center w-auto block`}
      >
        {buttonTitle}
      </a>
      ):
      <button
        type={buttonType}
        onClick={() => onClick && onClick()}
        onSubmit={() => onSubmit && onSubmit()}
        className={`${variantClasses[variant]} ${extraStyles && `${extraStyles}`}
        transition-all ease-in-out duration-150 rounded-full text-center w-auto block`}
      >
        {buttonTitle}
      </button>
      }
    </div>
  );
};

export default UIButtonCommon;
