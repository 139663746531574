import {FC} from 'react';
import {FaTimes} from 'react-icons/fa';

import {useRecoilValue} from 'recoil';
import useFetchNewsletter from '../../hooks/pages/useFetchNewsletter';
import {newsletterData} from '../../state/pages/pages';

import UILoadingIndicator from '../UICommon/UILoadingIndicator';
import UIEnrollmentNewsletterForm from '../UIForms/UIEnrollmentNewsletterForm';

type UINewsletterModalProps = {
  closeModal: Function;
}

const UINewsletterModal: FC<UINewsletterModalProps> = ({closeModal}) => {
  const {isLoading} = useFetchNewsletter();
  const newsletter = useRecoilValue(newsletterData);

  if (isLoading || newsletter == undefined) {
    return <UILoadingIndicator />;
  }

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50
     flex z-30 items-center justify-center h-full w-full'>
      <div className={`shadow rounded-xl bg-white relative w-4/5 ` +
      `md:w-3/5 h-fit lg:min-h-[50%] lg:max-h-[50%] lg:h-full overflow-hidden`}>
        <FaTimes className='w-5 h-5 cursor-pointer absolute right-5 top-5' onClick={() => closeModal()}/>
        <div className="flex w-full h-full">
          <div className="w-2/5 h-full hidden md:flex">
            <img src={newsletter.image} alt="" className='w-full h-full object-cover rounded-l-xl' />
          </div>
          <div className="w-full md:w-3/5 h-full p-8 flex items-center">
            <div className='w-full'>
              <h2 className='text-25px md:text-35px inter text-gray-black font-semibold leading-10 mb-4'>
                {newsletter.title}
              </h2>
              <div className='mb-8' dangerouslySetInnerHTML={{__html: newsletter.description}}></div>
              <UIEnrollmentNewsletterForm buttonText={newsletter.cta_button}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UINewsletterModal;
