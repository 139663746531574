import {atom, selector} from 'recoil';
import {aboutPageType, pagesType} from '../../types/pages';

export const pagesArray = atom<pagesType[]>({
  key: 'pagesArray',
  default: undefined,
});

export const faqCurrentPageIndex = atom<number>({
  key: 'faqCurrentPageIndex',
  default: 0,
});

export const archiveCurrentPageIndex = atom<number>({
  key: 'archiveCurrentPageIndex',
  default: 0,
});

export const archiveIsGdpr = atom<boolean>({
  key: 'archiveIsGdpr',
  default: false,
});

export const faqPages = selector<pagesType[]>({
  key: 'faqPages',
  get: ({get}) => {
    const pages = get(pagesArray);
    if (pages != null) {
      return pages.filter((item: pagesType) => item.category === 'faq');
    }
    return [];
  },
});

export const archivePages = selector<pagesType[]>({
  key: 'archivePages',
  get: ({get}) => {
    const pages = get(pagesArray);
    if (pages != null) {
      return pages.filter((item: pagesType) => item.category === 'archive');
    }
    return [];
  },
});

export const aboutPage = atom<aboutPageType>({
  key: 'aboutPage',
  default: undefined,
});

export const newsletterData = atom<any>({
  key: 'newsletterData',
  default: undefined,
});

