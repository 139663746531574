import {atom, selector} from 'recoil';
import {testimonialsResponseType} from '../../types/testimonials';
import getRandomItems from '../../utils/getRandomItems';

export const testimonials = atom<testimonialsResponseType>({
  key: 'testimonials',
  default: undefined,
});

export const randomTestimonials = selector<testimonialsResponseType>({
  key: 'randomTestimonials',
  get: ({get}) => {
    const testimonialsArray = get(testimonials);
    if (testimonialsArray != null) {
      const randomItems: testimonialsResponseType = getRandomItems(testimonialsArray);
      return randomItems;
    }
    return testimonialsArray;
  },
});

