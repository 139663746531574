import React, {FC} from 'react';
import UIPageContainer from '../UIWrappers/UIPageContainer';


type UIErrorIndicatorProps = {
  message: string;
  height?: string;
}

const UIErrorIndicator: FC<UIErrorIndicatorProps> = ({message, height='h-screen'}) => {
  return (
    <UIPageContainer>
      <div className={`w-full flex justify-center text-center font-semibold text-red-500 items-center ${height}`}>
        {message}
      </div>
    </UIPageContainer>
  );
};

export default UIErrorIndicator;
