import React, {ChangeEvent, FC, useState} from 'react';
import {UIDropdownSelectVariants} from '../../variants/UIDropdownSelectVariants';

type UIDropdownSelectProps = {
  id: string;
  label: string;
  name: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  formErrorMessage?: string;
  variant: keyof typeof UIDropdownSelectVariants;
  values: string[];
}

const UIDropdownSelect: FC<UIDropdownSelectProps> = ({
  id,
  label,
  name,
  placeholder,
  onChange,
  formErrorMessage,
  variant,
  values,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperVariantClasses = {
    [UIDropdownSelectVariants.allInclusiveCF]: `flex flex-col w-full text-sm font-semibold`,
  };
  const labelvariantClasses = {
    [UIDropdownSelectVariants.allInclusiveCF]: `text-pink-dark mb-5`,
  };
  const dropdownContainerVariantClasses = {
    [UIDropdownSelectVariants.allInclusiveCF]: `w-full text-gray-allinc-input font-normal text-lg border-b 
    border-b-black-body border-dashed pb-3 outline-none bg-transparent`,
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event);
    setIsOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${wrapperVariantClasses[variant]}`}>
      <label className={`${labelvariantClasses[variant]}`} htmlFor={id}>{label}</label>
      <div className={`relative`}>
        <div className={`${isOpen ? 'open' : ''} select-wrapper`}
          onClick={handleDropdownToggle}>
          <select
            placeholder={placeholder}
            className={`${dropdownContainerVariantClasses[variant]}`}
            id={id}
            name={name}
            onChange={handleSelectChange}
            defaultValue=''
          >
            <option value='' disabled>{placeholder}</option>
            {values.map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
          </select>
        </div>
        {formErrorMessage && (
          <div className="text-red-600 font-semibold text-xs mt-1">{formErrorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default UIDropdownSelect;
