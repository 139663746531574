import React, {FC} from 'react';
import {partnerType} from '../../types/partners';

import PartnersComponent from './PartnersComponent';


type partnerSectionTypes = {
  partnersType: partnerType[];
  partners: partnerType[];
}

const PartnersSection: FC<partnerSectionTypes> = ({partnersType, partners}) => {
  return (
    <div className='w-full py-12'>
      {partnersType && partnersType.map((el: partnerType, index:number) => (
        <div className='py-12' key={index}>
          <PartnersComponent
            index={index}
            title={el.title}
            perks={el.perks_description}
            partners={partners}
            partnerType={el.title}
          />
        </div>
      ))}
    </div>
  );
};

export default PartnersSection;

