import React, {FC} from 'react';

import {UIPageContainerVariants} from '../../variants/UIPageContainerVariants';

type UIPageContainerProps = {
  children: JSX.Element|JSX.Element[];
  variant?: keyof typeof UIPageContainerVariants;
  isPadding?: boolean;
  relative?: boolean;
}

const UIPageContainer: FC<UIPageContainerProps> = ({children, variant='full', isPadding=true, relative=true}) => {
  const variantClasses = {
    [UIPageContainerVariants.full]: 'max-w-container',
    [UIPageContainerVariants.wide]: 'max-w-container-wide',
  };

  return (
    <div className={`${variantClasses[variant]} ${isPadding && 'px-4 2xl:px-0'} ${relative && 'relative'} mx-auto`}>
      {children}
    </div>
  );
};

export default UIPageContainer;
