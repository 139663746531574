import React, {FC, useState} from 'react';
import {newsType} from '../../../types/news';
import useFetchNews from '../../../hooks/news/useFetchNews';

import NewsItem from './NewsItem';
import ExpandedNewsComponent from './ExpandedNewsComponents';

import UILoadingIndicator from '../../UICommon/UILoadingIndicator';
import UIErrorIndicator from '../../UICommon/UIErrorIndicator';
import UIButtonCommon from '../../UIButtons/UIButtonCommon';
import NewsItemMobile from './NewsItemMobile';
import UIHeading from '../../UICommon/UIHeading';


const NewsGridComponent: FC = () => {
  const {data, fetchNextPage, hasNextPage} = useFetchNews();
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
  const [expandedPageIndex, setExpandedPageIndex] = useState<number | null>(null);

  if (!data || data == null) {
    return <UILoadingIndicator />;
  }

  if (data.pages.length === 0) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu' />
    );
  }

  const handleItemClick = (itemId: number, pageIndex: number) => {
    setExpandedItemId(itemId);
    setExpandedPageIndex(pageIndex);
  };

  const getExpandedItemData = (): newsType | undefined => {
    if (expandedItemId !== null && expandedPageIndex !== null) {
      if (expandedPageIndex < data.pages.length) {
        const page = data.pages[expandedPageIndex];
        return page.results.find((item: newsType) => item.order === expandedItemId);
      }
    }
    return undefined;
  };

  return (
    <>
      <div className='w-full hidden lg:block py-12'>
        <UIHeading
          text={'Nyheter'}
          extraStyles='mt-16 mb-8'
        />
        {data && data.pages.map((page, pageIndex) => (
          <div id={`${pageIndex}`} key={pageIndex} className='wrapper gap-6 mt-6'>
            {page.results
                .sort((a: newsType, b: newsType) => a.order - b.order)
                .map((el: newsType) => (
                  <NewsItem
                    key={el.order}
                    id={el.order}
                    title={el.title}
                    byline={el.byline}
                    image={el.image}
                    tag={el.tags}
                    buttonFunction={() => handleItemClick(el.order, pageIndex)}
                    expandedItemId={expandedItemId}
                  />
                ))}
            {expandedItemId !== null && expandedPageIndex === pageIndex && (
              <div className='w-full my-6'>
                <ExpandedNewsComponent
                  id={getExpandedItemData()?.order || ''}
                  title={getExpandedItemData()?.title || ''}
                  description={getExpandedItemData()?.description || ''}
                  byline={getExpandedItemData()?.byline || ''}
                  image={getExpandedItemData()?.image || ''}
                  tag={getExpandedItemData()?.tags || ''}
                  buttonFunction={() => setExpandedItemId(null)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='w-full lg:hidden'>
        <UIHeading
          text={'Nyheter'}
          extraStyles='mt-16 mb-8'
        />
        {data && data.pages.map((page, pageIndex) => (
          <div id={`${pageIndex}`} key={pageIndex} className='wrapper gap-6 mt-6'>
            {page.results
                .sort((a: newsType, b: newsType) => a.order - b.order)
                .map((el: newsType) => (
                  <NewsItemMobile
                    key={el.order}
                    id={el.order}
                    title={el.title}
                    description={el.description}
                    byline={el.byline}
                    image={el.image}
                    tag={el.tags}
                  />
                ))}
          </div>
        ))}
      </div>
      {hasNextPage && (
        <div className='w-full flex justify-center mt-9'>
          <UIButtonCommon
            buttonTitle='Visa mer nyheter'
            buttonType='button'
            variant='whitePink'
            onClick={() => fetchNextPage()}
          />
        </div>
      )}
    </>
  );
};


export default NewsGridComponent;
