import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {BiUser} from 'react-icons/bi';
import {RiSuitcaseLine} from 'react-icons/ri';

import {validateForm} from '../../utils/validateForm';
import {EventTargetType} from '../../types/eventTarget';
import {currentUserType} from '../../types/currentUser';
import {currentUser} from '../../state/user/currentUser';
import useMutateUpdateUser from '../../hooks/user/useMutateUpdateUser';
import useMutateUpdateOrganisation from '../../hooks/user/useMutateUpdateOrganisation';

import ButtonIconComponent from './ButtonIconComponent';
import ProfileInfoBarComponent from './ProfileInfoBarComponent';
import ProfileDetailsForm from './ProfileDetailsForm';
import CompanyDetailsForm from './CompanyDetailsForm';
import UILoadingIndicatorFixed from '../UICommon/UILoadingIndicatorFixed';

type OrganisationUserBoxFormComponentType = {
    refetchCurrentUser: Function;
}

const OrganisationUserBoxFormComponent: FC<OrganisationUserBoxFormComponentType> = ({refetchCurrentUser}) => {
  const currentUserData: currentUserType = useRecoilValue(currentUser);
  const [activeForm, setActiveForm] = useState('userForm');
  const [successMessage, setSuccsessMessage] = useState(false);
  const [isOrgAvatarChanged, setIsOrgAvatarChanged] = useState(false);
  const [isUserAvatarChanged, setIsUserAvatarChanged] = useState(false);
  const [formUserValues, setFormUserValues] = useState<any>({
    avatar: currentUserData.avatar,
    full_name: currentUserData.full_name,
    work_place: currentUserData.work_place,
    reference: currentUserData.reference,
    invoice_email: currentUserData.invoice_email,
    street: currentUserData.street,
    street_number: currentUserData.street_number,
    city: currentUserData.city,
    postal_code: currentUserData.postal_code,
    password: '',
  });
  const [formOrgValues, setFormOrgValues] = useState<any>({
    company_name: currentUserData.organisation.company_name,
    avatar: currentUserData.organisation.avatar,
    invoice_email: currentUserData.organisation.invoice_email,
    org_no: currentUserData.organisation.org_no,
    street: currentUserData.organisation.street,
    city: currentUserData.organisation.city,
    postal_code: currentUserData.organisation.postal_code,
  },
  );
  const [userFormErrorMessages, setUserFormErrorMessages] =
    useState<any>({
      invoice_email: '',
      password: '',
    });
  const [orgFormErrorMessages, setOrgFormErrorMessages] =
    useState<any>({
      invoice_email: '',
    });

  const {
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
    isSuccess: isUpdateUserSuccess,
    mutate: mutateUpdateUser,
  } = useMutateUpdateUser();

  const {
    isLoading: isUpdateOrganisationLoading,
    isError: isUpdateOrganisationError,
    error: updateOrganisationError,
    isSuccess: isUpdateOrganisationSuccess,
    mutate: mutateUpdateOrganisation,
  } = useMutateUpdateOrganisation();

  const onInputUserChange = (event: React.ChangeEvent<EventTargetType>) => {
    if (event.target) {
      const {name, value} = event.target;
      setFormUserValues((prevValues: any) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const onInputOrgChange = (event: React.ChangeEvent<EventTargetType>) => {
    if (event.target) {
      const {name, value} = event.target;
      setFormOrgValues((prevValues: any) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const setUserAvatar = (file: any) => {
    setFormUserValues((prevValues: any) => ({
      ...prevValues,
      avatar: file,
    }));
    setIsUserAvatarChanged(true);
  };

  const setOrgAvatar = (file: any) => {
    setFormOrgValues((prevValues: any) => ({
      ...prevValues,
      avatar: file,
    }));
    setIsOrgAvatarChanged(true);
  };

  const handleSubmitUser = () => {
    const {isFormValid, formErrorMessages, updatedFormValues} = validateForm(
        formUserValues,
        true,
    );
    if (!isFormValid) {
      setUserFormErrorMessages(formErrorMessages);
      return;
    }
    const tmpAvatar: any = formUserValues.avatar;
    if (tmpAvatar instanceof File) {
      updatedFormValues.avatar = formUserValues.avatar;
    }

    if (!isUserAvatarChanged) {
      delete updatedFormValues.avatar;
    }
    mutateUpdateUser(updatedFormValues);
  };

  const handleSubmitOrg = () => {
    const {isFormValid, formErrorMessages, updatedFormValues} = validateForm(
        formOrgValues,
        false,
    );
    if (!isFormValid) {
      setOrgFormErrorMessages(formErrorMessages);
      return;
    }
    const tmpAvatar: any = formOrgValues.avatar;
    if (tmpAvatar instanceof File) {
      updatedFormValues.avatar = formOrgValues.avatar;
    }

    if (!isOrgAvatarChanged) {
      delete updatedFormValues.avatar;
    }
    mutateUpdateOrganisation(updatedFormValues);
  };

  useEffect(() => {
    setFormUserValues((prevValues: any) => ({
      ...prevValues,
      avatar: currentUserData.avatar,
    }));
    setIsUserAvatarChanged(false);
  }, [currentUserData]);

  useEffect(() => {
    setFormOrgValues((prevValues: any) => ({
      ...prevValues,
      avatar: currentUserData.organisation.avatar,
    }));
    setIsOrgAvatarChanged(false);
  }, [currentUserData]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      setSuccsessMessage(true);
      setTimeout(() => {
        setSuccsessMessage(false);
      }, 5000);
      setUserFormErrorMessages({});
      refetchCurrentUser();
    }
    if (isUpdateUserError) {
      console.log(updateUserError);
    }
  }, [isUpdateUserSuccess, isUpdateUserError, updateUserError]);

  useEffect(() => {
    if (isUpdateOrganisationSuccess) {
      setSuccsessMessage(true);
      setTimeout(() => {
        setSuccsessMessage(false);
      }, 5000);
      setOrgFormErrorMessages({});
      refetchCurrentUser();
    }
    if (isUpdateOrganisationError) {
      console.log(updateOrganisationError);
    }
  }, [isUpdateOrganisationSuccess, isUpdateOrganisationError, updateOrganisationError]);

  if (isUpdateUserLoading || isUpdateOrganisationLoading) {
    return (
      <UILoadingIndicatorFixed />
    );
  }

  return (
    <div className="flex flex-col pt-24 md:pt-32">
      <div
        className={`flex w-full justify-between md:justify-start md:space-x-3 md:px-4 
      fixed md:static bg-blue-dark z-20 top-20`}
      >
        <div className="w-1/2 md:w-fit">
          <ButtonIconComponent
            onClick={() => setActiveForm('userForm')}
            variant="white"
            icon={<BiUser />}
            text="Din profil"
            extraStyles={`${
              activeForm === 'userForm' ?
                'md:!text-green-primary md:!bg-green-light border-green-primary' :
                ''
            }`}
          />
        </div>
        <div className="w-1/2 md:w-fit">
          <ButtonIconComponent
            onClick={() => setActiveForm('companyForm')}
            variant="white"
            icon={<RiSuitcaseLine />}
            text="Ditt företag"
            extraStyles={`${
              activeForm === 'companyForm' ?
                'md:!text-green-primary md:!bg-green-light border-green-primary' :
                ''
            }`}
          />
        </div>
      </div>
      <div className="rounded-15px mt-16 md:mt-3 px-4">
        {activeForm === 'userForm' && (
          <div className="userForm">
            <ProfileInfoBarComponent
              userName={formUserValues.full_name}
              userOrg={formOrgValues.company_name}
              userEmail={currentUserData.email}
              userIdNum={formOrgValues.org_no}
              userId={currentUserData?.id}
              userOrgId={currentUserData?.organisation?.id}
              setFile={setUserAvatar}
              fortnoxId={currentUserData.fortnox_id}
              avaliableUpgrades={currentUserData.avaliable_upgrades}
              subscriptionEnrollment={currentUserData.subscription_enrollment}
              memberType={currentUserData.member_type}
              userAvatar={formUserValues.avatar}
              isOrganisation={false}
            />
            <ProfileDetailsForm
              userWorkplace={formUserValues.work_place}
              userCity={formUserValues.city}
              userPostalCode={formUserValues.postal_code}
              userBillingAdress={formUserValues.street}
              userBillingMail={formUserValues.invoice_email}
              userReferences={formUserValues.reference}
              userPassword={formUserValues.password}
              errorMessages={userFormErrorMessages}
              successMessage={successMessage}
              onChange={onInputUserChange}
              onClick={handleSubmitUser}
              organisation={currentUserData.organisation}
            />
          </div>
        )}
        {activeForm === 'companyForm' && (
          <div className="companyForm">
            <ProfileInfoBarComponent
              userName={formOrgValues.company_name}
              userOrg={formOrgValues.company_name}
              userEmail={currentUserData.email}
              userIdNum={formOrgValues.org_no}
              userId={currentUserData?.id}
              userOrgId={currentUserData?.organisation?.id}
              setFile={setOrgAvatar}
              fortnoxId={currentUserData.organisation.fortnox_id}
              avaliableUpgrades={currentUserData.avaliable_company_upgrades}
              subscriptionEnrollment={currentUserData.company_subscription_enrollment}
              memberType={currentUserData.member_type}
              userAvatar={formOrgValues.avatar}
              isOrganisation={true}
              disabled={currentUserData.organisation && !currentUserData.organisation.is_admin}
            />
            <CompanyDetailsForm
              companyEmail={formOrgValues.invoice_email}
              companyCity={formOrgValues.city}
              companyPostalCode={formOrgValues.postal_code}
              companyReference={formOrgValues.reference}
              errorMessages={orgFormErrorMessages}
              onChange={onInputOrgChange}
              onClick={handleSubmitOrg}
              disabled={currentUserData.organisation && !currentUserData.organisation.is_admin}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganisationUserBoxFormComponent;
