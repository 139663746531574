import {authService} from '../api/authService';


export const isLoggedIn = () => {
  const token = authService.getAccessTokenFromLocalStorage();
  if (token != '' && token != null) {
    return true;
  }
  return false;
};
