import {useMutation} from 'react-query';
import actions from '../../api/user/organisation';

const useMutateInviteUserToOrganisation = () => {
  return useMutation(async (email: string) => {
    await actions.inviteUser(email);
  }, {retry: false});
};

export default useMutateInviteUserToOrganisation;
