import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router';
import {useParams} from 'react-router';

import actions from '../../api/events/eventsEnrollment';
import {partnerFormFields} from '../../utils/registerPageForms';
import {EventTargetType} from '../../types/eventTarget';

import RegisterPageFormComponent from '../RegisterPage/RegisterPageFormComponent';
import {isValidEmail, isValidFullName} from '../../utils/isValid';


const RegisterPartnerForm: FC = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [formValues, setFormValues] = useState({
    email: '',
    full_name: '',
    work_place: '',
    event: id,
    partner_code: '',
  });
  const [formErrorMessages, setFormErrorMessages] = useState({
    email: '',
    full_name: '',
    work_place: '',
    partner_code: '',
  });

  const onInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFormErrorMessages((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = () => {
    let hasErrors = false;

    for (const field in formValues) {
      if (!formValues[field as keyof typeof formValues]) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Fältet är obligatoriskt.',
        }));
        hasErrors = true;
      } else if (field === 'email' && !isValidEmail(formValues[field])) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Ogiltig e-postadress.',
        }));
        hasErrors = true;
      } else if (field === 'full_name' && !isValidFullName(formValues[field])) {
        setFormErrorMessages((prevErrors) => ({
          ...prevErrors,
          [field]: 'Fältet ska innehålla namn och efternamn',
        }));
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }

    actions
        .enrollPartner(formValues)
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data === 'Ogiltig partnerkod.') {
            setFormErrorMessages((prevErrors) => ({
              ...prevErrors,
              partner_code: 'Ogiltig partnerkod.',
            }));
          }
          if (error.response.data === 'Hittade inte eventet.') {
            setFormErrorMessages((prevErrors) => ({
              ...prevErrors,
              partner_code: 'Hittade inte eventet.',
            }));
          }
          if (error.response.data === 'Partner är redan registrerad för det evenemanget.') {
            setFormErrorMessages((prevErrors) => ({
              ...prevErrors,
              email: 'Partner är redan registrerad för det evenemanget.',
            }));
          }
          if (error.response.data === 'Vi kunde inte skicka bekräftelsen, kontrollera att e-postadressen är korrekt.') {
            setFormErrorMessages((prevErrors) => ({
              ...prevErrors,
              email: 'Vi kunde inte skicka bekräftelsen, kontrollera att e-postadressen är korrekt.',
            }));
          }
        });
  };
  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      <RegisterPageFormComponent
        stepName='Företagsuppgifter'
        stepOrder={1}
        totalSteps={1}
        onChange={onInputChange}
        onClick={handleSubmit}
        formErrorMessages={formErrorMessages}
        formInputsArray={partnerFormFields}
        keyPrefix='guest-form-'
        isGuest={true}
        isDisabled={false}
      />
    </div>
  );
};

export default RegisterPartnerForm;
