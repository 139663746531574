import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {aboutPageType, awardsType, videosType} from '../types/pages';
import {eventType} from '../types/event';
import {aboutPage} from '../state/pages/pages';
import {currentYearEvents, futureYearEvents} from '../state/events/eventsPaginated';
import useFetchAboutPage from '../hooks/pages/useFetchAboutPage';
import useFetchEvents from '../hooks/events/useFetchEvents';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import HeroComponent from '../components/AboutPage/HeroComponent';
import UITextContentWrapper from '../components/UIWrappers/UITextContentWrapper';
import EventsDatesListComponent from '../components/Events/EventsDatesListComponent';
import StaffSectionComponent from '../components/AboutPage/StaffSectionComponent';
import StatisticsSectionComponent from '../components/AboutPage/StatisticsSectionComponent';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';
import VideoLinkComponent from '../components/AboutPage/VideoLinkComponent';


const AboutPage: FC = () => {
  const {isLoading: isLoadingAboutPage, isError: isErrorAboutPage} = useFetchAboutPage();
  const {isLoading: isLoadingEvents, isError: isErrorEvents} = useFetchEvents();
  const aboutPageData: aboutPageType = useRecoilValue(aboutPage);
  const eventsCurrentData: eventType[] = useRecoilValue(currentYearEvents);
  const eventsFutureData: eventType[] = useRecoilValue(futureYearEvents);

  if (isErrorAboutPage || isErrorEvents) {
    return <UIErrorIndicator message='Ett fel inträffade'/>;
  }

  if (isLoadingAboutPage || isLoadingEvents) {
    return <UILoadingIndicator/>;
  }

  return (
    <UIPageContainer relative={false}>
      <HeroComponent
        title={`Fakta om MiH <br> Mötesplatsen för näringslivet.`}
        image={aboutPageData.hero_image}
        bgColor={aboutPageData.hero_background_color}
        heading='HALLANDS STÖRSTA AFFÄRSNÄTVERK'
      />
      <StatisticsSectionComponent/>
      <UITextContentWrapper
        title='Vad är mih?'
        variant='big'
        heading={aboutPageData.what_is_mih_title}
        isDescription={true}
        description={aboutPageData.what_is_mih_description}
      />
      <UITextContentWrapper
        title='Datum events'
        variant='big'
      >
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full'>
          <EventsDatesListComponent
            year={new Date().getFullYear()}
            subheading=''
            additionalText='För mer information kontakta canita@mih.nu.'
            data={eventsCurrentData}
          />
          <EventsDatesListComponent
            year={new Date().getFullYear() + 1}
            subheading=''
            additionalText='För mer information kontakta canita@mih.nu.'
            data={eventsFutureData}
          />
        </div>
      </UITextContentWrapper>
      <UITextContentWrapper
        title='årets hallänning'
        variant='big'
        heading={aboutPageData.hall_of_fame_title}
        isDescription={true}
        description={aboutPageData.hall_of_fame_description}
      >
        {aboutPageData && aboutPageData.awards.map((el: awardsType, index: number) => (
          <UITextContentWrapper
            key={index}
            title={el.year}
            variant='small'
            heading={el.full_name}
            isDescription={true}
            description={el.description}
            containerOneWidth='w-1/5'
            containerTwoWidth='w-4/5'
            isBorder={true}
          />
        ))}
      </UITextContentWrapper>
      <UITextContentWrapper
        title='filmer'
        variant='big'
        heading={aboutPageData.videos_title}
        isDescription={true}
        description={aboutPageData.videos_description}
      >
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          {aboutPageData && aboutPageData.videos.length > 0 &&
          aboutPageData.videos.map((el: videosType, index: number) => (
            <VideoLinkComponent
              key={index}
              title={el.title}
              videoUrl={el.youtube_link}
            />
          ))}
        </div>
      </UITextContentWrapper>
      <StaffSectionComponent/>
    </UIPageContainer>
  );
};

export default AboutPage;
