import {useMutation} from 'react-query';
import actions from '../../api/subscriptions/subscriptions';

const useMutateCreateSubscription = () => {
  return useMutation(async (data: any) => {
    await actions.createSubscription(data);
  }, {retry: false});
};

export default useMutateCreateSubscription;
