import React, {FC} from 'react';
import {eventType} from '../../types/event';
import UICardComponent from '../UIWrappers/UICardComponent';


type EventsDatesListComponentTypes = {
  year: number;
  subheading: string;
  additionalText: string;
  data: eventType[];
}

const EventsDatesListComponent: FC<EventsDatesListComponentTypes> = ({
  year,
  subheading,
  additionalText,
  data,
}) => {
  return (
    <UICardComponent width='w-full' padding='px-9 py-5'>
      <div className='flex flex-col w-full h-full'>
        <div className='text-black-dark font-semibold'>
          <h2 className='text-38px mb-3'>
            {year}
          </h2>
          <h3 className='text-lg'>
            Frukostklubben:
          </h3>
        </div>
        <div className='w-fit mt-6'>
          <ul className='flex flex-col gap-x-20 flex-wrap list-none max-h-[124px] text-black-dark/60 font-medium'>
            {data && data.map((el: eventType, index: number) => {
              const formattedDate = (new Date(el.start_time)).toLocaleDateString('sv-SE', {
                day: 'numeric',
                month: 'long',
              });
              return (
                <li key={index}>
                  {formattedDate}
                </li>
              );
            })}
          </ul>
        </div>
        <div className='mt-auto'>
          <span className='text-black-dark font-bold text-base'>
            {subheading}
          </span>
          <div className='text-base font-normal text-black-dark/60 mt-4 opacity-50'>
            {additionalText}
          </div>
        </div>
      </div>
    </UICardComponent>

  );
};

export default EventsDatesListComponent;
