import React, {FC, useState} from 'react';

import contact from '../../../../api/contact';
import {contactFormInputs} from '../../../../utils/contactFormInputs';
import {EventTargetType} from '../../../../types/eventTarget';

import {inputType} from '../../../../utils/contactFormInputs';
import {isValidEmail} from '../../../../utils/isValid';

import UICardComponent from '../../../UIWrappers/UICardComponent';
import UIInput from '../../../UICommon/UIInput';
import UIButtonCommon from '../../../UIButtons/UIButtonCommon';
import UICheckbox from '../../../UICommon/UICheckbox';
import UIDropdownSelect from '../../../UICommon/UIDropdownSelect';

type ContactFormComponentProps = {
  heading: string;
  description: string;
}

const ContactFormComponent: FC<ContactFormComponentProps> = ({
  heading,
  description,
}) => {
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isPostSuccess, setIsPostSuccess] = useState(false);
  const [isPostError, setIsPostError] = useState(false);
  type FormValuesType = {
    sender_email: string;
    phone_number: string;
    contact_person: string;
    company_name: string;
    type_of_intership: string;
    city: string;
    message: string;
    agreement: string;
    [key: string]: string;
  };
  const [formValues, setFormValues] = useState<FormValuesType>({
    sender_email: '',
    phone_number: '',
    contact_person: '',
    company_name: '',
    type_of_intership: '',
    city: '',
    message: '',
    agreement: '',
  });
  const [requiredFieldsError, setRequiredFieldsError] = useState<Record<keyof FormValuesType, boolean>>({
    sender_email: false,
    phone_number: false,
    contact_person: false,
    company_name: false,
    type_of_intership: false,
    city: false,
    message: false,
    agreement: false,
  });
  const [formErrorMessages] = useState({
    sender_email: '',
    phone_number: '',
    company_name: '',
    type_of_intership: '',
    city: '',
    message: '',
    agreement: '',
  });

  const resetForm = () => {
    setFormValues({
      sender_email: '',
      phone_number: '',
      contact_person: '',
      company_name: '',
      type_of_intership: '',
      city: '',
      message: '',
      agreement: '',
    });
    setIsConsentChecked((prevIsConsentChecked) => !prevIsConsentChecked);
  };

  const onInputChange = (event: React.ChangeEvent<EventTargetType>) => {
    const {name, value} = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setRequiredFieldsError((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleConsentClick = () => {
    setIsConsentChecked((prevIsConsentChecked) => !prevIsConsentChecked);
    setRequiredFieldsError((prevErrors) => ({
      ...prevErrors,
      agreement: false,
    }));
  };

  const handleSubmit = () => {
    const requiredFields: (keyof FormValuesType)[] = [
      'sender_email',
      'contact_person',
      'phone_number',
      'company_name',
      'type_of_intership',
      'city',
      'agreement',
    ];

    const requiredFieldsErrors: Record<string, boolean> = {};
    let hasError = false;

    requiredFields.forEach((fieldName) => {
      if (fieldName === 'agreement') {
        requiredFieldsErrors[fieldName] = isConsentChecked ? false : true;
      } else if (fieldName === 'sender_email') {
        requiredFieldsErrors[fieldName] = isValidEmail(formValues[fieldName]) ? false : true;
      } else {
        requiredFieldsErrors[fieldName] = formValues[fieldName].trim() === '' ? true : false;
      }
      if (requiredFieldsErrors[fieldName]) {
        hasError = true;
      }
    });

    setRequiredFieldsError(requiredFieldsErrors);

    if (hasError || isConsentChecked == false) {
      console.log('Please fill in all required fields.');
      return;
    } else {
      setRequiredFieldsError({
        sender_email: false,
        phone_number: false,
        contact_person: false,
        company_name: false,
        type_of_intership: false,
        city: false,
        message: false,
        agreement: false,
      });
      contact.postContactForm(formValues)
          .then((res) => {
            setIsPostSuccess(true);
            resetForm();
            setTimeout(() => {
              setIsPostSuccess(false);
            }, 10000);
          })
          .catch((error) => {
            const errors: any = error.response.data;
            console.log('Error: ', errors);
            setIsPostError(true);
          });
    }
  };

  return (
    <UICardComponent width='w-full' padding='lg:pl-40 lg:pr-24 lg:py-24' backgroundColor='bg-transparent lg:bg-white'>
      <form className='flex flex-col manrope w-full'>
        <h2 className='font-semibold text-27px lg:text-43px text-black-normal '>
          {heading}
        </h2>
        <div className='text-pink-primary font-medium text-base lg:text-22px mt-2 '
          dangerouslySetInnerHTML={{__html: description}}/>
        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-9 mt-10'>
          {contactFormInputs && contactFormInputs.filter((el: inputType) => el.type != 'textarea',
          ).map((el: inputType, idx: number) => {
            const name = el.name as keyof typeof formErrorMessages;
            if (el.name === 'type_of_intership') {
              return (
                <UIDropdownSelect
                  key={idx}
                  id={el.id}
                  label={el.label}
                  name={el.name}
                  placeholder={el.placeholder}
                  onChange={onInputChange}
                  variant={'allInclusiveCF'}
                  values={[
                    'Praktik',
                    'APL/Lärlingsplats',
                    'Vet ej/Vill gärna veta mer',
                  ]}
                  formErrorMessage={requiredFieldsError[name] ? `${el.label} är obligatoriskt` : undefined}
                />
              );
            } else {
              return (
                <UIInput
                  key={idx}
                  id={el.id}
                  type={el.type}
                  label={el.label}
                  name={el.name}
                  value={formValues[el.name]}
                  placeholder={el.placeholder}
                  onChange={onInputChange}
                  formErrorMessage={requiredFieldsError[name] ? `${el.label} är obligatoriskt` : undefined}
                  variant='pink'
                />
              );
            }
          })}
        </div>
        <div className='w-full grid grid-cols-1 mt-9'>
          {contactFormInputs && contactFormInputs.filter((el: inputType) => el.type == 'textarea',
          ).map((el: inputType, idx: number) => {
            const name = el.name as keyof typeof formErrorMessages;
            return (
              <UIInput
                key={idx}
                id={el.id}
                type={el.type}
                label={el.label}
                name={el.name}
                value={formValues[el.name]}
                placeholder={el.placeholder}
                onChange={onInputChange}
                formErrorMessage={requiredFieldsError[name] ? `${el.label} är obligatoriskt` : undefined}
                variant='pink'
              />
            );
          })}
        </div>
        <div className='mt-12'>
          <UICheckbox
            onChange={() => handleConsentClick()}
            label={'Jag godkänner Marknadsföreningen i Hallands'}
            name={'agreement'}
            variant='allInclusiveCF'
            link='integritetspolicy'
            checked={isConsentChecked}
            formErrorMessage={requiredFieldsError['agreement'] ? `Avtal är obligatoriskt` : undefined}
          />
        </div>
        <div className='mt-14'>
          <UIButtonCommon
            buttonTitle='SKICKA'
            variant='pink'
            onClick={handleSubmit}
            width='w-full'
          />
        </div>
        {isPostSuccess && (
          <div className=' bg-green-primary px-3 py-2 rounded-full w-fit text-white font-medium text-sm mt-9'>
            Tack! Vi har tagit emot din intresseanmälan!
          </div>
        )}
        {isPostError && (
          <div className=' bg-red-500 px-3 py-2 rounded-full w-fit text-white font-medium text-sm mt-9'>
            Vi har problem med att skicka ditt meddelande
          </div>
        )}
      </form>
    </UICardComponent>
  );
};

export default ContactFormComponent;
