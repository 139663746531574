import {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import {FiMapPin} from 'react-icons/fi';


type UIEventCardProps = {
  isImage: boolean,
  event: any,
};

const UIEventCard: FC<UIEventCardProps> = ({
  isImage,
  event: el,
}) => {
  const dateObj = new Date(el.start_time);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('default', {month: 'long'});
  return (
    <a href={`/events/${el.id}`}>
      <UICardComponent width='w-full' padding=''>
        {isImage ?
          <img
            className='hidden lg:block rounded-t-15px w-full object-cover h-[170px]'
            src={el.image}
            alt={el.title}
          /> :
          <></>
        }
        <div className='flex flex-col px-5 py-4 inter'>
          <span className='text-base text-black-dark/40'>{`${day} ${month}`}</span>
          <h2 className='text-gray-black text-lg font-medium'>{el.title}</h2>
          <div className='flex items-center text-base'>
            <FiMapPin className='text-green-primary mr-3' />
            <span className='text-gray-black'>{el.address}</span>
          </div>
        </div>
      </UICardComponent>
    </a>
  );
};

export default UIEventCard;
