import React, {FC} from 'react';
import {parseHtml} from '../../../utils/parseHtml';

import UICardComponent from '../../UIWrappers/UICardComponent';
import UIButtonCommon from '../../UIButtons/UIButtonCommon';
import UITag from '../../UICommon/UITag';


type ExpandedNewsComponentProps = {
  id: number | string;
  image: string;
  title: string;
  byline: string;
  tag: string;
  description: string;
  buttonFunction: () => void;
}


const ExpandedNewsComponent:FC<ExpandedNewsComponentProps> = ({
  id,
  image,
  title,
  byline,
  tag,
  description,
  buttonFunction,
}) => {
  const parsedByline = parseHtml(byline);
  return (
    <UICardComponent id={`news-${id}`} width='w-full' padding='pl-8 pr-5 pt-6 pb-10' extraStyles='relative'>
      <UIButtonCommon
        buttonTitle='Stäng'
        buttonType='button'
        onClick={() => buttonFunction()}
        variant='blank'
        extraStyles='absolute right-5 top-5 underline'
      />
      <div className='flex gap-x-16'>
        <div className='w-3/5 flex flex-col'>
          <UITag name={tag} extraStyles='w-fit'/>
          <h2 className='mt-6 text-32px font-semibold pb-5 text-black-dark'>
            {title}
          </h2>
          <div className='text-black-dark text-lg font-normal pb-7'>
            {parsedByline}
          </div>
          <div className='font-normal text-base text-black-dark/70 leading-relaxed content'
            dangerouslySetInnerHTML={{__html: description}}>
          </div>
        </div>
        <div className='w-full h-auto pt-16'>
          <img className='w-full h-full object-cover rounded-15px' src={image} alt="image" />
        </div>
      </div>
    </UICardComponent>
  );
};

export default ExpandedNewsComponent;

