import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/partners/partners';
import {partnersPaginated} from '../../state/partners/partnersPaginated';

const useFetchPartners = () => {
  const setPartners = useSetRecoilState(partnersPaginated);

  return useQuery('useFetchPartners', async () => {
    const partners = await actions.fetchPartners();
    setPartners(partners);
    return partners;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchPartners;
