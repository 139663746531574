import React, {FC} from 'react';
import {useNavigate} from 'react-router';
import {useSetRecoilState} from 'recoil';

import {archiveIsGdpr} from '../../state/pages/pages';
import {UICheckboxVariants} from '../../variants/UICheckboxVariants';

type UICheckboxTypes = {
  label: string;
  name: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: keyof typeof UICheckboxVariants;
  link?: string;
  formErrorMessage?: string | undefined;
};

const UICheckbox: FC<UICheckboxTypes> = ({
  label,
  name,
  onChange,
  checked,
  variant='login',
  link='',
  formErrorMessage='',
}) => {
  const setCurrentIndex = useSetRecoilState(archiveIsGdpr);
  const navigate = useNavigate();

  const handleClick = () => {
    setCurrentIndex(true);
    navigate('/archive');
  };
  const variantClasses = {
    [UICheckboxVariants.login]: `form-control`,
    [UICheckboxVariants.allInclusiveCF]: `allInc-form poppins font-normal text-11px`,
  };
  const variantLinkClasses = {
    [UICheckboxVariants.login]: ``,
    [UICheckboxVariants.allInclusiveCF]: `text-pink-primary poppins font-normal text-11px`,
  };
  return (
    <>
      <div className='flex items-center'>
        <label className={`${variantClasses[variant]}`} htmlFor="">
          <input onChange={onChange} id={name} type="checkbox" checked={checked}/>
          <span className='text-black-body font-normal text-sm ml-2 mb-1'>
            {label}
          </span>
          {link && (
            <div onClick={() => handleClick()} className={variantLinkClasses[variant]}>
              {link}
            </div>
          )}
        </label>
      </div>
      {formErrorMessage && (
        <small className="text-red-600 font-semibold text-xs mt-1">
          {formErrorMessage}
        </small>
      )}
    </>

  );
};

export default UICheckbox;
