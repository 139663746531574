import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {eventsArchiveTitle, periodedEvents} from '../state/events/eventsPaginated';
import useFetchEvents from '../hooks/events/useFetchEvents';

import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextContentWrapper from '../components/UIWrappers/UITextContentWrapper';
import EventsArchiveSectionComponent from '../components/EventsArchivePage/EventsArchiveSectionComponent';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';


const EventsArchivePage: FC = () => {
  const {isLoading: isLoadingEvents, isError: isErrorEvents} = useFetchEvents();
  const eventsData = useRecoilValue(periodedEvents);
  const eventsTitle = useRecoilValue(eventsArchiveTitle);

  if (isErrorEvents) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };

  if (isLoadingEvents ) {
    return (
      <UILoadingIndicator/>
    );
  };

  return (
    <UIPageContainer>
      <div className='mt-80'>
        <UITextContentWrapper
          title={eventsTitle.title}
          variant='event'
          containerOneWidth='full'
          containerTwoWidth='w-full lg:w-1/4'
          isDescription={true}
          description={eventsTitle.description}
        />
      </div>
      <EventsArchiveSectionComponent
        data={eventsData}
      />
    </UIPageContainer>
  );
};

export default EventsArchivePage;
