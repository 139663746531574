export const companyFormFields = [
  {
    id: 'org_no_company',
    type: 'text',
    label: 'Organisationsnummer',
    name: 'org_no',
  },
  {
    id: 'company_name_company',
    type: 'text',
    label: 'Företagsnamn',
    name: 'company_name',
  },
  {
    id: 'invoice_email_company',
    type: 'text',
    label: 'Faktureringsmail',
    name: 'invoice_email',
  },
  {
    id: 'reference_company',
    type: 'text',
    label: 'Referens (valfritt)',
    name: 'reference',
  },
  {
    id: 'street_company',
    type: 'text',
    label: 'Faktureringsaddress',
    name: 'street',
  },
  {
    id: 'city_company',
    type: 'text',
    label: 'Stad',
    name: 'city',
  },
  {
    id: 'postal_code_company',
    type: 'text',
    label: 'Postnummer',
    name: 'postal_code',
  },
];


export const publicFormFields = [
  {
    id: 'org_no_public',
    type: 'text',
    label: 'Organisationsnummer',
    name: 'org_no',
  },
  {
    id: 'company_name_public',
    type: 'text',
    label: 'Företagsnamn',
    name: 'company_name',
  },
  {
    id: 'invoice_email_public',
    type: 'text',
    label: 'Faktureringsmail',
    name: 'invoice_email',
  },
  {
    id: 'street_public',
    type: 'text',
    label: 'Faktureringsaddress',
    name: 'street',
  },
  {
    id: 'city_public',
    type: 'text',
    label: 'Stad',
    name: 'city',
  },
  {
    id: 'postal_code_public',
    type: 'number',
    label: 'Postnummer',
    name: 'postal_code',
  },
];

export const userCompanyFormFields = [
  {
    id: 'postal_code_user',
    type: 'text',
    label: 'För- och efternamn',
    name: 'full_name',
  },
  {
    id: 'work_place_user',
    type: 'text',
    label: 'Arbetsplats',
    name: 'work_place',
  },
  {
    id: 'invoice_email_user',
    type: 'text',
    label: 'Faktureringsmail',
    name: 'invoice_email',
  },
  {
    id: 'reference_user',
    type: 'text',
    label: 'Referens (valfritt)',
    name: 'reference',
  },
  {
    id: 'email_user',
    type: 'text',
    label: 'Email',
    name: 'email',
  },
  {
    id: 'password_user',
    type: 'password',
    label: 'Lösenord - minst 6 tecken',
    name: 'password',
  },
];

export const userFormFields = [
  {
    id: 'postal_code_user',
    type: 'text',
    label: 'För- och efternamn',
    name: 'full_name',
  },
  {
    id: 'work_place_user',
    type: 'text',
    label: 'Arbetsplats',
    name: 'work_place',
  },
  {
    id: 'invoice_email_user',
    type: 'text',
    label: 'Faktureringsmail',
    name: 'invoice_email',
  },
  {
    id: 'street_user',
    type: 'text',
    label: 'Faktureringsaddress',
    name: 'street',
  },
  {
    id: 'city_user',
    type: 'text',
    label: 'Stad',
    name: 'city',
  },
  {
    id: 'postal_code_user',
    type: 'text',
    label: 'Postnummer',
    name: 'postal_code',
  },
  {
    id: 'reference_user',
    type: 'text',
    label: 'Referens (valfritt)',
    name: 'reference',
  },
  {
    id: 'email_user',
    type: 'text',
    label: 'Email',
    name: 'email',
  },
  {
    id: 'password_user',
    type: 'password',
    label: 'Lösenord - minst 6 tecken',
    name: 'password',
  },
];

export const guestFormFields = [
  {
    id: 'email_guest',
    type: 'text',
    label: 'E-postadress',
    name: 'email',
  },
  {
    id: 'password_guest',
    type: 'password',
    label: 'Lösenord',
    name: 'password',
  },
  {
    id: 'full_name_guest',
    type: 'text',
    label: 'Fullständiga namn',
    name: 'full_name',
  },
  {
    id: 'work_place_guest',
    type: 'text',
    label: 'Arbetsplats',
    name: 'work_place',
  },
];

export const partnerFormFields = [
  {
    id: 'full_name_partner',
    type: 'text',
    label: 'Fullständiga namn',
    name: 'full_name',
  },
  {
    id: 'work_place_partner',
    type: 'text',
    label: 'Arbetsplats',
    name: 'work_place',
  },
  {
    id: 'email_partner',
    type: 'text',
    label: 'E-postadress',
    name: 'email',
  },
  {
    id: 'code_partner',
    type: 'text',
    label: 'Anmälningskod',
    name: 'partner_code',
  },
];


export const invitedUserFormFields = [
  {
    id: 'postal_code_user',
    type: 'text',
    label: 'För- och efternamn',
    name: 'full_name',
  },
  {
    id: 'work_place_user',
    type: 'text',
    label: 'Arbetsplats',
    name: 'work_place',
  },
  {
    id: 'invoice_email_user',
    type: 'text',
    label: 'Faktureringsmail',
    name: 'invoice_email',
  },
  {
    id: 'reference_user',
    type: 'text',
    label: 'Referens (valfritt)',
    name: 'reference',
  },
  {
    id: 'email_user',
    type: 'text',
    label: 'Email',
    name: 'email',
  },
  {
    id: 'password_user',
    type: 'password',
    label: 'Lösenord',
    name: 'password',
  },
  {
    id: 'street_user',
    type: 'text',
    label: 'Gata',
    name: 'street',
  },
  {
    id: 'city_user',
    type: 'text',
    label: 'Stad',
    name: 'city',
  },
  {
    id: 'postal_code_user',
    type: 'text',
    label: 'Postnummer',
    name: 'postal_code',
  },
];


