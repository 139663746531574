export const contactFormInputs = [
  {
    id: 'contact_person_id',
    type: 'text',
    label: 'Kontaktperson*',
    name: 'contact_person',
    placeholder: 'Fyll i namn på kontaktperson',
  },
  {
    id: 'phone_number_id',
    type: 'text',
    label: 'Telefon*',
    name: 'phone_number',
    placeholder: 'Telefonnummer till kontaktperson',
  },
  {
    id: 'company_name_id',
    type: 'text',
    label: 'Företagsnamn*',
    name: 'company_name',
    placeholder: 'Fyll företagsnamn',
  },
  {
    id: 'type_of_intership_id',
    type: 'text',
    label: 'Typ av plats*',
    name: 'type_of_intership',
    placeholder: 'Klicka för att välja',
  },
  {
    id: 'sender_email_id',
    type: 'text',
    label: 'E-post*',
    name: 'sender_email',
    placeholder: 'Fyll i e-post',
  },
  {
    id: 'city_id',
    type: 'text',
    label: 'Stad*',
    name: 'city',
    placeholder: 'Fyll i stad',
  },
  {
    id: 'message_id',
    type: 'textarea',
    label: 'Meddelande',
    name: 'message',
    placeholder: 'Övrig information eller frågor',
  },
];

export type inputType = {
    id: string;
    type: string;
    label: string;
    name: string;
    placeholder: string;
}
