const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;


export const isValidEmail = function(email: string) {
  return emailRegex.test(email);
};

export const isValidPassword = function(password: string) {
  return passwordRegex.test(password);
};

export const isValidFullName = function(fullName: string) {
  const words: string[] = fullName.trim().split(/\s+/);
  return words.length >= 2;
};
