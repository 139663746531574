import React, {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import useFetchNewsletter from '../../hooks/pages/useFetchNewsletter';
import {newsletterData} from '../../state/pages/pages';

import UIEnrollmentNewsletterForm from '../UIForms/UIEnrollmentNewsletterForm';

import logoImg from '../../assets/mih-logo-footer.png';

type AppLayoutFooterLinks = {
  url: string;
  title: string;
}

const footerSocialLinks : AppLayoutFooterLinks[] = [
  {
    url: 'https://www.facebook.com/mihalland?fref=ts',
    title: 'Facebook',
  },
  {
    url: 'https://www.linkedin.com/company/mihalland/',
    title: 'LinkedIn',
  },
  {
    url: 'https://www.instagram.com/mih.nu/',
    title: 'Instagram',
  },
];

const footerLinks: AppLayoutFooterLinks[] = [
  {
    url: '/',
    title: 'HEM',
  },
  {
    url: '/about/',
    title: 'OM MIH',
  },
  {
    url: '/partners/',
    title: 'Bli Partner',
  },
];

const isLoggedInHiddenLinks= [
  {
    url: '/login/',
  },
  {
    url: '/forgot-password/',
  },
];

const AppLyoutFooter: FC = () => {
  const location = useLocation();
  const {isLoading} = useFetchNewsletter();
  const newsletter = useRecoilValue(newsletterData);
  const menuIsHidden = isLoggedInHiddenLinks.some((link) => {
    return location.pathname.startsWith(link.url) || location.pathname.startsWith('/reset-password/');
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={`flex flex-col pb-2 pt-16 ${menuIsHidden && 'hidden'}`}>
      <div className='flex flex-col space-y-8 lg:space-y-0 lg:flex-row w-full manrope mb-12'>
        <div className='block lg:hidden'>
          <h2 className={`mb-3 lg:mb-12 text-black-dark lg:text-gray-common uppercase ` +
          `text-lg font-bold h-fit lg:h-[51px] flex items-center`
          }>
            {newsletter?.title ? newsletter.title : 'Prenumerera'}
          </h2>
          <UIEnrollmentNewsletterForm
            buttonText={newsletter?.cta_button ? newsletter.cta_button : 'Prenumerera'}
            extraButtonWrapperStyles='mt-4 lg:mt-0'
          />
        </div>
        <div className='flex flex-col w-full lg:w-1/6'>
          <img className='mb-4 lg:mb-12 w-[106px] h-auto' src={logoImg} alt='image'/>
          <ul className='space-x-3 lg:space-x-0 lg:space-y-3 flex lg:flex-col'>
            {footerSocialLinks.map((footerSocialLinks: AppLayoutFooterLinks, index: number) => {
              return (
                <li
                  key={index}
                  className={`bg-white px-2 py-1 rounded-md text-black-dark w-fit text-sm font-medium
                  hover:bg-green-primary hover:text-white transition-colors ease-in-out duration-150
                  `}
                >
                  <a href={footerSocialLinks.url} target="_blank" rel="noreferrer">
                    {footerSocialLinks.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='flex flex-col w-full lg:w-1/6'>
          <h2 className={`mb-12 text-black-dark lg:text-gray-common uppercase ` +
          `text-lg font-bold h-fit lg:h-[51px] flex items-center`
          }>
            Hemsida
          </h2>
          <ul className='space-y-3 nav flex flex-col'>
            {footerLinks.map((footerLinks: AppLayoutFooterLinks, index: number) => {
              return (
                <li
                  key={index}
                  className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase w-fit'
                >
                  <Link to={footerLinks.url}>
                    <span>{footerLinks.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='flex flex-col w-full lg:w-1/5'>
          <h2 className={`mb-12 text-black-dark lg:text-gray-common uppercase ` +
          `text-lg font-bold h-fit lg:h-[51px] flex items-center`
          }>
            MiH
          </h2>
          <ul className='space-y-3'>
            <li
              className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase'
            >
              Org.nr 802457-5915
            </li>
            <li
              className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase'
            >
              Swish: 123 448 93 24
            </li>
            <li
              className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase'
            >
              Växjövägen 11, 302 44 Halmstad
            </li>
          </ul>
        </div>
        <div className='flex flex-col w-full lg:w-1/6'>
          <h2 className={`mb-12 text-black-dark lg:text-gray-common uppercase ` +
          `text-lg font-bold h-fit lg:h-[51px] flex items-center`
          }>
            KONTAKT
          </h2>
          <ul className='space-y-3'>
            <li>
              <a href="tel:0704-670125"
                className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase'>
                  0704-670125
              </a>
            </li>
            <li>
              <a href="mailto:CANITA@MIH.NU"
                className='text-sm font-semibold text-gray-common lg:text-black-dark py-1 uppercase'>
                  CANITA@MIH.NU
              </a>
            </li>
          </ul>
        </div>
        <div className='hidden lg:flex flex-col w-fit lg:w-2/5'>
          <h2 className={`mb-12 text-black-dark lg:text-gray-common uppercase ` +
          `text-lg font-bold h-fit lg:h-[51px] flex items-center`
          }>
            {newsletter?.title ? newsletter.title : 'Prenumerera'}
          </h2>
          <UIEnrollmentNewsletterForm buttonText={newsletter?.cta_button ? newsletter.cta_button : 'Prenumerera'}/>
        </div>
      </div>
      <span className='w-full text-center text-xs text-gray-common'>
        © Kopieringsrätt {new Date().getFullYear()}, Marknadsföreningen i Halland.
      </span>
    </div>
  );
};

export default AppLyoutFooter;
