import React, {FC, useEffect} from 'react';

import {pagesType} from '../../types/pages';

import UICardComponent from './UICardComponent';
import UIDropdownButton from '../UIButtons/UIDropdownButton';
import UIPaginationButton from '../UIButtons/UIPaginationButton';

type UIPaginatedTextWrapperTypes = {
  data: pagesType[];
  currentIndex: number;
  setCurrentIndex: Function;
  archiveIsGdpr?: boolean;
}

const UIPaginatedTextWrapper: FC<UIPaginatedTextWrapperTypes> = ({
  data, currentIndex, setCurrentIndex, archiveIsGdpr,
}) => {
  useEffect(() => {
    if (archiveIsGdpr) {
      const gdprIndex = data.findIndex((item) => item.title === 'GDPR');
      setCurrentIndex(gdprIndex);
    };
  }, [archiveIsGdpr]);

  const handleScrollTo = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleDropdownChange = (value: string) => {
    const selectedIndex = data.findIndex((item) => item.title === value);
    setCurrentIndex(selectedIndex);
  };

  return (
    <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 w-full'>
      <div className='w-full lg:w-1/3 relative'>
        <div className='hidden lg:block sticky top-32'>
          <UICardComponent
            width='w-full'
            padding='pl-8 xl:pl-16 pr-7 py-6'
          >
            <div className='flex flex-col space-y-2 items-start'>
              {data && data.length > 0 &&
                data
                    .map((el: pagesType, index: number) => (
                      <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`${index === currentIndex ? 'active' : ''} text-black-dark/60 sideBarItem`}
                      >
                        {el.title}
                      </button>
                    ))}
            </div>
          </UICardComponent>
        </div>
        <div className='block lg:hidden'>
          <UIDropdownButton
            variant='white'
            values={data}
            dropdownVariant='commonDropdown'
            selectedOption={data[currentIndex].title}
            onValueChange={handleDropdownChange}
          />
        </div>
      </div>
      <div className='w-full lg:w-2/3'>
        <UICardComponent
          width='w-full'
          padding='px-6 xl:px-16 pt-6 xl:pt-[58px] pb-10'
        >
          <div className='flex flex-col w-full text-black-dark'>
            <h2 className=' text-2xl md:text-33px font-bold mb-7'>
              {data[currentIndex].title}
            </h2>
            <div
              dangerouslySetInnerHTML={{__html: data[currentIndex].description}}
              className='text-lg text-wrapper'
            />
            <div className={`${currentIndex === 0 && currentIndex !== data.length ? 'justify-end' : 'justify-between'}
            w-full hidden lg:flex lg:flex-row mt-11`}>
              {currentIndex != 0 && (
                <UIPaginationButton
                  value={data[currentIndex - 1].title}
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                  variant='previous'
                />
              )}
              {currentIndex != data.length - 1 && (
                <UIPaginationButton
                  value={data[currentIndex + 1].title}
                  onClick={() => setCurrentIndex(currentIndex + 1)}
                  variant='next'
                />
              )}
            </div>
          </div>
        </UICardComponent>
        <div className='w-full flex lg:hidden flex-col items-center justify-between mt-4 space-y-4'>
          {currentIndex != 0 && (
            <UIPaginationButton
              value={data[currentIndex - 1].title}
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
                handleScrollTo();
              }}
              variant='previous'
            />
          )}
          {currentIndex != data.length - 1 && (
            <UIPaginationButton
              value={data[currentIndex + 1].title}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
                handleScrollTo();
              }}
              variant='next'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UIPaginatedTextWrapper;

