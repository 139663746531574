import React, {FC} from 'react';

type UICardComponentProps = {
  width: string;
  padding: string;
  children: JSX.Element|JSX.Element[];
  id?: number | string;
  backgroundColor?: string;
  extraStyles?: string;
}

const UICardComponent: FC<UICardComponentProps> = ({
  children,
  width,
  padding,
  id='',
  backgroundColor='bg-white',
  extraStyles,
}) => {
  return (
    <div id={`${id}`}className={`flex flex-col rounded-15px h-full 
    ${width} ${padding} ${backgroundColor} ${extraStyles ? extraStyles : ''}`
    }>
      {children}
    </div>
  );
};

export default UICardComponent;
