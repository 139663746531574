import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/events/event';
import {singleEvent} from '../../state/events/eventsPaginated';

const useFetchSingleEvent = (id: number) => {
  const setEvent = useSetRecoilState(singleEvent);

  return useQuery('useFetchSingleEvent', async () => {
    const event = await actions.fetchSingleEvent(id);
    setEvent(event);
    return event;
  }, {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

export default useFetchSingleEvent;
