import React, {FC} from 'react';
import {UIInputVariants} from '../../variants/UIInputVariants';

type UIInputTypes = {
  id: string;
  type: string;
  label: string;
  name: string;
  disabled?: boolean;
  value?: any;
  formErrorMessage: string | undefined;
  onChange?: Function;
  variant?: keyof typeof UIInputVariants;
  placeholder?: string;
};

const UIInput: FC<UIInputTypes> = ({
  id,
  type,
  label,
  name,
  disabled = false,
  value,
  formErrorMessage,
  onChange,
  variant = 'common',
  placeholder = '',
}) => {
  const variantTextareaClasses = {
    [UIInputVariants.common]: ``,
    [UIInputVariants.green]: ``,
    [UIInputVariants.pink]: `w-full text-gray-allinc-input font-normal text-lg border-b border-b-black-body 
    border-dashed outline-none bg-transparent`,
  };
  const variantContainerClasses = {
    [UIInputVariants.common]: `w-full border-b-black-dark`,
    [UIInputVariants.green]: `w-full border-b-black-dark`,
    [UIInputVariants.pink]: `w-full`,
  };
  const variantClasses = {
    [UIInputVariants.common]: `w-full lg:w-80 border border-gray-deepLight rounded-[5px] py-2 px-3 
    text-black-body font-semibold`,
    [UIInputVariants.green]: `w-full lg:w-80 bg-green-light rounded-[5px] py-2 px-3 
    text-black-body font-medium`,
    [UIInputVariants.pink]: `w-full text-gray-allinc-input font-normal text-lg border-b border-b-black-body 
    border-dashed pb-2 bg-transparent`,
  };
  const variantLabelClasses = {
    [UIInputVariants.common]: `mb-2`,
    [UIInputVariants.green]: `mb-2`,
    [UIInputVariants.pink]: `text-pink-dark mb-5`,
  };
  return (
    <>
      <div className={`flex flex-col ${variantContainerClasses[variant]}`}>
        <label className={`text-sm font-semibold ${variantLabelClasses[variant]}`} htmlFor={id}>
          {label}
        </label>
        <>
          {type == 'textarea' ? (
            <textarea
              id={id}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={(event) => onChange && onChange(event)}
              className={`${variantTextareaClasses[variant]}`}
              disabled={disabled}
            >
            </textarea>
          ) : (
            <input
              id={id}
              name={name}
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={(event) => onChange && onChange(event)}
              className={`${variantClasses[variant]}`}
              disabled={disabled}
            />
          )}
        </>
        {formErrorMessage && (
          <small className="text-red-600 font-semibold text-xs mt-1">
            {formErrorMessage}
          </small>
        )}
      </div>
    </>
  );
};

export default UIInput;
