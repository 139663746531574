import axios from 'axios';

const URL = process.env.REACT_APP_API_URI + 'newsletter/';

const subscribeNewsletter = async (email: string) => {
  const response = await axios.post(URL, {email: email});
  return response.data;
};

const actions = {
  subscribeNewsletter,
};

export default actions;
