import axios from 'axios';

const PAGES_URL = process.env.REACT_APP_API_URI + 'pages/';
const ABOUT_PAGE_URL = process.env.REACT_APP_API_URI + 'pages/about/';
const NEWSLETTER_PAGE_URL = process.env.REACT_APP_API_URI + 'pages/newsletter/';
const ALL_INCLUSIVE_PAGE_URL = process.env.REACT_APP_API_URI + 'pages/all-inclusive/';

const fetchPages = async () => {
  const response = await axios.get(PAGES_URL);
  return response.data;
};

const fetchAboutPage = async () => {
  const response = await axios.get(ABOUT_PAGE_URL);
  return response.data;
};

const fetchNewsletterData = async () => {
  const response = await axios.get(NEWSLETTER_PAGE_URL);
  return response.data;
};

const fetchAllInclusive = async () => {
  const response = await axios.get(ALL_INCLUSIVE_PAGE_URL);
  return response.data;
};


const actions = {
  fetchPages,
  fetchAboutPage,
  fetchNewsletterData,
  fetchAllInclusive,
};

export default actions;
