import React, {FC} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper';

import UITestimonialComponent from '../UICommon/UITestimonialComponent';
import {testimonialsTypes} from '../../types/testimonials';

type TestimonialsSectionProps = {
  data: testimonialsTypes[]
  padding?: string;
}

const TestimonialsCarouselSection: FC<TestimonialsSectionProps> = ({
  data,
  padding,
}) => {
  return (
    <Swiper
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className={`${padding} w-full grid`}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      }}
    >
      {data && data.map((el: testimonialsTypes, index: number) => (
        <SwiperSlide key={index}>
          <UITestimonialComponent
            companyTitle={el.organisation}
            nameTitle={el.author}
            description={el.quote}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialsCarouselSection;
