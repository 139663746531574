import {useQuery} from 'react-query';
import {useSetRecoilState} from 'recoil';
import actions from '../../api/subscriptions/subscriptions';
import {subscriptionsArray} from '../../state/subscriptions/subscriptions';
const useFetchSubscriptions = () => {
  const setSubscriptions = useSetRecoilState(subscriptionsArray);

  return useQuery('useFetchEvents', async () => {
    const subscriptions = await actions.fetchSubscriptions();
    setSubscriptions(subscriptions);
    return subscriptions;
  }, {
    refetchOnWindowFocus: false,
  });
};

export default useFetchSubscriptions;
