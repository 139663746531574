import React, {FC} from 'react';
import {Link} from 'react-router-dom';

const Page404: FC = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <div className='flex flex-col w-fit items-center lg:w-2/3'>
        <h2 className='text-4xl lg:text-8xl text-green-primary font-bold mb-3'>
          404
        </h2>
        <p className='text-base text-black-body text-center'>
          Sidan du letar efter finns inte. <br></br>
          <Link to='/' className='underline'>
            gå tillbaka till startsidan.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Page404;
