import {FC} from 'react';

import useFetchPages from '../hooks/pages/useFetchPages';

import FaqSectionComponent from '../components/FAQPage/FaqSectionComponent';
import UIPageContainer from '../components/UIWrappers/UIPageContainer';
import UITextWrapper from '../components/UIWrappers/UITextWrapper';
import UIErrorIndicator from '../components/UICommon/UIErrorIndicator';
import UILoadingIndicator from '../components/UICommon/UILoadingIndicator';


const FAQPage: FC = () => {
  const {isLoading: isLoadingPage, isError: isErrorPage} = useFetchPages();

  if (isErrorPage) {
    return (
      <UIErrorIndicator message='Ett fel uppstod, om felet är återkommande vänligen kontakta oss på canita@mih.nu'/>
    );
  };

  if (isLoadingPage) {
    return (
      <UILoadingIndicator/>
    );
  };
  return (
    <UIPageContainer>
      <UITextWrapper
        heading='FaQ/LATHUND'
        title='Historik och artiklar om Marknadsföreningen.'
        description='Här kan du läsa om de vanligaste frågorna rörande MiH.
        Hittar du inte svaret på din fråga maila oss på Canita@mih.nu'
      />
      <FaqSectionComponent />
    </UIPageContainer>
  );
};

export default FAQPage;
