import React, {FC, useEffect} from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';

import {selectedPlan} from '../../state/subscriptions/subscriptions';

import RegisterUpgradeCompanyForm from './RegisterUpgradeCompanyForm';
import UILoadingIndicator from '../UICommon/UILoadingIndicator';

const RegisterUpgradeSection: FC = () => {
  const navigate = useNavigate();
  const selectedUserPlan = useRecoilValue(selectedPlan);

  useEffect(() => {
    if (selectedUserPlan === undefined) {
      navigate('/register-organisation/plans/');
    }
  }, [selectedUserPlan]);

  if (selectedUserPlan === undefined) {
    return <UILoadingIndicator/>;
  }

  return (
    <div className='flex flex-col space-y-12 -mt-2'>
      <RegisterUpgradeCompanyForm/>
    </div>
  );
};
export default RegisterUpgradeSection;
