import React, {FC, useState} from 'react';

type UIVideoComponentTypes = {
  video?: string;
  image?: string;
}

const UIVideoComponent: FC<UIVideoComponentTypes> = ({video, image}) =>{
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };
  return (
    <>
      {!isVideoLoaded && (
        <>
          {image && (
            <img
              src={image} alt="image"
              className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px'
            />
          )}
        </>

      )}
      {video && (
        <>
          <video
            className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px'
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
            onLoadedData={handleVideoLoaded}
            src={video}
          />
          <video
            className='h-full w-full object-cover absolute top-0 left-0 z-0 rounded-15px'
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
            playsInline={true}
            onLoadedData={handleVideoLoaded}
            src={video}
            onError={(e) => {
              setIsVideoLoaded(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default UIVideoComponent;
