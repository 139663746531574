import React, {FC, useState} from 'react';
import {BiShow} from 'react-icons/bi';

type ProfileFormPasswordInputComponentProps = {
  id: string;
  name: string;
  onChange: Function;
  value?: string;
  errorMessages?: string;
  placeholder?: string;
  label?: string;
}

const ProfileFormPasswordInputComponent: FC<ProfileFormPasswordInputComponentProps> = ({
  id,
  name,
  onChange,
  value,
  errorMessages,
  placeholder,
  label,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='flex flex-col pr-4 relative'>
      <div className='flex items-center space-x-2 mb-2'>
        <label
          htmlFor={name}
          className='text-gray-common text-base'
        >
          {label}
        </label>
      </div>
      <input
        id={id}
        onChange={(event) => onChange(event)}
        autoComplete='off'
        className={`font-bold profileInputForm text-22px`}
        value={value}
        placeholder={placeholder && placeholder}
        name={name}
        type={showPassword ? 'text' : 'password'}
      />
      <BiShow className='absolute right-2 bottom-3 z-10' onClick={() => setShowPassword(!showPassword)}></BiShow>
      {errorMessages && (
        <small className="text-red-600 font-semibold text-xs mt-1">
          {errorMessages}
        </small>
      )}
    </div>
  );
};

export default ProfileFormPasswordInputComponent;
