import {atom} from 'recoil';
import {newsPaginatedType} from '../../types/news';

export const newsPaginated = atom<newsPaginatedType>({
  key: 'newsPaginated',
  default: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
});

