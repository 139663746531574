import React, {FC} from 'react';
import UICardComponent from '../UIWrappers/UICardComponent';
import UIButtonCommon from '../UIButtons/UIButtonCommon';

type UICtaComponentTypes = {
  title: string;
  description: string;
  buttonTitle: string;
  buttonUrl: string;
  extraInfo?: string;
  variant?: 'green' | 'white';
  contactInfo?: string;
}

const UICtaComponent: FC<UICtaComponentTypes> = ({
  title,
  description,
  buttonTitle,
  buttonUrl,
  extraInfo,
  variant='green',
  contactInfo,
}) => {
  return (
    <div className='py-24'>
      {variant == 'green' ? (
        <UICardComponent
          width='w-full'
          padding='px-5 md:pl-5 md:pr-16 pt-6 md:pt-10 pb-8 md:pb-24'
          backgroundColor='bg-green-primary'
        >
          <div className='flex flex-col manrope text-white w-full'>
            <div className='text-28px font-bold'>
              mih.nu
            </div>
            <div className='mt-24 md:mt-[72px]'>
              <span className='text-sm md:text-lg font-medium uppercase'>
                {title}
              </span>
              <div className={`flex flex-col md:flex-row w-full md:justify-between
                items-center md:items-end mt-3 md:mt-0`}>
                <div
                  className='text-27px md:text-55px font-bold w-full md:w-4/5 mb-16 md:mb-0'
                  dangerouslySetInnerHTML={{__html: description}}
                />
                <UIButtonCommon
                  width='w-full md:w-fit'
                  buttonTitle={buttonTitle}
                  buttonUrl={buttonUrl}
                  variant='whiteFilled'
                />
              </div>
            </div>
          </div>
        </UICardComponent>
      ) : (
        <UICardComponent
          width='w-full'
          padding='px-5 pt-9 pb-8 lg:px-16 lg:py-20'
          backgroundColor='bg-white'
        >
          <div className='flex flex-col manrope text-white w-full'>
            <span className='text-33px font-bold text-black-body'>
              {title}
            </span>
            <div
              className='text-lg w-full text-black-body/60'
              dangerouslySetInnerHTML={{__html: description}}
            />
            <div className={`flex flex-col md:flex-row w-full md:justify-between
              items-start md:items-end mt-3 md:mt-0`}>
              <div className='flex flex-col mb-6 lg:mb-0 mt-8 font-medium'>
                <span className='text-black-body/60 text-xs'>
                  Partner
                </span>
                {extraInfo && (
                  <div
                    className='text-black-normal leading-tight text-lg'
                    dangerouslySetInnerHTML={{__html: extraInfo}}
                  />
                )}
                {contactInfo && (
                  <a className='text-black-body/60 text-xs' href={`mailto: ${contactInfo}`}>
                    {contactInfo}
                  </a>
                )}
              </div>
              <UIButtonCommon
                width='w-full md:w-fit'
                buttonTitle={buttonTitle}
                buttonUrl={buttonUrl}
                variant='greenEvent'
                extraStyles='hover:scale-105'
              />
            </div>
          </div>
        </UICardComponent>
      )}
    </div>
  );
};

export default UICtaComponent;
