import React, {FC} from 'react';
import UIButtonCommon from '../UIButtons/UIButtonCommon';
import {UIButtonCommonVariants} from '../../variants/UIButtonCommonVariants';

type UIHeadingProps = {
  text: string;
  fontSize?: string;
  fontFamily?: string;
  color?: string;
  fontWeight?: string;
  lineHeight?: string;
  extraStyles?: string;
  isButton?: boolean;
  buttonUrl?: string;
  buttonMargin?: string;
  buttonOnClick?: Function;
  buttonTitle?: string;
  buttonVariant?: keyof typeof UIButtonCommonVariants;
}

const UIHeading: FC<UIHeadingProps> = ({
  text,
  fontSize='text-25px lg:text-42px',
  fontFamily='inter',
  color='text-gray-black',
  fontWeight='font-medium',
  lineHeight='leading-[1.2rem]',
  extraStyles,
  isButton=false,
  buttonMargin,
  buttonOnClick,
  buttonUrl,
  buttonTitle='',
  buttonVariant='blank',
}) => {
  return (
    <div className={`${extraStyles} w-full flex`}>
      <div className={`${fontSize} ${fontFamily} ${color} ${fontWeight} ${lineHeight}`}>
        {text}
      </div>
      {isButton && (
        <UIButtonCommon
          margin={buttonMargin}
          onClick={buttonOnClick && buttonOnClick}
          buttonUrl={buttonUrl}
          buttonTitle={buttonTitle}
          variant={buttonVariant}
        />
      )}
    </div>
  );
};

export default UIHeading;
