import React, {FC, useEffect, useState} from 'react';
import {FiMapPin, FiClock} from 'react-icons/fi';

import {useRecoilValue} from 'recoil';
import {currentUser} from '../../../state/user/currentUser';
import {isLoggedIn} from '../../../utils/isLoggedIn';
import {parseHtml} from '../../../utils/parseHtml';
import {enrolledListType} from '../../../types/event';
// eslint-disable-next-line camelcase
import {atcb_action} from 'add-to-calendar-button';
import useRefetchCurrentUser from '../../../hooks/auth/useRefetchCurrentUser';

import UIEnrollmentFormComponent from '../../UIForms/UIEnrollmentFormComponent';
import UICardComponent from '../../UIWrappers/UICardComponent';
import UIButtonCommon from '../../UIButtons/UIButtonCommon';
import EventsCarouselSlideMobile from './EventsCarouselSlideMobile';
import EventsAddToCalendarBtn from './EventsAddToCalendarBtn';
import UIUserAvatar from '../../UICommon/UIUserAvatar';
import UIUserAvatarExtra from '../../UICommon/UIUserAvatarExtra';

import imagePlaceholder from '../../../assets/placeholder-image.png';

type eventsCarouselSlideTypes = {
  title: string;
  description: string;
  speakers: enrolledListType[];
  eventId: number;
  enrollmentType: string;
  mapLink: string;
  enrollmentDate: string;
  enrollmentUrl?: string;
  thumbnailImageUrl?: string;
  localization?: string;
  timeStart?: string;
  timeEnd?: string;
  dateStart?: string;
  dateEnd?: string;
};


const EventsCarouselSlide: FC<eventsCarouselSlideTypes> = ({
  title,
  description,
  speakers,
  eventId,
  enrollmentType,
  mapLink,
  enrollmentDate,
  enrollmentUrl,
  thumbnailImageUrl,
  localization,
  timeStart,
  timeEnd,
  dateStart,
  dateEnd,
}) => {
  const [isEnrolled, setIsEnrolled] = useState(false);
  const userData = useRecoilValue(currentUser);
  const {refetch} = useRefetchCurrentUser();

  const enrolledUsersAmount = speakers?.length;
  const availableSpaces = 20;
  const leftOver = Math.max(enrolledUsersAmount - availableSpaces + 1, 0);
  const displayAvatars = leftOver > 0 ? availableSpaces - 1 : enrolledUsersAmount;

  useEffect(() => {
    if (userData != null && speakers && speakers?.filter((el: any) => el.id === userData.id).length > 0) {
      setIsEnrolled(true);
    }
  }, [userData, speakers]);

  const config: any = {
    name: title,
    hideIconButton: true,
    hideCheckmark: true,
    language: 'sv',
    size: '2',
    label: 'LÄGG TILL I KALENDER',
    location: localization,
    startDate: dateStart,
    endDate: dateEnd,
    options: ['Apple', 'Google', 'Yahoo', 'iCal'],
  };

  useEffect(() => {
    if (isLoggedIn()) {
      refetch();
    }
  }, []);

  const handleAddToCalendar = () => {
    atcb_action(config);
  };

  const txt = parseHtml(description);
  const enrollDate = new Date(enrollmentDate);

  return (
    <>
      <div className='hidden md:grid md:grid-cols-2 w-full h-full gap-8 mb-16'>
        <div className='relative w-full h-[600px]'>
          {speakers?.length > 0 &&
           <div className='flex flex-wrap-reverse absolute bottom-0 z-20 p-4
            from-gray-800 to-transparent bg-gradient-to-t w-full rounded-b-15px'>
             {speakers?.slice(0, displayAvatars).map((el: enrolledListType, idx: number) => (
               <UIUserAvatar
                 key={idx}
                 image={el.avatar}
                 extra={'mr-1 mt-1'}
               />
             ))}
             {leftOver > 0 &&
            <UIUserAvatarExtra
              amount={leftOver}
              extra={'mr-1 mt-1'}
            />
             }
           </div>
          }
          <img className='object-cover w-full rounded-15px h-full'
            src={thumbnailImageUrl || imagePlaceholder}
            alt='image'
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = imagePlaceholder;
            }}
          />
        </div>
        <div className='h-[600px]'>
          <UICardComponent
            width='w-full'
            padding='pl-8 lg:pl-12 pr-10 lg:pr-24 pt-8 pb-9'
          >
            {enrollmentType === 'Anmälan på webbplats' ? (
              <>
                {!isLoggedIn() && enrollDate > new Date() && (
                  <UIEnrollmentFormComponent
                    eventId={eventId}
                    buttonVariant='greenEvent'
                    buttonTitle='ANMÄL'
                    position='top-1 xl:top-2 right-2'
                    isEnrolled={isEnrolled}
                    setIsEnrolled={setIsEnrolled}
                    userEmail={userData ? userData.email : ''}
                  />
                )}
              </>
            ) : enrollDate > new Date() ? (
              <UIButtonCommon
                buttonUrl={enrollmentUrl}
                variant='greenEvent'
                buttonTitle='ANMÄL TILL EVENT'
              />
            ) : (<></>)}
            <div className='flex flex-col mt-14 manrope relative h-full'>
              {enrollDate < new Date() && (
                <div className='text-sm text-red-500 w-full'>
                  DET GÅR INTE LÄNGRE ATT ANMÄLA SIG TILL DETTA EVENEMANG
                </div>
              )}
              <div className="flex items-center justify-between">
                <h2 className='text-black-body text-33px'>
                  {title}
                </h2>
                {isLoggedIn() && enrollmentType === 'Anmälan på webbplats' && enrollDate > new Date() && (
                  <UIEnrollmentFormComponent
                    eventId={eventId}
                    buttonVariant='greenEvent'
                    buttonTitle='ANMÄL'
                    position='top-1 xl:top-2 right-2'
                    isEnrolled={isEnrolled}
                    setIsEnrolled={setIsEnrolled}
                    userEmail={userData ? userData.email : ''}
                  />
                )}
              </div>
              <h3 className='text-black/40 text-22px mt-6'>
                Om eventet
              </h3>
              <div
                className='mt-3 text-base lg:text-lg text-black-normal/70 carousel-content-wrapper'
              >
                <p>
                  {txt}
                </p>
              </div>
              <div
                className='mt-auto'
              >
                <div className='mt-6 flex flex-col space-y-4'>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <FiMapPin className='text-2xl text-black-normal/60 mr-2'/>
                      <span className='text-gray-black/60'>
                        {localization}
                      </span>
                    </div>
                    <UIButtonCommon
                      buttonUrl={mapLink}
                      target='blank'
                      buttonTitle='HITTA HIT'
                      variant='blackOutlinedTransparent'
                    />
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <FiClock className='text-2xl text-black-normal/60 mr-2'/>
                      <span className='text-gray-black/60'>
                        {timeStart} - {timeEnd}
                      </span>
                    </div>
                    <EventsAddToCalendarBtn
                      buttonTitle='LÄGG TILL I KALENDER'
                      onClick={handleAddToCalendar}
                    />
                  </div>
                </div>
                <UIButtonCommon
                  buttonUrl={`events/${eventId}`}
                  margin='mt-7'
                  buttonTitle='LÄS MER'
                  variant='greenOutlined'
                />
              </div>
            </div>
          </UICardComponent>
        </div>
      </div>
      <div className='flex md:hidden mb-12'>
        <EventsCarouselSlideMobile
          title={title}
          description={description}
          eventId={eventId}
          speakers={speakers}
          enrollmentDate={enrollmentDate}
          enrollmentType={enrollmentType}
          enrollmentUrl={enrollmentUrl}
          localization={localization}
          timeStart={timeStart}
          timeEnd={timeEnd}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      </div>
    </>
  );
};

export default EventsCarouselSlide;
