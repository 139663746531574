import React, {FC, useEffect, useState} from 'react';
import {FiChevronDown} from 'react-icons/fi';
import {UIDropdownButtonVariants} from '../../variants/UIDropdownButtonVariants';
import {pagesType} from '../../types/pages';

type UIDropdownButtonTypes = {
  values: pagesType[];
  variant: keyof typeof UIDropdownButtonVariants;
  dropdownVariant: keyof typeof UIDropdownButtonVariants;
  optionClick?: Function;
  selectedOption?: string;
  itemStyles?: string;
  width?: string;
  onValueChange?: (value: string) => void;
};

const UIDropdownButton: FC<UIDropdownButtonTypes> = ({
  values,
  variant,
  dropdownVariant,
  optionClick,
  selectedOption,
  itemStyles,
  width = 'w-full',
  onValueChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const variantClasses = {
    [UIDropdownButtonVariants.white]:
      'bg-white rounded-15px relative flex items-center py-3 px-4 ' +
      'shadow-sm rounded-15px text-black-dark/80 font-medium',
    [UIDropdownButtonVariants.green]:
      'bg-green-primary text-white rounded-md relative flex font-bold ' +
      'items-center py-3 px-4 shadow-sm text-base',
    [UIDropdownButtonVariants.greenDropdown]:
      'rounded-10px bg-green-primary rounded-t-none ' +
      'top-6 text-white rounded-lg shadow-sm divide-y !text-left ' +
      'divide-white lg:divide-gray-common',
    [UIDropdownButtonVariants.commonDropdown]: 'bg-white rounded-b-15px shadow-sm pb-2 pt-4',
    [UIDropdownButtonVariants.whiteDropdownRounded]: 'bg-white rounded-15px shadow-sm pb-2 pt-4 top-[120%]',
  };

  useEffect(() => {
    if (selectedOption) {
      setIsOpen(false);
    }
  }, [selectedOption]);

  const handleItemClick = (value: string) => {
    setIsOpen(false);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <div className='relative'>
      <button
        type='button'
        onClick={() => setIsOpen(!isOpen)}
        className={`${variantClasses[variant]} ${width} 
        ${isOpen && 'rounded-b-none'}`}
      >
        {selectedOption || ''}
        <FiChevronDown
          className={`${isOpen && 'rotate-180'} absolute right-2 sm:right-3 transition-transform duration-150 ease-out`}
        />
      </button>
      <div
        className={`${!isOpen ? 'hidden' : 'block'} ` +
          `w-full absolute top-10 flex flex-col text-left pt-2 ${variantClasses[dropdownVariant]}`}
      >
        {values &&
          values.map((el: pagesType, index: number) => (
            <div
              key={index}
              onClick={() => {
                handleItemClick(el.title);
                optionClick && optionClick();
              }}
              className={`${itemStyles ? itemStyles : 'px-4 py-2'}`}
            >
              {el.title}
            </div>
          ))}
      </div>
    </div>
  );
};

export default UIDropdownButton;
